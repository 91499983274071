<template>
  <div class="footer">
    <div class="top-wrap">
      <div class="content-wrap w1200 ">
        <div class="left-wrap">
          <img src="@/assets/img/home/logo1.png" alt="">
          <div class="" style="color:#EDEDED;margin-top: 2.81rem;">
            <router-link to="/contact">关于我们</router-link> | <router-link to="/contact">联系我们</router-link> | <router-link to="/policy">平台动态</router-link>
          </div>
          <div class="desc-wrap">
            <p>邮 箱：phtkjgf@126.com</p>
            <p>联系方式：0371-55597792</p>
            <p>地 址：河南省郑州市郑东新区联合大厦豫发中心17层</p>
          </div>
        </div>
        <div class="middle-wrap">
          <div class="more-recommend-wrap">
            更多推荐
          </div>
          <div class="desc-wrap">
            <p class="cursor-pointer" @click="$router.push('/mainprod')">金融科技</p>
            <p class="cursor-pointer" @click="$router.push('/mainserv')">金融服务</p>
            <p class="cursor-pointer" @click="$router.push('/ruralrevital')">乡村振兴</p>
            <p class="cursor-pointer" @click="$router.push('/typicalcase')">典型案例</p>
          </div>
        </div>
        <div class="right-wrap r">
          <div class="item-wrap">
            <div class="item-title">普惠通公众号</div>
            <img src="@/assets/img/wechat.jpg" style="width:7rem;height:7rem;" alt="普惠通公众号">
            <div class="item-desc">关注了解更多资讯</div>
          </div>
          <div class="item-wrap" style="margin-left:3.63rem;">
            <div class="item-title">普惠通 APP</div>
            <img src="@/assets/img/app1.png" style="width:7rem;height:7rem;" alt="普惠通 APP">
            <div class="item-desc">下载专享优质服务</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-wrap">
      Copyright©2017-2024 普惠通科技（河南）股份有限公司 <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备17039401号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    handleClick(idx){
      this.$router.push(`/mainserv/${idx}`)
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  .top-wrap {
    min-width: 75rem;

    height: 22.88rem;
    background: #071d3c;
    box-sizing: border-box;
    padding-top: 3.56rem;
    .content-wrap {
      box-sizing: border-box;
      padding-left: 4.56rem;
      text-align: left;
      .left-wrap {
        float: left;
      }
      .desc-wrap {
        margin-top: 1.88rem;
        p {
          font-size: 0.88rem;
          font-family: PingFangSC-Light-, PingFangSC-Light;
          color: #bbbabf;
          line-height: 2.63rem;
        }
      }
      .middle-wrap {
        float: left;
        margin-left: 2.88rem;
        .more-recommend-wrap {
          margin-top: 5rem;
          font-size: 1rem;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: normal;
          color: #ededed;
        }
        .desc-wrap {
          margin-top: 1.88rem;
          p {
            font-size: 0.88rem;
            font-family: PingFangSC-Light-, PingFangSC-Light;
            color: #bbbabf;
            line-height: 1.93rem;
          }
        }
      }
      .right-wrap {
        float: right;
        margin: 5rem 2.94rem 0 0;
        .item-wrap {
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          color: #ededed;
          text-align: center;
          .item-title {
            font-size: 1rem;
            margin-bottom: 1.31rem;
          }
          .item-desc {
            margin-top: 1.38rem;
            font-size: 0.88rem;
          }
        }
      }
    }
    a {
      font-size: 1rem;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      font-weight: normal;
      color: #ededed;
    }
  }
  .bottom-wrap {
    height: 2.75rem;
    background: #041636;
    min-width: 75rem;
    line-height: 2.75rem;
    font-size: 1rem;
    font-family: PingFangSC-Light-, PingFangSC-Light;
    color: #aaabb1;
    a {
      color: #aaabb1;
    }
  }
}
</style>
