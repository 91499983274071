<template>
  <!-- <div class="banner" v-if="mainbsBannerUrl.img" :style="'background-image:url('+mainbsBannerUrl.img+')'">
  </div> -->
  
  <!-- <div class="banner"  :style="{backgroundImage:'url('+require('../assets/img/servicebanner.png')+')'}"></div> -->
  <img src="../assets/img/servicebanner.png" alt="" class="banner">
  
</template>

<script>
// import { from } from 'webpack-sources/lib/CompatSource';

export default {
  // data(){
  //   return{
  //     servicebanner:servicebanner
  //   }
  // },
  // async created(){
  //   const mainbsBannerUrl = await this.$get('/operation-management/api/banner?plate=webbusiness')
  //   this.mainbsBannerUrl = mainbsBannerUrl[0]
  // }
}
</script>

<style scoped>
.banner{
  box-sizing: border-box;
  width: 100%;
  /* height: 25vw; */
  /* padding: 4.25rem 0 4.25rem 2.38rem; */
  /* background-image: url("../assets/img/servicebanner.png"); */
}
.title-zh{
  text-align: left;
  font-size: 2rem;
  color: #FFFFFF;
  margin-bottom: 0.5rem;
}
.title-en{
  text-align: left;
  font-size: 1rem;
  color: #F3F3F3;
  opacity: 0.5;
}

</style>