<template>
  <div class="mainserv">
    <Header />
    <div style="font-size:0;">
      <img
        :src="require(`@/assets/img/mainserv/banner${tabIndex}.png`)"
        style="width:100%;min-width: 75rem;"
        alt=""
      />
    </div>
    <div class="bg1 padding-bottom-6 padding-top-4">
      <div class="w1200">
        <div
          class="rs margin-auto margin-bottom-4 height10"
          style="align-items:baseline;width: 60rem;"
        >
          <div
            class="img cursor-pointer"
            :class="tabIndex == 1 ? '' : 'zoom'"
            @click="handleTab(1)"
          >
            <img
              :src="
                require(`@/assets/img/mainserv/${
                  tabIndex == 1 ? 'da' : 'xiao'
                }-1.png`)
              "
              alt=""
            />
          </div>
          <div
            class="img cursor-pointer"
            :class="tabIndex == 2 ? '' : 'zoom'"
            @click="handleTab(2)"
          >
            <img
              :src="
                require(`@/assets/img/mainserv/${
                  tabIndex == 2 ? 'da' : 'xiao'
                }-2.png`)
              "
              alt=""
            />
          </div>
          <div
            class="img cursor-pointer"
            :class="tabIndex == 3 ? '' : 'zoom'"
            @click="handleTab(3)"
          >
            <img
              :src="
                require(`@/assets/img/mainserv/${
                  tabIndex == 3 ? 'da' : 'xiao'
                }-3.png`)
              "
              alt=""
            />
          </div>
          <div
            class="img cursor-pointer"
            :class="tabIndex == 4 ? '' : 'zoom'"
            @click="handleTab(4)"
          >
            <img
              :src="
                require(`@/assets/img/mainserv/${
                  tabIndex == 4 ? 'da' : 'xiao'
                }-4.png`)
              "
              alt=""
            />
          </div>
        </div>
        <div class="relative">
          <transition name="fade">
            <div class="card rs" v-show="tabIndex == 1">
              <div>
                <div class="title margin-bottom-2">金融服务</div>
                <div class="content margin-bottom-1">
                  充分发挥400多家金融机构合作优势，线上依托<span class="red"
                    >普惠通APP</span
                  >及<span class="red">惠e众推分销系统</span
                  >，线下依托专业服务团队，为中小微企业及个人用户提供全方位金融服务。
                </div>
                <div class="rc margin-bottom-2">
                  <div class="w13">
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count1"
                        :endVal="10000"
                        :duration="2000"
                      ></count-to>
                      +
                    </div>
                    <div class="desc">累计服务C端用户</div>
                  </div>
                  <div>
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count2"
                        :endVal="400"
                        :duration="2000"
                      ></count-to
                      >+
                    </div>
                    <div class="desc">合作金融机构</div>
                  </div>
                </div>
                <div class="rc ">
                  <div class="w13">
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count3"
                        :endVal="2000"
                        :duration="2000"
                      ></count-to>
                      +
                    </div>
                    <div class="desc">累计服务中小微企业</div>
                  </div>
                  <div>
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count4"
                        :endVal="150"
                        :duration="2000"
                      ></count-to>
                      亿+
                    </div>
                    <div class="desc">累计贷款金额</div>
                  </div>
                </div>
              </div>
              <div class="img-wrap">
                <img src="@/assets/img/mainserv/pic1.png" alt="" />
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card rs" v-show="tabIndex == 2">
              <div>
                <div class="title margin-bottom-2">数据服务</div>
                <div class="content margin-bottom-2">
                  接入司法、电力、省信息中心数据及地方政务数据。开发普惠通企业征信系统服务B端企业客户。通过代理百行征信、朴道征信等个人征信业务，为银行、小贷公司等金融机构提供C端客户数据查询服务。
                </div>
                <div class="rc margin-bottom-2">
                  <div class="w13">
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count5"
                        :endVal="6"
                        :duration="2000"
                      ></count-to
                      >亿+
                    </div>
                    <div class="desc">拥有可调用数据量</div>
                  </div>
                  <div>
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count6"
                        :endVal="20"
                        :duration="2000"
                      ></count-to>
                      +
                    </div>
                    <div class="desc">已累计服务省内农商行、小贷公司</div>
                  </div>
                </div>
              </div>
              <div class="img-wrap">
                <img src="@/assets/img/mainserv/pic2.png" alt="" />
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card rs" v-show="tabIndex == 3">
              <div>
                <div class="title margin-bottom-2">用工服务</div>
                <div class="content margin-bottom-3">
                  依托平台县域基层用户资源，汇聚省内外企业用工需求和高校就业需求，通过公众号<span
                    class="red"
                    >惠民蓝领普工技能提升助手</span
                  >和<span class="red">微信社群</span
                  >，发布就业信息，提供就业及技能提升指导，同时，可承办用工服务活动，打通用工、就业双向通道，精准服务重点用工、就业群体。
                </div>
                <div class="rc margin-bottom-2">
                  <div class="w13">
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count7"
                        :endVal="10000"
                        :duration="2000"
                      ></count-to>
                      +
                    </div>
                    <div class="desc">拥有高校就业生源</div>
                  </div>
                  <div>
                    <div class="digital">
                      <count-to
                        :startVal="0"
                        :autoplay="false"
                        ref="count8"
                        :endVal="20"
                        :duration="2000"
                      ></count-to
                      >+
                    </div>
                    <div class="desc">已累计建立合作关系的企业</div>
                  </div>
                </div>
              </div>
              <div class="img-wrap">
                <img src="@/assets/img/mainserv/pic3.png" alt="" />
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card rs" v-show="tabIndex == 4">
              <div>
                <div class="title margin-bottom-2">电商服务</div>
                <div class="content margin-bottom-1">
                  <p class="margin-bottom-3">
                    <span class="red">惠民商城</span
                    >，与“第一书记”“郑欧班列”“一乡一品”等合作，将乡村特色优质农产品输送到城市，与省国际陆港集团合作上架欧洲进口商品，将国外优质商品带到乡村，形成国际国内双循环。
                  </p>

                  <p>
                    <span class="red">河南喜拼云链</span
                    >，专注商城运营，主播孵化、直播带货、品牌运营、乡品传播，带动农民就业、创业，激活乡村绿色产业链快速发展。
                  </p>
                </div>
              </div>
              <div class="img-wrap">
                <img src="@/assets/img/mainserv/pic4.png" alt="" />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import countTo from "vue-count-to";

export default {
  components: {
    Header,
    Footer,
    Sidebar,
    countTo
  },
  data() {
    return {
      tabIndex: 1,
      metas: [
        {
          title: "普惠通官网-金融服务",
          keywords: "普惠通,金融服务,普惠金融,中小微企业,贷款",
          description:
            "充分发挥400多家金融机构合作优势，线上依托普惠通APP及惠e众推分销系统，线下依托专业服务团队，为政、企及个人用户提供全方位金融服务。"
        },
        {
          title: "普惠通官网-数据服务",
          keywords: "普惠通,数据服务,征信,个人征信,企业征信",
          description:
            "协调接入司法、电力、省信息中心数据及地方政务数据。开发普惠通企业征信系统服务B端企业客户。通过代理百行征信、朴道征信等个人征信业务，为银行、小贷公司等金融机构提供C端客户数据查询服务。"
        },
        {
          title: "普惠通官网-用工服务",
          keywords: "普惠通,用工服务,就业信息,技能提升,用工需求,就业",
          description:
            "依托平台县域基层用户资源，汇聚省内外企业用工需求和高校就业需求，通过公众号惠民蓝领普工技能提升助手和微信社群，发布就业信息，提供就业及技能提升指导，同时，可承办用工服务活动，打通用工、就业双向通道，精准服务重点用工、就业群体。"
        },
        {
          title: "普惠通官网-电商服务",
          keywords: "普惠通,电商,直播,商城,农村产业链,进口商品,农村特产",
          description:
            "围绕县域“聚集性、规模性、特色性”进行产业振兴探索，开展涉农电商业务，打造以供应链为核心，孵化选品为基础，电商赋能为支撑，供应链金融为驱动的惠农涉农产业链综合服务模式。"
        }
      ]
    };
  },
  mounted() {
    if (this.$route?.params?.idx) {
      this.handleTab(this.$route?.params?.idx);
    } else {
      this.handleTab(this.tabIndex);
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val && val.params.idx != oldVal.params.idx) {
          // this.tabIndex = val.params.idx;
          this.handleTab(val.params.idx);
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    handleTab(idx) {
      this.tabIndex = idx;
      if (idx > 0) {
        // 设置meta标签
        document.title = this.metas[idx - 1].title;
        if (this.metas[idx - 1].description) {
          if (document.querySelector('meta[name="description"]')) {
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", this.metas[idx - 1].description);
          } else {
            let meta = document.createElement("meta");
            meta.content = this.metas[idx - 1].description;
            meta.name = "description";
            document.getElementsByTagName("head")[0].appendChild(meta);
          }
        }
        if (this.metas[idx - 1].keywords) {
          if (document.querySelector('meta[name="keywords"]')) {
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", this.metas[idx - 1].keywords);
          } else {
            let meta = document.createElement("meta");
            meta.content = this.metas[idx - 1].keywords;
            meta.name = "keywords";
            document.getElementsByTagName("head")[0].appendChild(meta);
          }
        }
        this.startCount(idx);
        this.resetCount(idx);
      }
    },
    resetCount(idx) {
      if (this.$refs.count1 && idx != 1) {
        this.$refs.count1.reset();
        this.$refs.count2.reset();
        this.$refs.count3.reset();
        this.$refs.count4.reset();
      }
      if (this.$refs.count5 && idx != 2) {
        this.$refs.count5.reset();
        this.$refs.count6.reset();
      }
      if (this.$refs.count7 && idx != 3) {
        this.$refs.count7.reset();
        this.$refs.count8.reset();
      }
    },
    startCount(idx) {
      switch (idx) {
        case 1:
          this.$refs.count1.start();
          this.$refs.count2.start();
          this.$refs.count3.start();
          this.$refs.count4.start();
          break;
        case 2:
          this.$refs.count5.start();
          this.$refs.count6.start();

          break;
        case 3:
          this.$refs.count7.start();
          this.$refs.count8.start();

          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$count: 5;
@for $i from 0 through $count * 2 {
  .margin-bottom-#{ $i } {
    margin-bottom: $i * 1rem;
  }
  .padding-bottom-#{ $i } {
    padding-bottom: $i * 1rem;
  }
  .padding-top-#{ $i } {
    padding-top: $i * 1rem;
  }
}
.mainserv {
  .relative {
    position: relative;

    height: 29.13rem;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .bg1 {
    background: #f2f4f9;
    // .zoom:hover{
    //   img{
    //     zoom: 1.2;
    //   }
    // }
    .img {
      width: 10.25rem;
    }
    .height10 {
      height: 10.3125rem;
    }
  }
  .card {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    background: #fff;
    border-radius: 1.25rem;
    padding-left: 2.69rem;
    .title {
      font-size: 1.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 2.06rem;
      color: #191e4c;
      margin-top: 3.88rem;
    }
    .red {
      color: #dd2e21;
      font-weight: bold;
    }
    .w13 {
      width: 13rem;
    }
    .content {
      width: 26.5rem;
      font-size: 1rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 1.7rem;
      color: #333333;
    }
    .digital {
      font-size: 1.88rem;
      font-family: SapientSansBold;
      font-weight: 400;
      line-height: 2.25rem;
      color: #191e4c;
      margin-bottom: 0.5rem;
    }
    .desc {
      font-size: 0.88rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 1.25rem;
      color: #666666;
    }
    .img-wrap {
      margin-left: 3rem;
      font-size: 0;
      width: 42.94rem;
      img {
        width: 100%;
      }
    }
  }
}
</style>
