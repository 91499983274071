<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {}
}
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}
html {
  font-size: 16px;
}
#app {
  width: 100%;
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#container {
  width: 75rem;
  margin: 0 auto;
}
ul,
ol,
p {
  margin: 0;
  padding: 0;
}
ul,
ol,
li {
  list-style: none;
}
a {
  font-family: Microsoft YaHei;
  text-decoration: none;
  /* color: #555555; */

  color: #333333;
}

.r {
  display: flex;
  flex-direction: row;
}
.c {
  display: flex;
  flex-direction: column;
}
.w {
  display: flex;
  flex-wrap: wrap;
}
.rc {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ra {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.rsc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rac {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.rcc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.csc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ccc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fav-icon {
  width: 0.76rem;
  height: 0.76rem;
  margin-right: 0.31rem;
}

.w1200 {
  width: 75rem;
  margin: 0 auto;
}
.w80 {
  min-width: 75rem;
  width: 80vw;
  margin: 0 auto;
}
.lineH {
  width: 100%;
  height: 0.0625rem;
  background: #d1d1d1;
}
.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* // style={{"WebkitBoxOrient": "vertical"}} */
.twoLine {
  overflow: hidden;
  text-overflow: ellipsis;

  /* Safari, Opera, and Chrome */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;


  /* Firefox */
  display: -moz-box;
  -moz-box-orient: horizontal;

  /* Safari, Opera, and Chrome */
  /* // display:-webkit-box;
  // -webkit-box-orient:horizontal; */

  /* W3C */
  display: box;
  box-orient: horizontal;
}
.threeLine {
  overflow: hidden;
  text-overflow: ellipsis;

  /* Safari, Opera, and Chrome */
  display: -webkit-box;
  -webkit-line-clamp: 3;
/* autoprefixer: ignore next */
  -webkit-box-orient: vertical;


  /* Firefox */
  display: -moz-box;
  -moz-box-orient: horizontal;

  /* Safari, Opera, and Chrome */
  /* // display:-webkit-box;
  // -webkit-box-orient:horizontal; */

  /* W3C */
  display: box;
  box-orient: horizontal;
}
.cursor-pointer{
  cursor: pointer;
}
.center{
  display: grid;
  place-items: center;
}
.margin-auto{
  margin: auto;
}

.text-center{
  text-align: center;
}
</style>
