<template>
  <div class="loan">
    <div>
      <div id="container">
        <Header />
      </div>
      <!-- <div
        class="banner"
        :style="'background-image:url('+loanBannerUrl+')'"
      ></div> -->
      <img src="../assets/img/loanbg.png" alt="" class="banner">
      <div id="container">
        <div class="recommend">
          <div class="headline">
            <p>智能推荐</p>
            <div class="block">
              <el-cascader
                placeholder="输入地区进行搜索"
                v-model="areacode"
                :options="options"
                :show-all-levels="false"
                filterable
                @change="handleArea"
              >
              </el-cascader>
            </div>
            <span class="explain">选择筛选条件为您精准推荐</span>
          </div>
          <div class="screen">
            <div class="screen-list">
              <p class="screen-sort">职业：</p>
              <div class="screen-detail">
                <span
                  @click="screenProfession(index)"
                  v-for="(item, index) in profession"
                  :key="index"
                  :class="{ active: current1.includes(index)  ? true : false }"
                  >{{ item.label }}</span
                >
              </div>
            </div>
            <div class="screen-list">
              <p class="screen-sort">用途：</p>
              <div class="screen-detail">
                <span
                  @click="screenUsing(index)"
                  v-for="(item, index) in using"
                  :key="index"
                  :class="{ active: current2.includes(index) ? true : false }"
                  >{{ item.label }}</span
                >
              </div>
            </div>
            <div class="screen-list">
              <p class="screen-sort">贷款类型：</p>
              <div class="screen-detail">
                <span
                  @click="screenType(index)"
                  v-for="(item, index) in type"
                  :key="index"
                  :class="{ active: current3.includes(index) ? true : false }"
                  >{{ item.label }}</span
                >
              </div>
            </div>
            <div class="screen-list">
              <p class="screen-sort">还款方式：</p>
              <div class="screen-detail">
                <span
                  @click="screenBack(index)"
                  v-for="(item, index) in payback"
                  :key="index"
                  :class="{ active: current4.includes(index) ? true : false }"
                  >{{ item.label }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="container">
        <div class="products">
          <div class="products-type">
            <div class="type-box">
              <span
                :class="productType == 20 ? 'type-head' : ''"
                @click="screenBigType(20)"
                >极速贷</span
              >
              <span
                :class="productType == 10 ? 'type-head' : ''"
                @click="screenBigType(10)"
                style="cursor: pointer"
                >普惠授信</span
              >
              <span
                :class="productType == 40 ? 'type-head' : ''"
                @click="screenBigType(40)"
                >个人贷</span
              >
              <span
                :class="productType == 30 ? 'type-head' : ''"
                @click="screenBigType(30)"
                >企业贷</span
              >
            </div>
          </div>
          <div class="line"></div>
          <div
            v-if="loanProductList && loanProductList.length == 0 || !loanProductList"
            class="products-empty"
            style="height: 15rem; line-height: 15rem; font-size: 1.4rem"
          >
            当前筛选条件无可推荐的贷款产品
          </div>
          <div v-loading="isloading" v-else class="products-list">
            <div
              class="products-item"
              v-for="item in loanProductList"
              :key="item.id"
            >
              <div class="item-logo">
                <img :src="item.bank && item.bank.logoMini" alt="" />
              </div>
              <div class="item-info">
                <div class="name">{{ item.productName }}</div>
                <div class="tags elipse" style="text-align: left">
                  <span v-for="(it, index) in item.labelName" :key="index">{{
                    it
                  }}</span>
                </div>
                <div class="limit">
                  <span class="aside">可借额度</span>
                  <span class="number">{{ item.loanAmount }}</span>
                </div>
                <div class="profit">
                  <span class="aside">{{ item.monthlyInterestTypeStr }}</span>
                  <span class="number">{{ item.monthlyInterest }}</span>
                </div>
                <a href="" class="applynow"><span>立即申请</span></a>
              </div>
               <div class="after" :style="{
                backgroundImage: 'url('+ item.qrCodeUrl+')'
                }"></div>
            </div>
            <div
              class="emptybox"
              :style="Math.ceil(totalSize/15)==pageNum&&(totalSize - 2) % 3 == 0 ? '' : 'display:none'"
            ></div>
          </div>
          <div class="block">
            <el-pagination
              @current-change="handlePageChange"
              @prev-click="handlePageChange"
              @next-click="handlePageChange"
              :current-page.sync="pageNum"
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="totalSize"
              :background="true"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div id="container">
        <Sidebar />
      </div>
    </div>
    <Footer />
    <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style="display: none"
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&referer=myapp"
    ></iframe>
  </div>
</template>


<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import area_ids from "@/utils/AreaID.js";
import { getTreeDeepArr, unique,setSpot } from "@/utils/tools.js";

export default {
  name: "Loan",
  data() {
    return {
      loanBannerUrl: '',
      profession: [
        {label:"农户",value:'la1'},
        {label:"个体工商户",value:'la2'},
        {label:"小微企业主",value:'la3'},
        {label:"私营企业员工",value:'la4'},
        {label:"公务员",value:'la5'},
        {label:"企事业单位",value:'la6'},
        {label:"学生",value:'la7'},
        {label:"其他职位",value:'la8'},
      ],
      using: [
        {label:"买房",value:'lc1'},
        {label:"买车",value:'lc2'},
        {label:"其他消费",value:'lc3'},
        {label:"税务",value:'lb1'},
        {label:"烟草",value:'lb2'},
        {label:"科技",value:'lb3'},
        {label:"其他生产经营",value:'lb4'},
      ],
      type: [
        { label: "信用", value: 'ld1' },
        { label: "担保", value: 'ld2' },
        { label: "抵质押", value: 'ld3' }
      ],
      payback: [
        {label:"等额本息",value:'le1'},
        {label:"等额本金",value:'le2'},
        {label:"随借随还",value:'le3'},
        {label:"到期一次性还本付息",value:'le4'},
        {label:"按时付息到期还本",value:'le5'},
        {label:"其他还款",value:'le6'},
        {label:"多种还款方式",value:'le7'},
      ],
      current1: [],
      current2: [],
      current3: [],
      current4: [],
      allTags:[],
      allParams:'',
      areacode: [0, 0, 0],
      applicablePeople: "",
      tagUse: "",
      tagGuarantee: "",
      productType: 20,
      loanProductList: [],
      totalSize: 0,
      pageNum: 1,
      pageSize: 15,
      options: "",
      locFlag: false,
      isloading: false,
    };
  },
  components: {
    Header,
    Footer,
    Sidebar,
  },
  created() {
    setSpot('贷款产品')
    this.options = area_ids;
    this.getLoanBanner()
  },
  mounted() {
    if (!this.locFlag) {
      this.Tmap();
    }
    // this.getLoanProductList()
  },
  methods: {
    async Tmap() {
    // var loc;
    // var isMapInit = false;
    // //监听定位组件的message事件
    // window.addEventListener('message', function(event) {
    //     loc = event.data; // 接收位置信息
    //     console.log('location', loc);

    //     if(loc  && loc.module == 'geolocation') { //定位成功,防止其他应用也会向该页面post信息，需判断module是否为'geolocation'
    //         var markUrl = 'https://apis.map.qq.com/tools/poimarker' +
    //             '?marker=coord:' + loc.lat + ',' + loc.lng +
    //             ';title:我的位置;addr:' + (loc.addr || loc.city) +
    //             '&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&referer=myapp';
    //         //给位置展示组件赋值
    //         document.getElementById('markPage').src = markUrl;
    //     } else { //定位组件在定位失败后，也会触发message, event.data为null
    //         alert('定位失败');
    //     }
    // }, false);
    // //为防止定位组件在message事件监听前已经触发定位成功事件，在此处显示请求一次位置信息
    // document.getElementById("geoPage").contentWindow.postMessage('getLocation', '*');

    // //设置6s超时，防止定位组件长时间获取位置信息未响应
    // setTimeout(function() {
    //     if(!loc) {
    //         //主动与前端定位组件通信（可选），获取粗糙的IP定位结果
    //         document.getElementById("geoPage")
    //             .contentWindow.postMessage('getLocation.robust', '*');
    //     }
    // }, 6000); //6s为推荐值，业务调用方可根据自己的需求设置改时间，不建议太短

      window.addEventListener(
        "message",
        async (event) => {
          // console.log(event); //地址信息
          if (event.data && event.data.type !== "ip") {
            return;
          }
          const location = await this.$get(
            `/loc/reverse_geocoding/v3/?ak=NbMro6A9dE1lrKGMxT4XHQgDlfYdBXyl&output=json&coordtype=wgs84ll&location=${
              event.data && event.data.lat
            },${event.data && event.data.lng}`
          );
          // console.log(location);
          if (!this.locFlag && location.result.addressComponent.district) {
            this.locFlag = true;
            this.areacode = getTreeDeepArr(
              location.result.addressComponent.adcode + "000000",
              area_ids
            );
              let key1=[],key2=[],key3=[],key4=[]
              this.current1.forEach((item)=>{
                key1.push(this.profession[item].value)
              })
              this.current2.forEach((item)=>{
                key2.push(this.using[item].value)
              })
              this.current3.forEach((item)=>{
                key3.push(this.type[item].value)
              })
              this.current4.forEach((item)=>{
                key4.push(this.payback[item].value)
              })
              this.allTags = [].concat(key1,key2,key3,key4)
              this.allTags = unique(this.allTags)
              this.allParams = this.allTags.length==0?'': this.allTags.join(',')
              let params = {
                "areacode": this.areacode[2],
                "loanLabel": this.allParams,
                "pageNum": this.pageNum,
                "pageSize": 15,
                "productType": this.productType
              }
              const res = await this.$post(
                "/loan-api/api/loanProduct/mini/list",
                params
              );
              this.totalSize = res.data.totalSize
              this.pageNum = res.data.pageNum
              this.loanProductList = res.data.content;
          }
        },
        false
      );

    },
    async getLoanBanner() {
      const loanBannerUrl = await this.$get("/operation-management/api/banner?plate=webloans");
      this.loanBannerUrl = loanBannerUrl[0].img;
    },
    async getLoanProductList() {
      this.isloading = true;
      let key1=[],key2=[],key3=[],key4=[]
      this.current1.forEach((item)=>{
        key1.push(this.profession[item].value)
      })
      this.current2.forEach((item)=>{
        key2.push(this.using[item].value)
      })
      this.current3.forEach((item)=>{
        key3.push(this.type[item].value)
      })
      this.current4.forEach((item)=>{
        key4.push(this.payback[item].value)
      })
      this.allTags = [].concat(key1,key2,key3,key4)
      this.allTags = unique(this.allTags)
      this.allParams = this.allTags.length==0?'': this.allTags.join(',')
      let params = {
        "areacode": this.areacode[2],
        "loanLabel": this.allParams,
        "pageNum": this.pageNum,
        "pageSize": 15,
        "productType": this.productType
      }
      const res = await this.$post(
        "/loan-api/api/loanProduct/mini/list",
        params
      );
      this.loanProductList = res.data.content;
      console.log(this.loanProductList);
      this.totalSize = res.data.totalSize
      this.isloading = false;
    },
    screenBigType(productType) {
      this.productType = productType;
      this.getLoanProductList();
    },
    screenProfession(index) {
      let curIndex = this.current1.indexOf(index)
      if(curIndex==-1){
        this.current1.push(index)
      }else{
        this.current1.splice(curIndex,1)
      }
      let temp = []
      this.current1.forEach((item)=>{
        temp.push(this.profession[item].value)
      })
      this.getLoanProductList(temp);
    },
    screenUsing(index) {
      let curIndex = this.current2.indexOf(index)
      if(curIndex==-1){
        this.current2.push(index)
      }else{
        this.current2.splice(curIndex,1)
      }
      let temp = []
      this.current2.forEach((item)=>{
        temp.push(this.using[item].value)
      })
      this.getLoanProductList(temp);
    },
    screenType(index) {
      let curIndex = this.current3.indexOf(index)
      if(curIndex==-1){
        this.current3.push(index)
      }else{
        this.current3.splice(curIndex,1)
      }
      let temp = []
      this.current3.forEach((item)=>{
        temp.push(this.type[item].value)
      })
      this.getLoanProductList(temp);
    },
    screenBack(index) {
      let curIndex = this.current4.indexOf(index)
      if(curIndex==-1){
        this.current4.push(index)
      }else{
        this.current4.splice(curIndex,1)
      }
      let temp = []
      this.current4.forEach((item)=>{
        temp.push(this.payback[item].value)
      })
      this.getLoanProductList(temp);
    },
    handleArea(value) {
      this.areacode = value;
      this.getLoanProductList();
    },
    async handlePageChange(page) {
      this.pageNum = page;
      console.log(page);
      this.getLoanProductList();
    },
  },
};
</script>

<style scoped>
.block {
  height: 28px;
  margin-right: 0.625rem;
  margin-bottom: 0.375rem;
}
::v-deep .el-input__inner {
  height: 28px;
  line-height: 28px;
}
::v-deep .el-pagination__editor .el-input__inner {
  width: 2.5rem !important;
  height: 28px;
  line-height: 28px;
}

.banner {
  box-sizing: border-box;
  width: 100%;
  /* height: 12.8125rem; */
  /* padding: 4.25rem 0 4.25rem 2.38rem; */
  margin-bottom: 7rem;
  background-position: center center;
    background-repeat: no-repeat;
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
}

#container .title-zh {
  text-align: left;
  font-size: 2rem;
  color: #333333;
  opacity: 1;
  margin-bottom: 0.5rem;
}
#container .title-en {
  text-align: left;
  font-size: 1rem;
  color: #333333;
  opacity: 0.5;
}
.loan .banner #container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.recommend {
  width: 75rem;
  height: 13rem;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 1.25rem 0 1.5rem 2.31rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.headline {
  height: 1.63rem;
  display: flex;
  align-items: flex-end;
}
.headline p {
  font-size: 1.25rem;
  font-weight: 400;
  color: #585858;
  margin-right: 0.94rem;
}
.headline span {
  font-size: 0.75rem;
  font-weight: 400;
  color: #b1b1b1;
}
.screen {
  margin-top: 1.13rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.screen-list {
  display: flex;
  align-items: center;
}
.screen-sort {
  width: 4.4rem;
  margin-right: 0.35rem;
  text-align: left;
  font-size: 0.88rem;
  font-weight: 400;
  color: #323232;
}
.screen-detail span {
  cursor: pointer;
  padding: 0 0.2rem;
  display: inline-block;
  font-size: 0.88rem;
  color: #777777;
  margin-right: 1rem;
}
.screen-detail span.active {
  border-radius: 0.2rem;
  color: #ffffff;
  background: #1564db;
}

.products {
  margin-top: 1.88rem;
  margin-bottom: 2.62rem;
}
.products-type {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: 2.31rem;
}
.type-box span.type-head {
  margin-right: 4.13rem;
  font-size: 1.5rem;
  color: #1564DB;
  position: relative;
}
.type-box span.type-head::before{
  content: "";
  display: block;
  position: absolute;
  left: -1rem;
  top: .625rem;
  width: 0.75rem;
  height: 0.75rem;
  background: url("../assets/img/circle.png");
  background-repeat: no-repeat;
}
.type-box span {
  margin-right: 3.94rem;
  font-size: 1rem;
  color: #969696;
  cursor: pointer;
}
.line {
  margin-top: 0.69rem;
  margin-bottom: 2.63rem;
  width: 74.94rem;
  height: 1px;
  background-color: #e6e6e6;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.products-item {
  width: 24.13rem;
  height: 14.25rem;
  background: #f5f9ff;
  margin-bottom: 1.38rem;
  display: flex;
  box-sizing: border-box;
  padding: 1.13rem 1.25rem;
  position: relative;
}
.emptybox {
  width: 24.13rem;
  height: 14.25rem;
  background: #fff;
  margin-bottom: 1.38rem;
  display: flex;
  box-sizing: border-box;
  padding: 1.13rem 1.25rem;
  position: relative;
}
.products-item:hover .after {
    cursor: pointer;
  /* content: ""; */
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5)  ; */
  background:rgba(255, 255, 255, 0.85);
  border: 1px solid #e6e6e6;
   /* background-image: url("../assets/img/mini_code.png"); */
  border-radius: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40%;
}

.item-logo {
  width: 3.88rem;
  height: 3.88rem;
  background: #ffffff;
}
img {
  width: 100%;
  height: 100%;
}
.item-info {
  margin-left: 0.6rem;
  width: 18.13rem;
  height: 12.57rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.item-info .name {
  font-size: 1.13rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.5rem;
  color: #1f1f1f;
  opacity: 1;
  margin-bottom: 0.31rem;
}
.item-info .tags span {
  height: 1.19rem;
  background: #e4eeff;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.19rem;
  color: #1564db;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  padding: 0.13rem .6rem;
  flex-shrink: 0;
  word-break: keep-all;
}
.elipse{
  display: flex;
  flex-wrap: wrap;
  height: 3.8rem;
  overflow: hidden;
}
.item-info .limit,
.item-info .profit {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.5rem;
  color: #707070;
  margin-top: 0.21rem;
}
.aside {
  display: inline-block;
  width: 4.88rem;
  height: 1.5rem;
  text-align: left;
  line-height: 1.5rem;
}
.number {
  font-size: 1.13rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 1.5rem;
  color: #d93340;
  opacity: 1;
}
.item-info .applynow {
  display: block;
  width: 9rem;
  height: 2.38rem;
  background: #1564db;
  opacity: 1;
  border-radius: 1rem;
  margin: .8rem 0 1rem 1.84rem;
}
.applynow span {
  font-size: 1.13rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 2.38rem;
}
::v-deep .el-input__inner {
  width: 130px;
}
.emptybox:nth-child(1) {
  display: none !important;
}
</style>
