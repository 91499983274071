var area_values = [ {
	"label": "河南省",
	"value": 41,
	"children": [{
		"label": "郑州市",
		"value": 410100000000,
		"children": [{
			"label": "中原区",
			"value": 410102000000
		}, {
			"label": "二七区",
			"value": 410103000000
		}, {
			"label": "管城回族区",
			"value": 410104000000
		}, {
			"label": "金水区",
			"value": 410105000000
		}, {
			"label": "上街区",
			"value": 410106000000
		}, {
			"label": "惠济区",
			"value": 410108000000
		}, {
			"label": "中牟县",
			"value": 410122000000
		}, {
			"label": "巩义市",
			"value": 410181000000
		}, {
			"label": "荥阳市",
			"value": 410182000000
		}, {
			"label": "新密市",
			"value": 410183000000
		}, {
			"label": "新郑市",
			"value": 410184000000
		}, {
			"label": "登封市",
			"value": 410185000000
		}]
	}, {
		"label": "开封市",
		"value": 410200000000,
		"children": [{
			"label": "龙亭区",
			"value": 410202000000
		}, {
			"label": "顺河回族区",
			"value": 410203000000
		}, {
			"label": "鼓楼区",
			"value": 410204000000
		}, {
			"label": "禹王台区",
			"value": 410205000000
		}, {
			"label": "金明区",
			"value": 410211000000
		}, {
			"label": "祥符区",
			"value": 410212000000
		}, {
			"label": "杞县",
			"value": 410221000000
		}, {
			"label": "通许县",
			"value": 410222000000
		}, {
			"label": "尉氏县",
			"value": 410223000000
		}, {
			"label": "兰考县",
			"value": 410225000000
		}]
	}, {
		"label": "洛阳市",
		"value": 410300000000,
		"children": [{
			"label": "老城区",
			"value": 410302000000
		}, {
			"label": "西工区",
			"value": 410303000000
		}, {
			"label": "瀍河回族区",
			"value": 410304000000
		}, {
			"label": "涧西区",
			"value": 410305000000
		}, {
			"label": "吉利区",
			"value": 410306000000
		}, {
			"label": "洛龙区",
			"value": 410311000000
		}, {
			"label": "孟津县",
			"value": 410322000000
		}, {
			"label": "新安县",
			"value": 410323000000
		}, {
			"label": "栾川县",
			"value": 410324000000
		}, {
			"label": "嵩县",
			"value": 410325000000
		}, {
			"label": "汝阳县",
			"value": 410326000000
		}, {
			"label": "宜阳县",
			"value": 410327000000
		}, {
			"label": "洛宁县",
			"value": 410328000000
		}, {
			"label": "伊川县",
			"value": 410329000000
		}, {
			"label": "偃师市",
			"value": 410381000000
		}]
	}, {
		"label": "平顶山市",
		"value": 410400000000,
		"children": [{
			"label": "新华区",
			"value": 410402000000
		}, {
			"label": "卫东区",
			"value": 410403000000
		}, {
			"label": "石龙区",
			"value": 410404000000
		}, {
			"label": "湛河区",
			"value": 410411000000
		}, {
			"label": "宝丰县",
			"value": 410421000000
		}, {
			"label": "叶县",
			"value": 410422000000
		}, {
			"label": "鲁山县",
			"value": 410423000000
		}, {
			"label": "郏县",
			"value": 410425000000
		}, {
			"label": "舞钢市",
			"value": 410481000000
		}, {
			"label": "汝州市",
			"value": 410482000000
		}]
	}, {
		"label": "安阳市",
		"value": 410500000000,
		"children": [{
			"label": "文峰区",
			"value": 410502000000
		}, {
			"label": "北关区",
			"value": 410503000000
		}, {
			"label": "殷都区",
			"value": 410505000000
		}, {
			"label": "龙安区",
			"value": 410506000000
		}, {
			"label": "安阳县",
			"value": 410522000000
		}, {
			"label": "汤阴县",
			"value": 410523000000
		}, {
			"label": "滑县",
			"value": 410526000000
		}, {
			"label": "内黄县",
			"value": 410527000000
		}, {
			"label": "林州市",
			"value": 410581000000
		}]
	}, {
		"label": "鹤壁市",
		"value": 410600000000,
		"children": [{
			"label": "鹤山区",
			"value": 410602000000
		}, {
			"label": "山城区",
			"value": 410603000000
		}, {
			"label": "淇滨区",
			"value": 410611000000
		}, {
			"label": "浚县",
			"value": 410621000000
		}, {
			"label": "淇县",
			"value": 410622000000
		}]
	}, {
		"label": "新乡市",
		"value": 410700000000,
		"children": [{
			"label": "红旗区",
			"value": 410702000000
		}, {
			"label": "卫滨区",
			"value": 410703000000
		}, {
			"label": "凤泉区",
			"value": 410704000000
		}, {
			"label": "牧野区",
			"value": 410711000000
		}, {
			"label": "新乡县",
			"value": 410721000000
		}, {
			"label": "获嘉县",
			"value": 410724000000
		}, {
			"label": "原阳县",
			"value": 410725000000
		}, {
			"label": "延津县",
			"value": 410726000000
		}, {
			"label": "封丘县",
			"value": 410727000000
		}, {
			"label": "长垣县",
			"value": 410728000000
		}, {
			"label": "卫辉市",
			"value": 410781000000
		}, {
			"label": "辉县市",
			"value": 410782000000
		}]
	}, {
		"label": "焦作市",
		"value": 410800000000,
		"children": [{
			"label": "解放区",
			"value": 410802000000
		}, {
			"label": "中站区",
			"value": 410803000000
		}, {
			"label": "马村区",
			"value": 410804000000
		}, {
			"label": "山阳区",
			"value": 410811000000
		}, {
			"label": "修武县",
			"value": 410821000000
		}, {
			"label": "博爱县",
			"value": 410822000000
		}, {
			"label": "武陟县",
			"value": 410823000000
		}, {
			"label": "温县",
			"value": 410825000000
		}, {
			"label": "沁阳市",
			"value": 410882000000
		}, {
			"label": "孟州市",
			"value": 410883000000
		}]
	}, {
		"label": "濮阳市",
		"value": 410900000000,
		"children": [{
			"label": "华龙区",
			"value": 410902000000
		}, {
			"label": "清丰县",
			"value": 410922000000
		}, {
			"label": "南乐县",
			"value": 410923000000
		}, {
			"label": "范县",
			"value": 410926000000
		}, {
			"label": "台前县",
			"value": 410927000000
		}, {
			"label": "濮阳县",
			"value": 410928000000
		}]
	}, {
		"label": "许昌市",
		"value": 411000000000,
		"children": [{
			"label": "魏都区",
			"value": 411002000000
		}, {
			"label": "建安区",
			"value": 411003000000
		}, {
			"label": "许昌区",
			"value": 411023000000
		}, {
			"label": "鄢陵县",
			"value": 411024000000
		}, {
			"label": "襄城县",
			"value": 411025000000
		}, {
			"label": "禹州市",
			"value": 411081000000
		}, {
			"label": "长葛市",
			"value": 411082000000
		}]
	}, {
		"label": "漯河市",
		"value": 411100000000,
		"children": [{
			"label": "源汇区",
			"value": 411102000000
		}, {
			"label": "郾城区",
			"value": 411103000000
		}, {
			"label": "召陵区",
			"value": 411104000000
		}, {
			"label": "舞阳县",
			"value": 411121000000
		}, {
			"label": "临颍县",
			"value": 411122000000
		}]
	}, {
		"label": "三门峡市",
		"value": 411200000000,
		"children": [{
			"label": "湖滨区",
			"value": 411202000000
		}, {
			"label": "渑池县",
			"value": 411221000000
		}, {
			"label": "陕州区",
			"value": 411222000000
		}, {
			"label": "卢氏县",
			"value": 411224000000
		}, {
			"label": "义马市",
			"value": 411281000000
		}, {
			"label": "灵宝市",
			"value": 411282000000
		}]
	}, {
		"label": "南阳市",
		"value": 411300000000,
		"children": [{
			"label": "宛城区",
			"value": 411302000000
		}, {
			"label": "卧龙区",
			"value": 411303000000
		}, {
			"label": "南召县",
			"value": 411321000000
		}, {
			"label": "方城县",
			"value": 411322000000
		}, {
			"label": "西峡县",
			"value": 411323000000
		}, {
			"label": "镇平县",
			"value": 411324000000
		}, {
			"label": "内乡县",
			"value": 411325000000
		}, {
			"label": "淅川县",
			"value": 411326000000
		}, {
			"label": "社旗县",
			"value": 411327000000
		}, {
			"label": "唐河县",
			"value": 411328000000
		}, {
			"label": "新野县",
			"value": 411329000000
		}, {
			"label": "桐柏县",
			"value": 411330000000
		}, {
			"label": "邓州市",
			"value": 411381000000
		}]
	}, {
		"label": "商丘市",
		"value": 411400000000,
		"children": [{
			"label": "梁园区",
			"value": 411402000000
		}, {
			"label": "睢阳区",
			"value": 411403000000
		}, {
			"label": "民权县",
			"value": 411421000000
		}, {
			"label": "睢县",
			"value": 411422000000
		}, {
			"label": "宁陵县",
			"value": 411423000000
		}, {
			"label": "柘城县",
			"value": 411424000000
		}, {
			"label": "虞城县",
			"value": 411425000000
		}, {
			"label": "夏邑县",
			"value": 411426000000
		}, {
			"label": "永城市",
			"value": 411481000000
		}]
	}, {
		"label": "信阳市",
		"value": 411500000000,
		"children": [{
			"label": "浉河区",
			"value": 411502000000
		}, {
			"label": "平桥区",
			"value": 411503000000
		}, {
			"label": "罗山县",
			"value": 411521000000
		}, {
			"label": "光山县",
			"value": 411522000000
		}, {
			"label": "新县",
			"value": 411523000000
		}, {
			"label": "商城县",
			"value": 411524000000
		}, {
			"label": "固始县",
			"value": 411525000000
		}, {
			"label": "潢川县",
			"value": 411526000000
		}, {
			"label": "淮滨县",
			"value": 411527000000
		}, {
			"label": "息县",
			"value": 411528000000
		}]
	}, {
		"label": "周口市",
		"value": 411600000000,
		"children": [{
			"label": "川汇区",
			"value": 411602000000
		}, {
			"label": "扶沟县",
			"value": 411621000000
		}, {
			"label": "西华县",
			"value": 411622000000
		}, {
			"label": "商水县",
			"value": 411623000000
		}, {
			"label": "沈丘县",
			"value": 411624000000
		}, {
			"label": "郸城县",
			"value": 411625000000
		}, {
			"label": "淮阳区",
			"value": 411626000000
		}, {
			"label": "太康县",
			"value": 411627000000
		}, {
			"label": "鹿邑县",
			"value": 411628000000
		}, {
			"label": "项城市",
			"value": 411681000000
		}]
	}, {
		"label": "驻马店市",
		"value": 411700000000,
		"children": [{
			"label": "驿城区",
			"value": 411702000000
		}, {
			"label": "西平县",
			"value": 411721000000
		}, {
			"label": "上蔡县",
			"value": 411722000000
		}, {
			"label": "平舆县",
			"value": 411723000000
		}, {
			"label": "正阳县",
			"value": 411724000000
		}, {
			"label": "确山县",
			"value": 411725000000
		}, {
			"label": "泌阳县",
			"value": 411726000000
		}, {
			"label": "汝南县",
			"value": 411727000000
		}, {
			"label": "遂平县",
			"value": 411728000000
		}, {
			"label": "新蔡县",
			"value": 411729000000
		}]
	}, {
		"label": "省直辖县级行政区划",
		"value": 419000000000,
		"children": [{
			"label": "济源市",
			"value": 419001000000
		}]
	}]
}, {
	"label": "北京市",
	"value": 11,
	"children": [{
		"label": "市辖区",
		"value": 110100000000,
		"children": [{
			"label": "东城区",
			"value": 110101000000
		}, {
			"label": "西城区",
			"value": 110102000000
		}, {
			"label": "朝阳区",
			"value": 110105000000
		}, {
			"label": "丰台区",
			"value": 110106000000
		}, {
			"label": "石景山区",
			"value": 110107000000
		}, {
			"label": "海淀区",
			"value": 110108000000
		}, {
			"label": "门头沟区",
			"value": 110109000000
		}, {
			"label": "房山区",
			"value": 110111000000
		}, {
			"label": "通州区",
			"value": 110112000000
		}, {
			"label": "顺义区",
			"value": 110113000000
		}, {
			"label": "昌平区",
			"value": 110114000000
		}, {
			"label": "大兴区",
			"value": 110115000000
		}, {
			"label": "怀柔区",
			"value": 110116000000
		}, {
			"label": "平谷区",
			"value": 110117000000
		}]
	}, {
		"label": "县",
		"value": 110200000000,
		"children": [{
			"label": "密云县",
			"value": 110228000000
		}, {
			"label": "延庆县",
			"value": 110229000000
		}]
	}]
}, {
	"label": "天津市",
	"value": 12,
	"children": [{
		"label": "市辖区",
		"value": 120100000000,
		"children": [{
			"label": "和平区",
			"value": 120101000000
		}, {
			"label": "河东区",
			"value": 120102000000
		}, {
			"label": "河西区",
			"value": 120103000000
		}, {
			"label": "南开区",
			"value": 120104000000
		}, {
			"label": "河北区",
			"value": 120105000000
		}, {
			"label": "红桥区",
			"value": 120106000000
		}, {
			"label": "东丽区",
			"value": 120110000000
		}, {
			"label": "西青区",
			"value": 120111000000
		}, {
			"label": "津南区",
			"value": 120112000000
		}, {
			"label": "北辰区",
			"value": 120113000000
		}, {
			"label": "武清区",
			"value": 120114000000
		}, {
			"label": "宝坻区",
			"value": 120115000000
		}, {
			"label": "滨海新区",
			"value": 120116000000
		}, {
			"label": "宁河区",
			"value": 120117000000
		}, {
			"label": "静海区",
			"value": 120118000000
		}]
	}, {
		"label": "县",
		"value": 120200000000,
		"children": [{
			"label": "蓟县",
			"value": 120225000000
		}]
	}]
}, {
	"label": "河北省",
	"value": 13,
	"children": [{
		"label": "石家庄市",
		"value": 130100000000,
		"children": [{
			"label": "长安区",
			"value": 130102000000
		}, {
			"label": "桥西区",
			"value": 130104000000
		}, {
			"label": "新华区",
			"value": 130105000000
		}, {
			"label": "井陉矿区",
			"value": 130107000000
		}, {
			"label": "裕华区",
			"value": 130108000000
		}, {
			"label": "藁城区",
			"value": 130109000000
		}, {
			"label": "鹿泉区",
			"value": 130110000000
		}, {
			"label": "栾城区",
			"value": 130111000000
		}, {
			"label": "井陉县",
			"value": 130121000000
		}, {
			"label": "正定县",
			"value": 130123000000
		}, {
			"label": "行唐县",
			"value": 130125000000
		}, {
			"label": "灵寿县",
			"value": 130126000000
		}, {
			"label": "高邑县",
			"value": 130127000000
		}, {
			"label": "深泽县",
			"value": 130128000000
		}, {
			"label": "赞皇县",
			"value": 130129000000
		}, {
			"label": "无极县",
			"value": 130130000000
		}, {
			"label": "平山县",
			"value": 130131000000
		}, {
			"label": "元氏县",
			"value": 130132000000
		}, {
			"label": "赵县",
			"value": 130133000000
		}, {
			"label": "晋州市",
			"value": 130183000000
		}, {
			"label": "新乐市",
			"value": 130184000000
		}]
	}, {
		"label": "唐山市",
		"value": 130200000000,
		"children": [{
			"label": "路南区",
			"value": 130202000000
		}, {
			"label": "路北区",
			"value": 130203000000
		}, {
			"label": "古冶区",
			"value": 130204000000
		}, {
			"label": "开平区",
			"value": 130205000000
		}, {
			"label": "丰南区",
			"value": 130207000000
		}, {
			"label": "丰润区",
			"value": 130208000000
		}, {
			"label": "曹妃甸区",
			"value": 130209000000
		}, {
			"label": "滦县",
			"value": 130223000000
		}, {
			"label": "滦南县",
			"value": 130224000000
		}, {
			"label": "乐亭县",
			"value": 130225000000
		}, {
			"label": "迁西县",
			"value": 130227000000
		}, {
			"label": "玉田县",
			"value": 130229000000
		}, {
			"label": "遵化市",
			"value": 130281000000
		}, {
			"label": "迁安市",
			"value": 130283000000
		}]
	}, {
		"label": "秦皇岛市",
		"value": 130300000000,
		"children": [{
			"label": "海港区",
			"value": 130302000000
		}, {
			"label": "山海关区",
			"value": 130303000000
		}, {
			"label": "北戴河区",
			"value": 130304000000
		}, {
			"label": "抚宁区",
			"value": 130306000000
		}, {
			"label": "青龙满族自治县",
			"value": 130321000000
		}, {
			"label": "昌黎县",
			"value": 130322000000
		}, {
			"label": "卢龙县",
			"value": 130324000000
		}]
	}, {
		"label": "邯郸市",
		"value": 130400000000,
		"children": [{
			"label": "邯山区",
			"value": 130402000000
		}, {
			"label": "丛台区",
			"value": 130403000000
		}, {
			"label": "复兴区",
			"value": 130404000000
		}, {
			"label": "峰峰矿区",
			"value": 130406000000
		}, {
			"label": "邯郸县",
			"value": 130421000000
		}, {
			"label": "临漳县",
			"value": 130423000000
		}, {
			"label": "成安县",
			"value": 130424000000
		}, {
			"label": "大名县",
			"value": 130425000000
		}, {
			"label": "涉县",
			"value": 130426000000
		}, {
			"label": "磁县",
			"value": 130427000000
		}, {
			"label": "肥乡县",
			"value": 130428000000
		}, {
			"label": "永年县",
			"value": 130429000000
		}, {
			"label": "邱县",
			"value": 130430000000
		}, {
			"label": "鸡泽县",
			"value": 130431000000
		}, {
			"label": "广平县",
			"value": 130432000000
		}, {
			"label": "馆陶县",
			"value": 130433000000
		}, {
			"label": "魏县",
			"value": 130434000000
		}, {
			"label": "曲周县",
			"value": 130435000000
		}, {
			"label": "武安市",
			"value": 130481000000
		}]
	}, {
		"label": "邢台市",
		"value": 130500000000,
		"children": [{
			"label": "桥东区",
			"value": 130502000000
		}, {
			"label": "桥西区",
			"value": 130503000000
		}, {
			"label": "邢台县",
			"value": 130521000000
		}, {
			"label": "临城县",
			"value": 130522000000
		}, {
			"label": "内丘县",
			"value": 130523000000
		}, {
			"label": "柏乡县",
			"value": 130524000000
		}, {
			"label": "隆尧县",
			"value": 130525000000
		}, {
			"label": "任县",
			"value": 130526000000
		}, {
			"label": "南和县",
			"value": 130527000000
		}, {
			"label": "宁晋县",
			"value": 130528000000
		}, {
			"label": "巨鹿县",
			"value": 130529000000
		}, {
			"label": "新河县",
			"value": 130530000000
		}, {
			"label": "广宗县",
			"value": 130531000000
		}, {
			"label": "平乡县",
			"value": 130532000000
		}, {
			"label": "威县",
			"value": 130533000000
		}, {
			"label": "清河县",
			"value": 130534000000
		}, {
			"label": "临西县",
			"value": 130535000000
		}, {
			"label": "南宫市",
			"value": 130581000000
		}, {
			"label": "沙河市",
			"value": 130582000000
		}]
	}, {
		"label": "保定市",
		"value": 130600000000,
		"children": [{
			"label": "竞秀区",
			"value": 130602000000
		}, {
			"label": "莲池区",
			"value": 130606000000
		}, {
			"label": "满城区",
			"value": 130607000000
		}, {
			"label": "清苑区",
			"value": 130608000000
		}, {
			"label": "徐水区",
			"value": 130609000000
		}, {
			"label": "涞水县",
			"value": 130623000000
		}, {
			"label": "阜平县",
			"value": 130624000000
		}, {
			"label": "定兴县",
			"value": 130626000000
		}, {
			"label": "唐县",
			"value": 130627000000
		}, {
			"label": "高阳县",
			"value": 130628000000
		}, {
			"label": "容城县",
			"value": 130629000000
		}, {
			"label": "涞源县",
			"value": 130630000000
		}, {
			"label": "望都县",
			"value": 130631000000
		}, {
			"label": "安新县",
			"value": 130632000000
		}, {
			"label": "易县",
			"value": 130633000000
		}, {
			"label": "曲阳县",
			"value": 130634000000
		}, {
			"label": "蠡县",
			"value": 130635000000
		}, {
			"label": "顺平县",
			"value": 130636000000
		}, {
			"label": "博野县",
			"value": 130637000000
		}, {
			"label": "雄县",
			"value": 130638000000
		}, {
			"label": "涿州市",
			"value": 130681000000
		}, {
			"label": "安国市",
			"value": 130683000000
		}, {
			"label": "高碑店市",
			"value": 130684000000
		}]
	}, {
		"label": "张家口市",
		"value": 130700000000,
		"children": [{
			"label": "桥东区",
			"value": 130702000000
		}, {
			"label": "桥西区",
			"value": 130703000000
		}, {
			"label": "宣化区",
			"value": 130705000000
		}, {
			"label": "下花园区",
			"value": 130706000000
		}, {
			"label": "宣化县",
			"value": 130721000000
		}, {
			"label": "张北县",
			"value": 130722000000
		}, {
			"label": "康保县",
			"value": 130723000000
		}, {
			"label": "沽源县",
			"value": 130724000000
		}, {
			"label": "尚义县",
			"value": 130725000000
		}, {
			"label": "蔚县",
			"value": 130726000000
		}, {
			"label": "阳原县",
			"value": 130727000000
		}, {
			"label": "怀安县",
			"value": 130728000000
		}, {
			"label": "万全县",
			"value": 130729000000
		}, {
			"label": "怀来县",
			"value": 130730000000
		}, {
			"label": "涿鹿县",
			"value": 130731000000
		}, {
			"label": "赤城县",
			"value": 130732000000
		}, {
			"label": "崇礼县",
			"value": 130733000000
		}]
	}, {
		"label": "承德市",
		"value": 130800000000,
		"children": [{
			"label": "双桥区",
			"value": 130802000000
		}, {
			"label": "双滦区",
			"value": 130803000000
		}, {
			"label": "鹰手营子矿区",
			"value": 130804000000
		}, {
			"label": "承德县",
			"value": 130821000000
		}, {
			"label": "兴隆县",
			"value": 130822000000
		}, {
			"label": "平泉县",
			"value": 130823000000
		}, {
			"label": "滦平县",
			"value": 130824000000
		}, {
			"label": "隆化县",
			"value": 130825000000
		}, {
			"label": "丰宁满族自治县",
			"value": 130826000000
		}, {
			"label": "宽城满族自治县",
			"value": 130827000000
		}, {
			"label": "围场满族蒙古族自治县",
			"value": 130828000000
		}]
	}, {
		"label": "沧州市",
		"value": 130900000000,
		"children": [{
			"label": "新华区",
			"value": 130902000000
		}, {
			"label": "运河区",
			"value": 130903000000
		}, {
			"label": "沧县",
			"value": 130921000000
		}, {
			"label": "青县",
			"value": 130922000000
		}, {
			"label": "东光县",
			"value": 130923000000
		}, {
			"label": "海兴县",
			"value": 130924000000
		}, {
			"label": "盐山县",
			"value": 130925000000
		}, {
			"label": "肃宁县",
			"value": 130926000000
		}, {
			"label": "南皮县",
			"value": 130927000000
		}, {
			"label": "吴桥县",
			"value": 130928000000
		}, {
			"label": "献县",
			"value": 130929000000
		}, {
			"label": "孟村回族自治县",
			"value": 130930000000
		}, {
			"label": "泊头市",
			"value": 130981000000
		}, {
			"label": "任丘市",
			"value": 130982000000
		}, {
			"label": "黄骅市",
			"value": 130983000000
		}, {
			"label": "河间市",
			"value": 130984000000
		}]
	}, {
		"label": "廊坊市",
		"value": 131000000000,
		"children": [{
			"label": "安次区",
			"value": 131002000000
		}, {
			"label": "广阳区",
			"value": 131003000000
		}, {
			"label": "固安县",
			"value": 131022000000
		}, {
			"label": "永清县",
			"value": 131023000000
		}, {
			"label": "香河县",
			"value": 131024000000
		}, {
			"label": "大城县",
			"value": 131025000000
		}, {
			"label": "文安县",
			"value": 131026000000
		}, {
			"label": "大厂回族自治县",
			"value": 131028000000
		}, {
			"label": "霸州市",
			"value": 131081000000
		}, {
			"label": "三河市",
			"value": 131082000000
		}]
	}, {
		"label": "衡水市",
		"value": 131100000000,
		"children": [{
			"label": "桃城区",
			"value": 131102000000
		}, {
			"label": "枣强县",
			"value": 131121000000
		}, {
			"label": "武邑县",
			"value": 131122000000
		}, {
			"label": "武强县",
			"value": 131123000000
		}, {
			"label": "饶阳县",
			"value": 131124000000
		}, {
			"label": "安平县",
			"value": 131125000000
		}, {
			"label": "故城县",
			"value": 131126000000
		}, {
			"label": "景县",
			"value": 131127000000
		}, {
			"label": "阜城县",
			"value": 131128000000
		}, {
			"label": "冀州市",
			"value": 131181000000
		}, {
			"label": "深州市",
			"value": 131182000000
		}]
	}, {
		"label": "省直辖县级行政区划",
		"value": 139000000000,
		"children": [{
			"label": "定州市",
			"value": 139001000000
		}, {
			"label": "辛集市",
			"value": 139002000000
		}]
	}]
}, {
	"label": "山西省",
	"value": 14,
	"children": [{
		"label": "太原市",
		"value": 140100000000,
		"children": [{
			"label": "小店区",
			"value": 140105000000
		}, {
			"label": "迎泽区",
			"value": 140106000000
		}, {
			"label": "杏花岭区",
			"value": 140107000000
		}, {
			"label": "尖草坪区",
			"value": 140108000000
		}, {
			"label": "万柏林区",
			"value": 140109000000
		}, {
			"label": "晋源区",
			"value": 140110000000
		}, {
			"label": "清徐县",
			"value": 140121000000
		}, {
			"label": "阳曲县",
			"value": 140122000000
		}, {
			"label": "娄烦县",
			"value": 140123000000
		}, {
			"label": "古交市",
			"value": 140181000000
		}]
	}, {
		"label": "大同市",
		"value": 140200000000,
		"children": [{
			"label": "城区",
			"value": 140202000000
		}, {
			"label": "矿区",
			"value": 140203000000
		}, {
			"label": "南郊区",
			"value": 140211000000
		}, {
			"label": "新荣区",
			"value": 140212000000
		}, {
			"label": "阳高县",
			"value": 140221000000
		}, {
			"label": "天镇县",
			"value": 140222000000
		}, {
			"label": "广灵县",
			"value": 140223000000
		}, {
			"label": "灵丘县",
			"value": 140224000000
		}, {
			"label": "浑源县",
			"value": 140225000000
		}, {
			"label": "左云县",
			"value": 140226000000
		}, {
			"label": "大同县",
			"value": 140227000000
		}]
	}, {
		"label": "阳泉市",
		"value": 140300000000,
		"children": [{
			"label": "城区",
			"value": 140302000000
		}, {
			"label": "矿区",
			"value": 140303000000
		}, {
			"label": "郊区",
			"value": 140311000000
		}, {
			"label": "平定县",
			"value": 140321000000
		}, {
			"label": "盂县",
			"value": 140322000000
		}]
	}, {
		"label": "长治市",
		"value": 140400000000,
		"children": [{
			"label": "城区",
			"value": 140402000000
		}, {
			"label": "郊区",
			"value": 140411000000
		}, {
			"label": "长治县",
			"value": 140421000000
		}, {
			"label": "襄垣县",
			"value": 140423000000
		}, {
			"label": "屯留县",
			"value": 140424000000
		}, {
			"label": "平顺县",
			"value": 140425000000
		}, {
			"label": "黎城县",
			"value": 140426000000
		}, {
			"label": "壶关县",
			"value": 140427000000
		}, {
			"label": "长子县",
			"value": 140428000000
		}, {
			"label": "武乡县",
			"value": 140429000000
		}, {
			"label": "沁县",
			"value": 140430000000
		}, {
			"label": "沁源县",
			"value": 140431000000
		}, {
			"label": "潞城市",
			"value": 140481000000
		}]
	}, {
		"label": "晋城市",
		"value": 140500000000,
		"children": [{
			"label": "城区",
			"value": 140502000000
		}, {
			"label": "沁水县",
			"value": 140521000000
		}, {
			"label": "阳城县",
			"value": 140522000000
		}, {
			"label": "陵川县",
			"value": 140524000000
		}, {
			"label": "泽州县",
			"value": 140525000000
		}, {
			"label": "高平市",
			"value": 140581000000
		}]
	}, {
		"label": "朔州市",
		"value": 140600000000,
		"children": [{
			"label": "朔城区",
			"value": 140602000000
		}, {
			"label": "平鲁区",
			"value": 140603000000
		}, {
			"label": "山阴县",
			"value": 140621000000
		}, {
			"label": "应县",
			"value": 140622000000
		}, {
			"label": "右玉县",
			"value": 140623000000
		}, {
			"label": "怀仁县",
			"value": 140624000000
		}]
	}, {
		"label": "晋中市",
		"value": 140700000000,
		"children": [{
			"label": "榆次区",
			"value": 140702000000
		}, {
			"label": "榆社县",
			"value": 140721000000
		}, {
			"label": "左权县",
			"value": 140722000000
		}, {
			"label": "和顺县",
			"value": 140723000000
		}, {
			"label": "昔阳县",
			"value": 140724000000
		}, {
			"label": "寿阳县",
			"value": 140725000000
		}, {
			"label": "太谷县",
			"value": 140726000000
		}, {
			"label": "祁县",
			"value": 140727000000
		}, {
			"label": "平遥县",
			"value": 140728000000
		}, {
			"label": "灵石县",
			"value": 140729000000
		}, {
			"label": "介休市",
			"value": 140781000000
		}]
	}, {
		"label": "运城市",
		"value": 140800000000,
		"children": [{
			"label": "盐湖区",
			"value": 140802000000
		}, {
			"label": "临猗县",
			"value": 140821000000
		}, {
			"label": "万荣县",
			"value": 140822000000
		}, {
			"label": "闻喜县",
			"value": 140823000000
		}, {
			"label": "稷山县",
			"value": 140824000000
		}, {
			"label": "新绛县",
			"value": 140825000000
		}, {
			"label": "绛县",
			"value": 140826000000
		}, {
			"label": "垣曲县",
			"value": 140827000000
		}, {
			"label": "夏县",
			"value": 140828000000
		}, {
			"label": "平陆县",
			"value": 140829000000
		}, {
			"label": "芮城县",
			"value": 140830000000
		}, {
			"label": "永济市",
			"value": 140881000000
		}, {
			"label": "河津市",
			"value": 140882000000
		}]
	}, {
		"label": "忻州市",
		"value": 140900000000,
		"children": [{
			"label": "忻府区",
			"value": 140902000000
		}, {
			"label": "定襄县",
			"value": 140921000000
		}, {
			"label": "五台县",
			"value": 140922000000
		}, {
			"label": "代县",
			"value": 140923000000
		}, {
			"label": "繁峙县",
			"value": 140924000000
		}, {
			"label": "宁武县",
			"value": 140925000000
		}, {
			"label": "静乐县",
			"value": 140926000000
		}, {
			"label": "神池县",
			"value": 140927000000
		}, {
			"label": "五寨县",
			"value": 140928000000
		}, {
			"label": "岢岚县",
			"value": 140929000000
		}, {
			"label": "河曲县",
			"value": 140930000000
		}, {
			"label": "保德县",
			"value": 140931000000
		}, {
			"label": "偏关县",
			"value": 140932000000
		}, {
			"label": "原平市",
			"value": 140981000000
		}]
	}, {
		"label": "临汾市",
		"value": 141000000000,
		"children": [{
			"label": "尧都区",
			"value": 141002000000
		}, {
			"label": "曲沃县",
			"value": 141021000000
		}, {
			"label": "翼城县",
			"value": 141022000000
		}, {
			"label": "襄汾县",
			"value": 141023000000
		}, {
			"label": "洪洞县",
			"value": 141024000000
		}, {
			"label": "古县",
			"value": 141025000000
		}, {
			"label": "安泽县",
			"value": 141026000000
		}, {
			"label": "浮山县",
			"value": 141027000000
		}, {
			"label": "吉县",
			"value": 141028000000
		}, {
			"label": "乡宁县",
			"value": 141029000000
		}, {
			"label": "大宁县",
			"value": 141030000000
		}, {
			"label": "隰县",
			"value": 141031000000
		}, {
			"label": "永和县",
			"value": 141032000000
		}, {
			"label": "蒲县",
			"value": 141033000000
		}, {
			"label": "汾西县",
			"value": 141034000000
		}, {
			"label": "侯马市",
			"value": 141081000000
		}, {
			"label": "霍州市",
			"value": 141082000000
		}]
	}, {
		"label": "吕梁市",
		"value": 141100000000,
		"children": [{
			"label": "离石区",
			"value": 141102000000
		}, {
			"label": "文水县",
			"value": 141121000000
		}, {
			"label": "交城县",
			"value": 141122000000
		}, {
			"label": "兴县",
			"value": 141123000000
		}, {
			"label": "临县",
			"value": 141124000000
		}, {
			"label": "柳林县",
			"value": 141125000000
		}, {
			"label": "石楼县",
			"value": 141126000000
		}, {
			"label": "岚县",
			"value": 141127000000
		}, {
			"label": "方山县",
			"value": 141128000000
		}, {
			"label": "中阳县",
			"value": 141129000000
		}, {
			"label": "交口县",
			"value": 141130000000
		}, {
			"label": "孝义市",
			"value": 141181000000
		}, {
			"label": "汾阳市",
			"value": 141182000000
		}]
	}]
}, {
	"label": "内蒙古自治区",
	"value": 15,
	"children": [{
		"label": "呼和浩特市",
		"value": 150100000000,
		"children": [{
			"label": "新城区",
			"value": 150102000000
		}, {
			"label": "回民区",
			"value": 150103000000
		}, {
			"label": "玉泉区",
			"value": 150104000000
		}, {
			"label": "赛罕区",
			"value": 150105000000
		}, {
			"label": "土默特左旗",
			"value": 150121000000
		}, {
			"label": "托克托县",
			"value": 150122000000
		}, {
			"label": "和林格尔县",
			"value": 150123000000
		}, {
			"label": "清水河县",
			"value": 150124000000
		}, {
			"label": "武川县",
			"value": 150125000000
		}]
	}, {
		"label": "包头市",
		"value": 150200000000,
		"children": [{
			"label": "东河区",
			"value": 150202000000
		}, {
			"label": "昆都仑区",
			"value": 150203000000
		}, {
			"label": "青山区",
			"value": 150204000000
		}, {
			"label": "石拐区",
			"value": 150205000000
		}, {
			"label": "白云鄂博矿区",
			"value": 150206000000
		}, {
			"label": "九原区",
			"value": 150207000000
		}, {
			"label": "土默特右旗",
			"value": 150221000000
		}, {
			"label": "固阳县",
			"value": 150222000000
		}, {
			"label": "达尔罕茂明安联合旗",
			"value": 150223000000
		}]
	}, {
		"label": "乌海市",
		"value": 150300000000,
		"children": [{
			"label": "海勃湾区",
			"value": 150302000000
		}, {
			"label": "海南区",
			"value": 150303000000
		}, {
			"label": "乌达区",
			"value": 150304000000
		}]
	}, {
		"label": "赤峰市",
		"value": 150400000000,
		"children": [{
			"label": "红山区",
			"value": 150402000000
		}, {
			"label": "元宝山区",
			"value": 150403000000
		}, {
			"label": "松山区",
			"value": 150404000000
		}, {
			"label": "阿鲁科尔沁旗",
			"value": 150421000000
		}, {
			"label": "巴林左旗",
			"value": 150422000000
		}, {
			"label": "巴林右旗",
			"value": 150423000000
		}, {
			"label": "林西县",
			"value": 150424000000
		}, {
			"label": "克什克腾旗",
			"value": 150425000000
		}, {
			"label": "翁牛特旗",
			"value": 150426000000
		}, {
			"label": "喀喇沁旗",
			"value": 150428000000
		}, {
			"label": "宁城县",
			"value": 150429000000
		}, {
			"label": "敖汉旗",
			"value": 150430000000
		}]
	}, {
		"label": "通辽市",
		"value": 150500000000,
		"children": [{
			"label": "科尔沁区",
			"value": 150502000000
		}, {
			"label": "科尔沁左翼中旗",
			"value": 150521000000
		}, {
			"label": "科尔沁左翼后旗",
			"value": 150522000000
		}, {
			"label": "开鲁县",
			"value": 150523000000
		}, {
			"label": "库伦旗",
			"value": 150524000000
		}, {
			"label": "奈曼旗",
			"value": 150525000000
		}, {
			"label": "扎鲁特旗",
			"value": 150526000000
		}, {
			"label": "霍林郭勒市",
			"value": 150581000000
		}]
	}, {
		"label": "鄂尔多斯市",
		"value": 150600000000,
		"children": [{
			"label": "东胜区",
			"value": 150602000000
		}, {
			"label": "达拉特旗",
			"value": 150621000000
		}, {
			"label": "准格尔旗",
			"value": 150622000000
		}, {
			"label": "鄂托克前旗",
			"value": 150623000000
		}, {
			"label": "鄂托克旗",
			"value": 150624000000
		}, {
			"label": "杭锦旗",
			"value": 150625000000
		}, {
			"label": "乌审旗",
			"value": 150626000000
		}, {
			"label": "伊金霍洛旗",
			"value": 150627000000
		}]
	}, {
		"label": "呼伦贝尔市",
		"value": 150700000000,
		"children": [{
			"label": "海拉尔区",
			"value": 150702000000
		}, {
			"label": "扎赉诺尔区",
			"value": 150703000000
		}, {
			"label": "阿荣旗",
			"value": 150721000000
		}, {
			"label": "莫力达瓦达斡尔族自治旗",
			"value": 150722000000
		}, {
			"label": "鄂伦春自治旗",
			"value": 150723000000
		}, {
			"label": "鄂温克族自治旗",
			"value": 150724000000
		}, {
			"label": "陈巴尔虎旗",
			"value": 150725000000
		}, {
			"label": "新巴尔虎左旗",
			"value": 150726000000
		}, {
			"label": "新巴尔虎右旗",
			"value": 150727000000
		}, {
			"label": "满洲里市",
			"value": 150781000000
		}, {
			"label": "牙克石市",
			"value": 150782000000
		}, {
			"label": "扎兰屯市",
			"value": 150783000000
		}, {
			"label": "额尔古纳市",
			"value": 150784000000
		}, {
			"label": "根河市",
			"value": 150785000000
		}]
	}, {
		"label": "巴彦淖尔市",
		"value": 150800000000,
		"children": [{
			"label": "临河区",
			"value": 150802000000
		}, {
			"label": "五原县",
			"value": 150821000000
		}, {
			"label": "磴口县",
			"value": 150822000000
		}, {
			"label": "乌拉特前旗",
			"value": 150823000000
		}, {
			"label": "乌拉特中旗",
			"value": 150824000000
		}, {
			"label": "乌拉特后旗",
			"value": 150825000000
		}, {
			"label": "杭锦后旗",
			"value": 150826000000
		}]
	}, {
		"label": "乌兰察布市",
		"value": 150900000000,
		"children": [{
			"label": "集宁区",
			"value": 150902000000
		}, {
			"label": "卓资县",
			"value": 150921000000
		}, {
			"label": "化德县",
			"value": 150922000000
		}, {
			"label": "商都县",
			"value": 150923000000
		}, {
			"label": "兴和县",
			"value": 150924000000
		}, {
			"label": "凉城县",
			"value": 150925000000
		}, {
			"label": "察哈尔右翼前旗",
			"value": 150926000000
		}, {
			"label": "察哈尔右翼中旗",
			"value": 150927000000
		}, {
			"label": "察哈尔右翼后旗",
			"value": 150928000000
		}, {
			"label": "四子王旗",
			"value": 150929000000
		}, {
			"label": "丰镇市",
			"value": 150981000000
		}]
	}, {
		"label": "兴安盟",
		"value": 152200000000,
		"children": [{
			"label": "乌兰浩特市",
			"value": 152201000000
		}, {
			"label": "阿尔山市",
			"value": 152202000000
		}, {
			"label": "科尔沁右翼前旗",
			"value": 152221000000
		}, {
			"label": "科尔沁右翼中旗",
			"value": 152222000000
		}, {
			"label": "扎赉特旗",
			"value": 152223000000
		}, {
			"label": "突泉县",
			"value": 152224000000
		}]
	}, {
		"label": "锡林郭勒盟",
		"value": 152500000000,
		"children": [{
			"label": "二连浩特市",
			"value": 152501000000
		}, {
			"label": "锡林浩特市",
			"value": 152502000000
		}, {
			"label": "阿巴嘎旗",
			"value": 152522000000
		}, {
			"label": "苏尼特左旗",
			"value": 152523000000
		}, {
			"label": "苏尼特右旗",
			"value": 152524000000
		}, {
			"label": "东乌珠穆沁旗",
			"value": 152525000000
		}, {
			"label": "西乌珠穆沁旗",
			"value": 152526000000
		}, {
			"label": "太仆寺旗",
			"value": 152527000000
		}, {
			"label": "镶黄旗",
			"value": 152528000000
		}, {
			"label": "正镶白旗",
			"value": 152529000000
		}, {
			"label": "正蓝旗",
			"value": 152530000000
		}, {
			"label": "多伦县",
			"value": 152531000000
		}]
	}, {
		"label": "阿拉善盟",
		"value": 152900000000,
		"children": [{
			"label": "阿拉善左旗",
			"value": 152921000000
		}, {
			"label": "阿拉善右旗",
			"value": 152922000000
		}, {
			"label": "额济纳旗",
			"value": 152923000000
		}]
	}]
}, {
	"label": "辽宁省",
	"value": 21,
	"children": [{
		"label": "沈阳市",
		"value": 210100000000,
		"children": [{
			"label": "和平区",
			"value": 210102000000
		}, {
			"label": "沈河区",
			"value": 210103000000
		}, {
			"label": "大东区",
			"value": 210104000000
		}, {
			"label": "皇姑区",
			"value": 210105000000
		}, {
			"label": "铁西区",
			"value": 210106000000
		}, {
			"label": "苏家屯区",
			"value": 210111000000
		}, {
			"label": "浑南区",
			"value": 210112000000
		}, {
			"label": "沈北新区",
			"value": 210113000000
		}, {
			"label": "于洪区",
			"value": 210114000000
		}, {
			"label": "辽中县",
			"value": 210122000000
		}, {
			"label": "康平县",
			"value": 210123000000
		}, {
			"label": "法库县",
			"value": 210124000000
		}, {
			"label": "新民市",
			"value": 210181000000
		}]
	}, {
		"label": "大连市",
		"value": 210200000000,
		"children": [{
			"label": "中山区",
			"value": 210202000000
		}, {
			"label": "西岗区",
			"value": 210203000000
		}, {
			"label": "沙河口区",
			"value": 210204000000
		}, {
			"label": "甘井子区",
			"value": 210211000000
		}, {
			"label": "旅顺口区",
			"value": 210212000000
		}, {
			"label": "金州区",
			"value": 210213000000
		}, {
			"label": "长海县",
			"value": 210224000000
		}, {
			"label": "瓦房店市",
			"value": 210281000000
		}, {
			"label": "普兰店市",
			"value": 210282000000
		}, {
			"label": "庄河市",
			"value": 210283000000
		}]
	}, {
		"label": "鞍山市",
		"value": 210300000000,
		"children": [{
			"label": "铁东区",
			"value": 210302000000
		}, {
			"label": "铁西区",
			"value": 210303000000
		}, {
			"label": "立山区",
			"value": 210304000000
		}, {
			"label": "千山区",
			"value": 210311000000
		}, {
			"label": "台安县",
			"value": 210321000000
		}, {
			"label": "岫岩满族自治县",
			"value": 210323000000
		}, {
			"label": "海城市",
			"value": 210381000000
		}]
	}, {
		"label": "抚顺市",
		"value": 210400000000,
		"children": [{
			"label": "新抚区",
			"value": 210402000000
		}, {
			"label": "东洲区",
			"value": 210403000000
		}, {
			"label": "望花区",
			"value": 210404000000
		}, {
			"label": "顺城区",
			"value": 210411000000
		}, {
			"label": "抚顺县",
			"value": 210421000000
		}, {
			"label": "新宾满族自治县",
			"value": 210422000000
		}, {
			"label": "清原满族自治县",
			"value": 210423000000
		}]
	}, {
		"label": "本溪市",
		"value": 210500000000,
		"children": [{
			"label": "平山区",
			"value": 210502000000
		}, {
			"label": "溪湖区",
			"value": 210503000000
		}, {
			"label": "明山区",
			"value": 210504000000
		}, {
			"label": "南芬区",
			"value": 210505000000
		}, {
			"label": "本溪满族自治县",
			"value": 210521000000
		}, {
			"label": "桓仁满族自治县",
			"value": 210522000000
		}]
	}, {
		"label": "丹东市",
		"value": 210600000000,
		"children": [{
			"label": "元宝区",
			"value": 210602000000
		}, {
			"label": "振兴区",
			"value": 210603000000
		}, {
			"label": "振安区",
			"value": 210604000000
		}, {
			"label": "宽甸满族自治县",
			"value": 210624000000
		}, {
			"label": "东港市",
			"value": 210681000000
		}, {
			"label": "凤城市",
			"value": 210682000000
		}]
	}, {
		"label": "锦州市",
		"value": 210700000000,
		"children": [{
			"label": "古塔区",
			"value": 210702000000
		}, {
			"label": "凌河区",
			"value": 210703000000
		}, {
			"label": "太和区",
			"value": 210711000000
		}, {
			"label": "黑山县",
			"value": 210726000000
		}, {
			"label": "义县",
			"value": 210727000000
		}, {
			"label": "凌海市",
			"value": 210781000000
		}, {
			"label": "北镇市",
			"value": 210782000000
		}]
	}, {
		"label": "营口市",
		"value": 210800000000,
		"children": [{
			"label": "站前区",
			"value": 210802000000
		}, {
			"label": "西市区",
			"value": 210803000000
		}, {
			"label": "鲅鱼圈区",
			"value": 210804000000
		}, {
			"label": "老边区",
			"value": 210811000000
		}, {
			"label": "盖州市",
			"value": 210881000000
		}, {
			"label": "大石桥市",
			"value": 210882000000
		}]
	}, {
		"label": "阜新市",
		"value": 210900000000,
		"children": [{
			"label": "海州区",
			"value": 210902000000
		}, {
			"label": "新邱区",
			"value": 210903000000
		}, {
			"label": "太平区",
			"value": 210904000000
		}, {
			"label": "清河门区",
			"value": 210905000000
		}, {
			"label": "细河区",
			"value": 210911000000
		}, {
			"label": "阜新蒙古族自治县",
			"value": 210921000000
		}, {
			"label": "彰武县",
			"value": 210922000000
		}]
	}, {
		"label": "辽阳市",
		"value": 211000000000,
		"children": [{
			"label": "白塔区",
			"value": 211002000000
		}, {
			"label": "文圣区",
			"value": 211003000000
		}, {
			"label": "宏伟区",
			"value": 211004000000
		}, {
			"label": "弓长岭区",
			"value": 211005000000
		}, {
			"label": "太子河区",
			"value": 211011000000
		}, {
			"label": "辽阳县",
			"value": 211021000000
		}, {
			"label": "灯塔市",
			"value": 211081000000
		}]
	}, {
		"label": "盘锦市",
		"value": 211100000000,
		"children": [{
			"label": "双台子区",
			"value": 211102000000
		}, {
			"label": "兴隆台区",
			"value": 211103000000
		}, {
			"label": "大洼县",
			"value": 211121000000
		}, {
			"label": "盘山县",
			"value": 211122000000
		}]
	}, {
		"label": "铁岭市",
		"value": 211200000000,
		"children": [{
			"label": "银州区",
			"value": 211202000000
		}, {
			"label": "清河区",
			"value": 211204000000
		}, {
			"label": "铁岭县",
			"value": 211221000000
		}, {
			"label": "西丰县",
			"value": 211223000000
		}, {
			"label": "昌图县",
			"value": 211224000000
		}, {
			"label": "调兵山市",
			"value": 211281000000
		}, {
			"label": "开原市",
			"value": 211282000000
		}]
	}, {
		"label": "朝阳市",
		"value": 211300000000,
		"children": [{
			"label": "双塔区",
			"value": 211302000000
		}, {
			"label": "龙城区",
			"value": 211303000000
		}, {
			"label": "朝阳县",
			"value": 211321000000
		}, {
			"label": "建平县",
			"value": 211322000000
		}, {
			"label": "喀喇沁左翼蒙古族自治县",
			"value": 211324000000
		}, {
			"label": "北票市",
			"value": 211381000000
		}, {
			"label": "凌源市",
			"value": 211382000000
		}]
	}, {
		"label": "葫芦岛市",
		"value": 211400000000,
		"children": [{
			"label": "连山区",
			"value": 211402000000
		}, {
			"label": "龙港区",
			"value": 211403000000
		}, {
			"label": "南票区",
			"value": 211404000000
		}, {
			"label": "绥中县",
			"value": 211421000000
		}, {
			"label": "建昌县",
			"value": 211422000000
		}, {
			"label": "兴城市",
			"value": 211481000000
		}]
	}]
}, {
	"label": "吉林省",
	"value": 22,
	"children": [{
		"label": "长春市",
		"value": 220100000000,
		"children": [{
			"label": "南关区",
			"value": 220102000000
		}, {
			"label": "宽城区",
			"value": 220103000000
		}, {
			"label": "朝阳区",
			"value": 220104000000
		}, {
			"label": "二道区",
			"value": 220105000000
		}, {
			"label": "绿园区",
			"value": 220106000000
		}, {
			"label": "双阳区",
			"value": 220112000000
		}, {
			"label": "九台区",
			"value": 220113000000
		}, {
			"label": "农安县",
			"value": 220122000000
		}, {
			"label": "榆树市",
			"value": 220182000000
		}, {
			"label": "德惠市",
			"value": 220183000000
		}]
	}, {
		"label": "吉林市",
		"value": 220200000000,
		"children": [{
			"label": "昌邑区",
			"value": 220202000000
		}, {
			"label": "龙潭区",
			"value": 220203000000
		}, {
			"label": "船营区",
			"value": 220204000000
		}, {
			"label": "丰满区",
			"value": 220211000000
		}, {
			"label": "永吉县",
			"value": 220221000000
		}, {
			"label": "蛟河市",
			"value": 220281000000
		}, {
			"label": "桦甸市",
			"value": 220282000000
		}, {
			"label": "舒兰市",
			"value": 220283000000
		}, {
			"label": "磐石市",
			"value": 220284000000
		}]
	}, {
		"label": "四平市",
		"value": 220300000000,
		"children": [{
			"label": "铁西区",
			"value": 220302000000
		}, {
			"label": "铁东区",
			"value": 220303000000
		}, {
			"label": "梨树县",
			"value": 220322000000
		}, {
			"label": "伊通满族自治县",
			"value": 220323000000
		}, {
			"label": "公主岭市",
			"value": 220381000000
		}, {
			"label": "双辽市",
			"value": 220382000000
		}]
	}, {
		"label": "辽源市",
		"value": 220400000000,
		"children": [{
			"label": "龙山区",
			"value": 220402000000
		}, {
			"label": "西安区",
			"value": 220403000000
		}, {
			"label": "东丰县",
			"value": 220421000000
		}, {
			"label": "东辽县",
			"value": 220422000000
		}]
	}, {
		"label": "通化市",
		"value": 220500000000,
		"children": [{
			"label": "东昌区",
			"value": 220502000000
		}, {
			"label": "二道江区",
			"value": 220503000000
		}, {
			"label": "通化县",
			"value": 220521000000
		}, {
			"label": "辉南县",
			"value": 220523000000
		}, {
			"label": "柳河县",
			"value": 220524000000
		}, {
			"label": "梅河口市",
			"value": 220581000000
		}, {
			"label": "集安市",
			"value": 220582000000
		}]
	}, {
		"label": "白山市",
		"value": 220600000000,
		"children": [{
			"label": "浑江区",
			"value": 220602000000
		}, {
			"label": "江源区",
			"value": 220605000000
		}, {
			"label": "抚松县",
			"value": 220621000000
		}, {
			"label": "靖宇县",
			"value": 220622000000
		}, {
			"label": "长白朝鲜族自治县",
			"value": 220623000000
		}, {
			"label": "临江市",
			"value": 220681000000
		}]
	}, {
		"label": "松原市",
		"value": 220700000000,
		"children": [{
			"label": "宁江区",
			"value": 220702000000
		}, {
			"label": "前郭尔罗斯蒙古族自治县",
			"value": 220721000000
		}, {
			"label": "长岭县",
			"value": 220722000000
		}, {
			"label": "乾安县",
			"value": 220723000000
		}, {
			"label": "扶余市",
			"value": 220781000000
		}]
	}, {
		"label": "白城市",
		"value": 220800000000,
		"children": [{
			"label": "洮北区",
			"value": 220802000000
		}, {
			"label": "镇赉县",
			"value": 220821000000
		}, {
			"label": "通榆县",
			"value": 220822000000
		}, {
			"label": "洮南市",
			"value": 220881000000
		}, {
			"label": "大安市",
			"value": 220882000000
		}]
	}, {
		"label": "延边朝鲜族自治州",
		"value": 222400000000,
		"children": [{
			"label": "延吉市",
			"value": 222401000000
		}, {
			"label": "图们市",
			"value": 222402000000
		}, {
			"label": "敦化市",
			"value": 222403000000
		}, {
			"label": "珲春市",
			"value": 222404000000
		}, {
			"label": "龙井市",
			"value": 222405000000
		}, {
			"label": "和龙市",
			"value": 222406000000
		}, {
			"label": "汪清县",
			"value": 222424000000
		}, {
			"label": "安图县",
			"value": 222426000000
		}]
	}]
}, {
	"label": "黑龙江省",
	"value": 23,
	"children": [{
		"label": "哈尔滨市",
		"value": 230100000000,
		"children": [{
			"label": "道里区",
			"value": 230102000000
		}, {
			"label": "南岗区",
			"value": 230103000000
		}, {
			"label": "道外区",
			"value": 230104000000
		}, {
			"label": "平房区",
			"value": 230108000000
		}, {
			"label": "松北区",
			"value": 230109000000
		}, {
			"label": "香坊区",
			"value": 230110000000
		}, {
			"label": "呼兰区",
			"value": 230111000000
		}, {
			"label": "阿城区",
			"value": 230112000000
		}, {
			"label": "双城区",
			"value": 230113000000
		}, {
			"label": "依兰县",
			"value": 230123000000
		}, {
			"label": "方正县",
			"value": 230124000000
		}, {
			"label": "宾县",
			"value": 230125000000
		}, {
			"label": "巴彦县",
			"value": 230126000000
		}, {
			"label": "木兰县",
			"value": 230127000000
		}, {
			"label": "通河县",
			"value": 230128000000
		}, {
			"label": "延寿县",
			"value": 230129000000
		}, {
			"label": "尚志市",
			"value": 230183000000
		}, {
			"label": "五常市",
			"value": 230184000000
		}]
	}, {
		"label": "齐齐哈尔市",
		"value": 230200000000,
		"children": [{
			"label": "龙沙区",
			"value": 230202000000
		}, {
			"label": "建华区",
			"value": 230203000000
		}, {
			"label": "铁锋区",
			"value": 230204000000
		}, {
			"label": "昂昂溪区",
			"value": 230205000000
		}, {
			"label": "富拉尔基区",
			"value": 230206000000
		}, {
			"label": "碾子山区",
			"value": 230207000000
		}, {
			"label": "梅里斯达斡尔族区",
			"value": 230208000000
		}, {
			"label": "龙江县",
			"value": 230221000000
		}, {
			"label": "依安县",
			"value": 230223000000
		}, {
			"label": "泰来县",
			"value": 230224000000
		}, {
			"label": "甘南县",
			"value": 230225000000
		}, {
			"label": "富裕县",
			"value": 230227000000
		}, {
			"label": "克山县",
			"value": 230229000000
		}, {
			"label": "克东县",
			"value": 230230000000
		}, {
			"label": "拜泉县",
			"value": 230231000000
		}, {
			"label": "讷河市",
			"value": 230281000000
		}]
	}, {
		"label": "鸡西市",
		"value": 230300000000,
		"children": [{
			"label": "鸡冠区",
			"value": 230302000000
		}, {
			"label": "恒山区",
			"value": 230303000000
		}, {
			"label": "滴道区",
			"value": 230304000000
		}, {
			"label": "梨树区",
			"value": 230305000000
		}, {
			"label": "城子河区",
			"value": 230306000000
		}, {
			"label": "麻山区",
			"value": 230307000000
		}, {
			"label": "鸡东县",
			"value": 230321000000
		}, {
			"label": "虎林市",
			"value": 230381000000
		}, {
			"label": "密山市",
			"value": 230382000000
		}]
	}, {
		"label": "鹤岗市",
		"value": 230400000000,
		"children": [{
			"label": "向阳区",
			"value": 230402000000
		}, {
			"label": "工农区",
			"value": 230403000000
		}, {
			"label": "南山区",
			"value": 230404000000
		}, {
			"label": "兴安区",
			"value": 230405000000
		}, {
			"label": "东山区",
			"value": 230406000000
		}, {
			"label": "兴山区",
			"value": 230407000000
		}, {
			"label": "萝北县",
			"value": 230421000000
		}, {
			"label": "绥滨县",
			"value": 230422000000
		}]
	}, {
		"label": "双鸭山市",
		"value": 230500000000,
		"children": [{
			"label": "尖山区",
			"value": 230502000000
		}, {
			"label": "岭东区",
			"value": 230503000000
		}, {
			"label": "四方台区",
			"value": 230505000000
		}, {
			"label": "宝山区",
			"value": 230506000000
		}, {
			"label": "集贤县",
			"value": 230521000000
		}, {
			"label": "友谊县",
			"value": 230522000000
		}, {
			"label": "宝清县",
			"value": 230523000000
		}, {
			"label": "饶河县",
			"value": 230524000000
		}]
	}, {
		"label": "大庆市",
		"value": 230600000000,
		"children": [{
			"label": "萨尔图区",
			"value": 230602000000
		}, {
			"label": "龙凤区",
			"value": 230603000000
		}, {
			"label": "让胡路区",
			"value": 230604000000
		}, {
			"label": "红岗区",
			"value": 230605000000
		}, {
			"label": "大同区",
			"value": 230606000000
		}, {
			"label": "肇州县",
			"value": 230621000000
		}, {
			"label": "肇源县",
			"value": 230622000000
		}, {
			"label": "林甸县",
			"value": 230623000000
		}, {
			"label": "杜尔伯特蒙古族自治县",
			"value": 230624000000
		}]
	}, {
		"label": "伊春市",
		"value": 230700000000,
		"children": [{
			"label": "伊春区",
			"value": 230702000000
		}, {
			"label": "南岔区",
			"value": 230703000000
		}, {
			"label": "友好区",
			"value": 230704000000
		}, {
			"label": "西林区",
			"value": 230705000000
		}, {
			"label": "翠峦区",
			"value": 230706000000
		}, {
			"label": "新青区",
			"value": 230707000000
		}, {
			"label": "美溪区",
			"value": 230708000000
		}, {
			"label": "金山屯区",
			"value": 230709000000
		}, {
			"label": "五营区",
			"value": 230710000000
		}, {
			"label": "乌马河区",
			"value": 230711000000
		}, {
			"label": "汤旺河区",
			"value": 230712000000
		}, {
			"label": "带岭区",
			"value": 230713000000
		}, {
			"label": "乌伊岭区",
			"value": 230714000000
		}, {
			"label": "红星区",
			"value": 230715000000
		}, {
			"label": "上甘岭区",
			"value": 230716000000
		}, {
			"label": "嘉荫县",
			"value": 230722000000
		}, {
			"label": "铁力市",
			"value": 230781000000
		}]
	}, {
		"label": "佳木斯市",
		"value": 230800000000,
		"children": [{
			"label": "向阳区",
			"value": 230803000000
		}, {
			"label": "前进区",
			"value": 230804000000
		}, {
			"label": "东风区",
			"value": 230805000000
		}, {
			"label": "郊区",
			"value": 230811000000
		}, {
			"label": "桦南县",
			"value": 230822000000
		}, {
			"label": "桦川县",
			"value": 230826000000
		}, {
			"label": "汤原县",
			"value": 230828000000
		}, {
			"label": "抚远县",
			"value": 230833000000
		}, {
			"label": "同江市",
			"value": 230881000000
		}, {
			"label": "富锦市",
			"value": 230882000000
		}]
	}, {
		"label": "七台河市",
		"value": 230900000000,
		"children": [{
			"label": "新兴区",
			"value": 230902000000
		}, {
			"label": "桃山区",
			"value": 230903000000
		}, {
			"label": "茄子河区",
			"value": 230904000000
		}, {
			"label": "勃利县",
			"value": 230921000000
		}]
	}, {
		"label": "牡丹江市",
		"value": 231000000000,
		"children": [{
			"label": "东安区",
			"value": 231002000000
		}, {
			"label": "阳明区",
			"value": 231003000000
		}, {
			"label": "爱民区",
			"value": 231004000000
		}, {
			"label": "西安区",
			"value": 231005000000
		}, {
			"label": "东宁县",
			"value": 231024000000
		}, {
			"label": "林口县",
			"value": 231025000000
		}, {
			"label": "绥芬河市",
			"value": 231081000000
		}, {
			"label": "海林市",
			"value": 231083000000
		}, {
			"label": "宁安市",
			"value": 231084000000
		}, {
			"label": "穆棱市",
			"value": 231085000000
		}]
	}, {
		"label": "黑河市",
		"value": 231100000000,
		"children": [{
			"label": "爱辉区",
			"value": 231102000000
		}, {
			"label": "嫩江县",
			"value": 231121000000
		}, {
			"label": "逊克县",
			"value": 231123000000
		}, {
			"label": "孙吴县",
			"value": 231124000000
		}, {
			"label": "北安市",
			"value": 231181000000
		}, {
			"label": "五大连池市",
			"value": 231182000000
		}]
	}, {
		"label": "绥化市",
		"value": 231200000000,
		"children": [{
			"label": "北林区",
			"value": 231202000000
		}, {
			"label": "望奎县",
			"value": 231221000000
		}, {
			"label": "兰西县",
			"value": 231222000000
		}, {
			"label": "青冈县",
			"value": 231223000000
		}, {
			"label": "庆安县",
			"value": 231224000000
		}, {
			"label": "明水县",
			"value": 231225000000
		}, {
			"label": "绥棱县",
			"value": 231226000000
		}, {
			"label": "安达市",
			"value": 231281000000
		}, {
			"label": "肇东市",
			"value": 231282000000
		}, {
			"label": "海伦市",
			"value": 231283000000
		}]
	}, {
		"label": "大兴安岭地区",
		"value": 232700000000,
		"children": [{
			"label": "呼玛县",
			"value": 232721000000
		}, {
			"label": "塔河县",
			"value": 232722000000
		}, {
			"label": "漠河县",
			"value": 232723000000
		}]
	}]
}, {
	"label": "上海市",
	"value": 31,
	"children": [{
		"label": "市辖区",
		"value": 310100000000,
		"children": [{
			"label": "黄浦区",
			"value": 310101000000
		}, {
			"label": "徐汇区",
			"value": 310104000000
		}, {
			"label": "长宁区",
			"value": 310105000000
		}, {
			"label": "静安区",
			"value": 310106000000
		}, {
			"label": "普陀区",
			"value": 310107000000
		}, {
			"label": "闸北区",
			"value": 310108000000
		}, {
			"label": "虹口区",
			"value": 310109000000
		}, {
			"label": "杨浦区",
			"value": 310110000000
		}, {
			"label": "闵行区",
			"value": 310112000000
		}, {
			"label": "宝山区",
			"value": 310113000000
		}, {
			"label": "嘉定区",
			"value": 310114000000
		}, {
			"label": "浦东新区",
			"value": 310115000000
		}, {
			"label": "金山区",
			"value": 310116000000
		}, {
			"label": "松江区",
			"value": 310117000000
		}, {
			"label": "青浦区",
			"value": 310118000000
		}, {
			"label": "奉贤区",
			"value": 310120000000
		}]
	}, {
		"label": "县",
		"value": 310200000000,
		"children": [{
			"label": "崇明县",
			"value": 310230000000
		}]
	}]
}, {
	"label": "江苏省",
	"value": 32,
	"children": [{
		"label": "南京市",
		"value": 320100000000,
		"children": [{
			"label": "玄武区",
			"value": 320102000000
		}, {
			"label": "秦淮区",
			"value": 320104000000
		}, {
			"label": "建邺区",
			"value": 320105000000
		}, {
			"label": "鼓楼区",
			"value": 320106000000
		}, {
			"label": "浦口区",
			"value": 320111000000
		}, {
			"label": "栖霞区",
			"value": 320113000000
		}, {
			"label": "雨花台区",
			"value": 320114000000
		}, {
			"label": "江宁区",
			"value": 320115000000
		}, {
			"label": "六合区",
			"value": 320116000000
		}, {
			"label": "溧水区",
			"value": 320117000000
		}, {
			"label": "高淳区",
			"value": 320118000000
		}]
	}, {
		"label": "无锡市",
		"value": 320200000000,
		"children": [{
			"label": "崇安区",
			"value": 320202000000
		}, {
			"label": "南长区",
			"value": 320203000000
		}, {
			"label": "北塘区",
			"value": 320204000000
		}, {
			"label": "锡山区",
			"value": 320205000000
		}, {
			"label": "惠山区",
			"value": 320206000000
		}, {
			"label": "滨湖区",
			"value": 320211000000
		}, {
			"label": "江阴市",
			"value": 320281000000
		}, {
			"label": "宜兴市",
			"value": 320282000000
		}]
	}, {
		"label": "徐州市",
		"value": 320300000000,
		"children": [{
			"label": "鼓楼区",
			"value": 320302000000
		}, {
			"label": "云龙区",
			"value": 320303000000
		}, {
			"label": "贾汪区",
			"value": 320305000000
		}, {
			"label": "泉山区",
			"value": 320311000000
		}, {
			"label": "铜山区",
			"value": 320312000000
		}, {
			"label": "丰县",
			"value": 320321000000
		}, {
			"label": "沛县",
			"value": 320322000000
		}, {
			"label": "睢宁县",
			"value": 320324000000
		}, {
			"label": "新沂市",
			"value": 320381000000
		}, {
			"label": "邳州市",
			"value": 320382000000
		}]
	}, {
		"label": "常州市",
		"value": 320400000000,
		"children": [{
			"label": "天宁区",
			"value": 320402000000
		}, {
			"label": "钟楼区",
			"value": 320404000000
		}, {
			"label": "新北区",
			"value": 320411000000
		}, {
			"label": "武进区",
			"value": 320412000000
		}, {
			"label": "金坛区",
			"value": 320413000000
		}, {
			"label": "溧阳市",
			"value": 320481000000
		}]
	}, {
		"label": "苏州市",
		"value": 320500000000,
		"children": [{
			"label": "虎丘区",
			"value": 320505000000
		}, {
			"label": "吴中区",
			"value": 320506000000
		}, {
			"label": "相城区",
			"value": 320507000000
		}, {
			"label": "姑苏区",
			"value": 320508000000
		}, {
			"label": "吴江区",
			"value": 320509000000
		}, {
			"label": "常熟市",
			"value": 320581000000
		}, {
			"label": "张家港市",
			"value": 320582000000
		}, {
			"label": "昆山市",
			"value": 320583000000
		}, {
			"label": "太仓市",
			"value": 320585000000
		}]
	}, {
		"label": "南通市",
		"value": 320600000000,
		"children": [{
			"label": "崇川区",
			"value": 320602000000
		}, {
			"label": "港闸区",
			"value": 320611000000
		}, {
			"label": "通州区",
			"value": 320612000000
		}, {
			"label": "海安县",
			"value": 320621000000
		}, {
			"label": "如东县",
			"value": 320623000000
		}, {
			"label": "启东市",
			"value": 320681000000
		}, {
			"label": "如皋市",
			"value": 320682000000
		}, {
			"label": "海门市",
			"value": 320684000000
		}]
	}, {
		"label": "连云港市",
		"value": 320700000000,
		"children": [{
			"label": "连云区",
			"value": 320703000000
		}, {
			"label": "海州区",
			"value": 320706000000
		}, {
			"label": "赣榆区",
			"value": 320707000000
		}, {
			"label": "东海县",
			"value": 320722000000
		}, {
			"label": "灌云县",
			"value": 320723000000
		}, {
			"label": "灌南县",
			"value": 320724000000
		}]
	}, {
		"label": "淮安市",
		"value": 320800000000,
		"children": [{
			"label": "清河区",
			"value": 320802000000
		}, {
			"label": "淮安区",
			"value": 320803000000
		}, {
			"label": "淮阴区",
			"value": 320804000000
		}, {
			"label": "清浦区",
			"value": 320811000000
		}, {
			"label": "涟水县",
			"value": 320826000000
		}, {
			"label": "洪泽县",
			"value": 320829000000
		}, {
			"label": "盱眙县",
			"value": 320830000000
		}, {
			"label": "金湖县",
			"value": 320831000000
		}]
	}, {
		"label": "盐城市",
		"value": 320900000000,
		"children": [{
			"label": "亭湖区",
			"value": 320902000000
		}, {
			"label": "盐都区",
			"value": 320903000000
		}, {
			"label": "大丰区",
			"value": 320904000000
		}, {
			"label": "响水县",
			"value": 320921000000
		}, {
			"label": "滨海县",
			"value": 320922000000
		}, {
			"label": "阜宁县",
			"value": 320923000000
		}, {
			"label": "射阳县",
			"value": 320924000000
		}, {
			"label": "建湖县",
			"value": 320925000000
		}, {
			"label": "东台市",
			"value": 320981000000
		}]
	}, {
		"label": "扬州市",
		"value": 321000000000,
		"children": [{
			"label": "广陵区",
			"value": 321002000000
		}, {
			"label": "邗江区",
			"value": 321003000000
		}, {
			"label": "江都区",
			"value": 321012000000
		}, {
			"label": "宝应县",
			"value": 321023000000
		}, {
			"label": "仪征市",
			"value": 321081000000
		}, {
			"label": "高邮市",
			"value": 321084000000
		}]
	}, {
		"label": "镇江市",
		"value": 321100000000,
		"children": [{
			"label": "京口区",
			"value": 321102000000
		}, {
			"label": "润州区",
			"value": 321111000000
		}, {
			"label": "丹徒区",
			"value": 321112000000
		}, {
			"label": "丹阳市",
			"value": 321181000000
		}, {
			"label": "扬中市",
			"value": 321182000000
		}, {
			"label": "句容市",
			"value": 321183000000
		}]
	}, {
		"label": "泰州市",
		"value": 321200000000,
		"children": [{
			"label": "海陵区",
			"value": 321202000000
		}, {
			"label": "高港区",
			"value": 321203000000
		}, {
			"label": "姜堰区",
			"value": 321204000000
		}, {
			"label": "兴化市",
			"value": 321281000000
		}, {
			"label": "靖江市",
			"value": 321282000000
		}, {
			"label": "泰兴市",
			"value": 321283000000
		}]
	}, {
		"label": "宿迁市",
		"value": 321300000000,
		"children": [{
			"label": "宿城区",
			"value": 321302000000
		}, {
			"label": "宿豫区",
			"value": 321311000000
		}, {
			"label": "沭阳县",
			"value": 321322000000
		}, {
			"label": "泗阳县",
			"value": 321323000000
		}, {
			"label": "泗洪县",
			"value": 321324000000
		}]
	}]
}, {
	"label": "浙江省",
	"value": 33,
	"children": [{
		"label": "杭州市",
		"value": 330100000000,
		"children": [{
			"label": "上城区",
			"value": 330102000000
		}, {
			"label": "下城区",
			"value": 330103000000
		}, {
			"label": "江干区",
			"value": 330104000000
		}, {
			"label": "拱墅区",
			"value": 330105000000
		}, {
			"label": "西湖区",
			"value": 330106000000
		}, {
			"label": "滨江区",
			"value": 330108000000
		}, {
			"label": "萧山区",
			"value": 330109000000
		}, {
			"label": "余杭区",
			"value": 330110000000
		}, {
			"label": "富阳区",
			"value": 330111000000
		}, {
			"label": "桐庐县",
			"value": 330122000000
		}, {
			"label": "淳安县",
			"value": 330127000000
		}, {
			"label": "建德市",
			"value": 330182000000
		}, {
			"label": "临安市",
			"value": 330185000000
		}]
	}, {
		"label": "宁波市",
		"value": 330200000000,
		"children": [{
			"label": "海曙区",
			"value": 330203000000
		}, {
			"label": "江东区",
			"value": 330204000000
		}, {
			"label": "江北区",
			"value": 330205000000
		}, {
			"label": "北仑区",
			"value": 330206000000
		}, {
			"label": "镇海区",
			"value": 330211000000
		}, {
			"label": "鄞州区",
			"value": 330212000000
		}, {
			"label": "象山县",
			"value": 330225000000
		}, {
			"label": "宁海县",
			"value": 330226000000
		}, {
			"label": "余姚市",
			"value": 330281000000
		}, {
			"label": "慈溪市",
			"value": 330282000000
		}, {
			"label": "奉化市",
			"value": 330283000000
		}]
	}, {
		"label": "温州市",
		"value": 330300000000,
		"children": [{
			"label": "鹿城区",
			"value": 330302000000
		}, {
			"label": "龙湾区",
			"value": 330303000000
		}, {
			"label": "瓯海区",
			"value": 330304000000
		}, {
			"label": "洞头区",
			"value": 330305000000
		}, {
			"label": "永嘉县",
			"value": 330324000000
		}, {
			"label": "平阳县",
			"value": 330326000000
		}, {
			"label": "苍南县",
			"value": 330327000000
		}, {
			"label": "文成县",
			"value": 330328000000
		}, {
			"label": "泰顺县",
			"value": 330329000000
		}, {
			"label": "瑞安市",
			"value": 330381000000
		}, {
			"label": "乐清市",
			"value": 330382000000
		}]
	}, {
		"label": "嘉兴市",
		"value": 330400000000,
		"children": [{
			"label": "南湖区",
			"value": 330402000000
		}, {
			"label": "秀洲区",
			"value": 330411000000
		}, {
			"label": "嘉善县",
			"value": 330421000000
		}, {
			"label": "海盐县",
			"value": 330424000000
		}, {
			"label": "海宁市",
			"value": 330481000000
		}, {
			"label": "平湖市",
			"value": 330482000000
		}, {
			"label": "桐乡市",
			"value": 330483000000
		}]
	}, {
		"label": "湖州市",
		"value": 330500000000,
		"children": [{
			"label": "吴兴区",
			"value": 330502000000
		}, {
			"label": "南浔区",
			"value": 330503000000
		}, {
			"label": "德清县",
			"value": 330521000000
		}, {
			"label": "长兴县",
			"value": 330522000000
		}, {
			"label": "安吉县",
			"value": 330523000000
		}]
	}, {
		"label": "绍兴市",
		"value": 330600000000,
		"children": [{
			"label": "越城区",
			"value": 330602000000
		}, {
			"label": "柯桥区",
			"value": 330603000000
		}, {
			"label": "上虞区",
			"value": 330604000000
		}, {
			"label": "新昌县",
			"value": 330624000000
		}, {
			"label": "诸暨市",
			"value": 330681000000
		}, {
			"label": "嵊州市",
			"value": 330683000000
		}]
	}, {
		"label": "金华市",
		"value": 330700000000,
		"children": [{
			"label": "婺城区",
			"value": 330702000000
		}, {
			"label": "金东区",
			"value": 330703000000
		}, {
			"label": "武义县",
			"value": 330723000000
		}, {
			"label": "浦江县",
			"value": 330726000000
		}, {
			"label": "磐安县",
			"value": 330727000000
		}, {
			"label": "兰溪市",
			"value": 330781000000
		}, {
			"label": "义乌市",
			"value": 330782000000
		}, {
			"label": "东阳市",
			"value": 330783000000
		}, {
			"label": "永康市",
			"value": 330784000000
		}]
	}, {
		"label": "衢州市",
		"value": 330800000000,
		"children": [{
			"label": "柯城区",
			"value": 330802000000
		}, {
			"label": "衢江区",
			"value": 330803000000
		}, {
			"label": "常山县",
			"value": 330822000000
		}, {
			"label": "开化县",
			"value": 330824000000
		}, {
			"label": "龙游县",
			"value": 330825000000
		}, {
			"label": "江山市",
			"value": 330881000000
		}]
	}, {
		"label": "舟山市",
		"value": 330900000000,
		"children": [{
			"label": "定海区",
			"value": 330902000000
		}, {
			"label": "普陀区",
			"value": 330903000000
		}, {
			"label": "岱山县",
			"value": 330921000000
		}, {
			"label": "嵊泗县",
			"value": 330922000000
		}]
	}, {
		"label": "台州市",
		"value": 331000000000,
		"children": [{
			"label": "椒江区",
			"value": 331002000000
		}, {
			"label": "黄岩区",
			"value": 331003000000
		}, {
			"label": "路桥区",
			"value": 331004000000
		}, {
			"label": "玉环县",
			"value": 331021000000
		}, {
			"label": "三门县",
			"value": 331022000000
		}, {
			"label": "天台县",
			"value": 331023000000
		}, {
			"label": "仙居县",
			"value": 331024000000
		}, {
			"label": "温岭市",
			"value": 331081000000
		}, {
			"label": "临海市",
			"value": 331082000000
		}]
	}, {
		"label": "丽水市",
		"value": 331100000000,
		"children": [{
			"label": "莲都区",
			"value": 331102000000
		}, {
			"label": "青田县",
			"value": 331121000000
		}, {
			"label": "缙云县",
			"value": 331122000000
		}, {
			"label": "遂昌县",
			"value": 331123000000
		}, {
			"label": "松阳县",
			"value": 331124000000
		}, {
			"label": "云和县",
			"value": 331125000000
		}, {
			"label": "庆元县",
			"value": 331126000000
		}, {
			"label": "景宁畲族自治县",
			"value": 331127000000
		}, {
			"label": "龙泉市",
			"value": 331181000000
		}]
	}]
}, {
	"label": "安徽省",
	"value": 34,
	"children": [{
		"label": "合肥市",
		"value": 340100000000,
		"children": [{
			"label": "瑶海区",
			"value": 340102000000
		}, {
			"label": "庐阳区",
			"value": 340103000000
		}, {
			"label": "蜀山区",
			"value": 340104000000
		}, {
			"label": "包河区",
			"value": 340111000000
		}, {
			"label": "长丰县",
			"value": 340121000000
		}, {
			"label": "肥东县",
			"value": 340122000000
		}, {
			"label": "肥西县",
			"value": 340123000000
		}, {
			"label": "庐江县",
			"value": 340124000000
		}, {
			"label": "巢湖市",
			"value": 340181000000
		}]
	}, {
		"label": "芜湖市",
		"value": 340200000000,
		"children": [{
			"label": "镜湖区",
			"value": 340202000000
		}, {
			"label": "弋江区",
			"value": 340203000000
		}, {
			"label": "鸠江区",
			"value": 340207000000
		}, {
			"label": "三山区",
			"value": 340208000000
		}, {
			"label": "芜湖县",
			"value": 340221000000
		}, {
			"label": "繁昌县",
			"value": 340222000000
		}, {
			"label": "南陵县",
			"value": 340223000000
		}, {
			"label": "无为县",
			"value": 340225000000
		}]
	}, {
		"label": "蚌埠市",
		"value": 340300000000,
		"children": [{
			"label": "龙子湖区",
			"value": 340302000000
		}, {
			"label": "蚌山区",
			"value": 340303000000
		}, {
			"label": "禹会区",
			"value": 340304000000
		}, {
			"label": "淮上区",
			"value": 340311000000
		}, {
			"label": "怀远县",
			"value": 340321000000
		}, {
			"label": "五河县",
			"value": 340322000000
		}, {
			"label": "固镇县",
			"value": 340323000000
		}]
	}, {
		"label": "淮南市",
		"value": 340400000000,
		"children": [{
			"label": "大通区",
			"value": 340402000000
		}, {
			"label": "田家庵区",
			"value": 340403000000
		}, {
			"label": "谢家集区",
			"value": 340404000000
		}, {
			"label": "八公山区",
			"value": 340405000000
		}, {
			"label": "潘集区",
			"value": 340406000000
		}, {
			"label": "凤台县",
			"value": 340421000000
		}]
	}, {
		"label": "马鞍山市",
		"value": 340500000000,
		"children": [{
			"label": "花山区",
			"value": 340503000000
		}, {
			"label": "雨山区",
			"value": 340504000000
		}, {
			"label": "博望区",
			"value": 340506000000
		}, {
			"label": "当涂县",
			"value": 340521000000
		}, {
			"label": "含山县",
			"value": 340522000000
		}, {
			"label": "和县",
			"value": 340523000000
		}]
	}, {
		"label": "淮北市",
		"value": 340600000000,
		"children": [{
			"label": "杜集区",
			"value": 340602000000
		}, {
			"label": "相山区",
			"value": 340603000000
		}, {
			"label": "烈山区",
			"value": 340604000000
		}, {
			"label": "濉溪县",
			"value": 340621000000
		}]
	}, {
		"label": "铜陵市",
		"value": 340700000000,
		"children": [{
			"label": "铜官山区",
			"value": 340702000000
		}, {
			"label": "狮子山区",
			"value": 340703000000
		}, {
			"label": "郊区",
			"value": 340711000000
		}, {
			"label": "铜陵县",
			"value": 340721000000
		}]
	}, {
		"label": "安庆市",
		"value": 340800000000,
		"children": [{
			"label": "迎江区",
			"value": 340802000000
		}, {
			"label": "大观区",
			"value": 340803000000
		}, {
			"label": "宜秀区",
			"value": 340811000000
		}, {
			"label": "怀宁县",
			"value": 340822000000
		}, {
			"label": "枞阳县",
			"value": 340823000000
		}, {
			"label": "潜山县",
			"value": 340824000000
		}, {
			"label": "太湖县",
			"value": 340825000000
		}, {
			"label": "宿松县",
			"value": 340826000000
		}, {
			"label": "望江县",
			"value": 340827000000
		}, {
			"label": "岳西县",
			"value": 340828000000
		}, {
			"label": "桐城市",
			"value": 340881000000
		}]
	}, {
		"label": "黄山市",
		"value": 341000000000,
		"children": [{
			"label": "屯溪区",
			"value": 341002000000
		}, {
			"label": "黄山区",
			"value": 341003000000
		}, {
			"label": "徽州区",
			"value": 341004000000
		}, {
			"label": "歙县",
			"value": 341021000000
		}, {
			"label": "休宁县",
			"value": 341022000000
		}, {
			"label": "黟县",
			"value": 341023000000
		}, {
			"label": "祁门县",
			"value": 341024000000
		}]
	}, {
		"label": "滁州市",
		"value": 341100000000,
		"children": [{
			"label": "琅琊区",
			"value": 341102000000
		}, {
			"label": "南谯区",
			"value": 341103000000
		}, {
			"label": "来安县",
			"value": 341122000000
		}, {
			"label": "全椒县",
			"value": 341124000000
		}, {
			"label": "定远县",
			"value": 341125000000
		}, {
			"label": "凤阳县",
			"value": 341126000000
		}, {
			"label": "天长市",
			"value": 341181000000
		}, {
			"label": "明光市",
			"value": 341182000000
		}]
	}, {
		"label": "阜阳市",
		"value": 341200000000,
		"children": [{
			"label": "颍州区",
			"value": 341202000000
		}, {
			"label": "颍东区",
			"value": 341203000000
		}, {
			"label": "颍泉区",
			"value": 341204000000
		}, {
			"label": "临泉县",
			"value": 341221000000
		}, {
			"label": "太和县",
			"value": 341222000000
		}, {
			"label": "阜南县",
			"value": 341225000000
		}, {
			"label": "颍上县",
			"value": 341226000000
		}, {
			"label": "界首市",
			"value": 341282000000
		}]
	}, {
		"label": "宿州市",
		"value": 341300000000,
		"children": [{
			"label": "埇桥区",
			"value": 341302000000
		}, {
			"label": "砀山县",
			"value": 341321000000
		}, {
			"label": "萧县",
			"value": 341322000000
		}, {
			"label": "灵璧县",
			"value": 341323000000
		}, {
			"label": "泗县",
			"value": 341324000000
		}]
	}, {
		"label": "六安市",
		"value": 341500000000,
		"children": [{
			"label": "金安区",
			"value": 341502000000
		}, {
			"label": "裕安区",
			"value": 341503000000
		}, {
			"label": "寿县",
			"value": 341521000000
		}, {
			"label": "霍邱县",
			"value": 341522000000
		}, {
			"label": "舒城县",
			"value": 341523000000
		}, {
			"label": "金寨县",
			"value": 341524000000
		}, {
			"label": "霍山县",
			"value": 341525000000
		}]
	}, {
		"label": "亳州市",
		"value": 341600000000,
		"children": [{
			"label": "谯城区",
			"value": 341602000000
		}, {
			"label": "涡阳县",
			"value": 341621000000
		}, {
			"label": "蒙城县",
			"value": 341622000000
		}, {
			"label": "利辛县",
			"value": 341623000000
		}]
	}, {
		"label": "池州市",
		"value": 341700000000,
		"children": [{
			"label": "贵池区",
			"value": 341702000000
		}, {
			"label": "东至县",
			"value": 341721000000
		}, {
			"label": "石台县",
			"value": 341722000000
		}, {
			"label": "青阳县",
			"value": 341723000000
		}]
	}, {
		"label": "宣城市",
		"value": 341800000000,
		"children": [{
			"label": "宣州区",
			"value": 341802000000
		}, {
			"label": "郎溪县",
			"value": 341821000000
		}, {
			"label": "广德县",
			"value": 341822000000
		}, {
			"label": "泾县",
			"value": 341823000000
		}, {
			"label": "绩溪县",
			"value": 341824000000
		}, {
			"label": "旌德县",
			"value": 341825000000
		}, {
			"label": "宁国市",
			"value": 341881000000
		}]
	}]
}, {
	"label": "福建省",
	"value": 35,
	"children": [{
		"label": "福州市",
		"value": 350100000000,
		"children": [{
			"label": "鼓楼区",
			"value": 350102000000
		}, {
			"label": "台江区",
			"value": 350103000000
		}, {
			"label": "仓山区",
			"value": 350104000000
		}, {
			"label": "马尾区",
			"value": 350105000000
		}, {
			"label": "晋安区",
			"value": 350111000000
		}, {
			"label": "闽侯县",
			"value": 350121000000
		}, {
			"label": "连江县",
			"value": 350122000000
		}, {
			"label": "罗源县",
			"value": 350123000000
		}, {
			"label": "闽清县",
			"value": 350124000000
		}, {
			"label": "永泰县",
			"value": 350125000000
		}, {
			"label": "平潭县",
			"value": 350128000000
		}, {
			"label": "福清市",
			"value": 350181000000
		}, {
			"label": "长乐市",
			"value": 350182000000
		}]
	}, {
		"label": "厦门市",
		"value": 350200000000,
		"children": [{
			"label": "思明区",
			"value": 350203000000
		}, {
			"label": "海沧区",
			"value": 350205000000
		}, {
			"label": "湖里区",
			"value": 350206000000
		}, {
			"label": "集美区",
			"value": 350211000000
		}, {
			"label": "同安区",
			"value": 350212000000
		}, {
			"label": "翔安区",
			"value": 350213000000
		}]
	}, {
		"label": "莆田市",
		"value": 350300000000,
		"children": [{
			"label": "城厢区",
			"value": 350302000000
		}, {
			"label": "涵江区",
			"value": 350303000000
		}, {
			"label": "荔城区",
			"value": 350304000000
		}, {
			"label": "秀屿区",
			"value": 350305000000
		}, {
			"label": "仙游县",
			"value": 350322000000
		}]
	}, {
		"label": "三明市",
		"value": 350400000000,
		"children": [{
			"label": "梅列区",
			"value": 350402000000
		}, {
			"label": "三元区",
			"value": 350403000000
		}, {
			"label": "明溪县",
			"value": 350421000000
		}, {
			"label": "清流县",
			"value": 350423000000
		}, {
			"label": "宁化县",
			"value": 350424000000
		}, {
			"label": "大田县",
			"value": 350425000000
		}, {
			"label": "尤溪县",
			"value": 350426000000
		}, {
			"label": "沙县",
			"value": 350427000000
		}, {
			"label": "将乐县",
			"value": 350428000000
		}, {
			"label": "泰宁县",
			"value": 350429000000
		}, {
			"label": "建宁县",
			"value": 350430000000
		}, {
			"label": "永安市",
			"value": 350481000000
		}]
	}, {
		"label": "泉州市",
		"value": 350500000000,
		"children": [{
			"label": "鲤城区",
			"value": 350502000000
		}, {
			"label": "丰泽区",
			"value": 350503000000
		}, {
			"label": "洛江区",
			"value": 350504000000
		}, {
			"label": "泉港区",
			"value": 350505000000
		}, {
			"label": "惠安县",
			"value": 350521000000
		}, {
			"label": "安溪县",
			"value": 350524000000
		}, {
			"label": "永春县",
			"value": 350525000000
		}, {
			"label": "德化县",
			"value": 350526000000
		}, {
			"label": "石狮市",
			"value": 350581000000
		}, {
			"label": "晋江市",
			"value": 350582000000
		}, {
			"label": "南安市",
			"value": 350583000000
		}]
	}, {
		"label": "漳州市",
		"value": 350600000000,
		"children": [{
			"label": "芗城区",
			"value": 350602000000
		}, {
			"label": "龙文区",
			"value": 350603000000
		}, {
			"label": "云霄县",
			"value": 350622000000
		}, {
			"label": "漳浦县",
			"value": 350623000000
		}, {
			"label": "诏安县",
			"value": 350624000000
		}, {
			"label": "长泰县",
			"value": 350625000000
		}, {
			"label": "东山县",
			"value": 350626000000
		}, {
			"label": "南靖县",
			"value": 350627000000
		}, {
			"label": "平和县",
			"value": 350628000000
		}, {
			"label": "华安县",
			"value": 350629000000
		}, {
			"label": "龙海市",
			"value": 350681000000
		}]
	}, {
		"label": "南平市",
		"value": 350700000000,
		"children": [{
			"label": "延平区",
			"value": 350702000000
		}, {
			"label": "建阳区",
			"value": 350703000000
		}, {
			"label": "顺昌县",
			"value": 350721000000
		}, {
			"label": "浦城县",
			"value": 350722000000
		}, {
			"label": "光泽县",
			"value": 350723000000
		}, {
			"label": "松溪县",
			"value": 350724000000
		}, {
			"label": "政和县",
			"value": 350725000000
		}, {
			"label": "邵武市",
			"value": 350781000000
		}, {
			"label": "武夷山市",
			"value": 350782000000
		}, {
			"label": "建瓯市",
			"value": 350783000000
		}]
	}, {
		"label": "龙岩市",
		"value": 350800000000,
		"children": [{
			"label": "新罗区",
			"value": 350802000000
		}, {
			"label": "永定区",
			"value": 350803000000
		}, {
			"label": "长汀县",
			"value": 350821000000
		}, {
			"label": "上杭县",
			"value": 350823000000
		}, {
			"label": "武平县",
			"value": 350824000000
		}, {
			"label": "连城县",
			"value": 350825000000
		}, {
			"label": "漳平市",
			"value": 350881000000
		}]
	}, {
		"label": "宁德市",
		"value": 350900000000,
		"children": [{
			"label": "蕉城区",
			"value": 350902000000
		}, {
			"label": "霞浦县",
			"value": 350921000000
		}, {
			"label": "古田县",
			"value": 350922000000
		}, {
			"label": "屏南县",
			"value": 350923000000
		}, {
			"label": "寿宁县",
			"value": 350924000000
		}, {
			"label": "周宁县",
			"value": 350925000000
		}, {
			"label": "柘荣县",
			"value": 350926000000
		}, {
			"label": "福安市",
			"value": 350981000000
		}, {
			"label": "福鼎市",
			"value": 350982000000
		}]
	}]
}, {
	"label": "江西省",
	"value": 36,
	"children": [{
		"label": "南昌市",
		"value": 360100000000,
		"children": [{
			"label": "东湖区",
			"value": 360102000000
		}, {
			"label": "西湖区",
			"value": 360103000000
		}, {
			"label": "青云谱区",
			"value": 360104000000
		}, {
			"label": "湾里区",
			"value": 360105000000
		}, {
			"label": "青山湖区",
			"value": 360111000000
		}, {
			"label": "新建区",
			"value": 360112000000
		}, {
			"label": "南昌县",
			"value": 360121000000
		}, {
			"label": "安义县",
			"value": 360123000000
		}, {
			"label": "进贤县",
			"value": 360124000000
		}]
	}, {
		"label": "景德镇市",
		"value": 360200000000,
		"children": [{
			"label": "昌江区",
			"value": 360202000000
		}, {
			"label": "珠山区",
			"value": 360203000000
		}, {
			"label": "浮梁县",
			"value": 360222000000
		}, {
			"label": "乐平市",
			"value": 360281000000
		}]
	}, {
		"label": "萍乡市",
		"value": 360300000000,
		"children": [{
			"label": "安源区",
			"value": 360302000000
		}, {
			"label": "湘东区",
			"value": 360313000000
		}, {
			"label": "莲花县",
			"value": 360321000000
		}, {
			"label": "上栗县",
			"value": 360322000000
		}, {
			"label": "芦溪县",
			"value": 360323000000
		}]
	}, {
		"label": "九江市",
		"value": 360400000000,
		"children": [{
			"label": "庐山区",
			"value": 360402000000
		}, {
			"label": "浔阳区",
			"value": 360403000000
		}, {
			"label": "九江县",
			"value": 360421000000
		}, {
			"label": "武宁县",
			"value": 360423000000
		}, {
			"label": "修水县",
			"value": 360424000000
		}, {
			"label": "永修县",
			"value": 360425000000
		}, {
			"label": "德安县",
			"value": 360426000000
		}, {
			"label": "星子县",
			"value": 360427000000
		}, {
			"label": "都昌县",
			"value": 360428000000
		}, {
			"label": "湖口县",
			"value": 360429000000
		}, {
			"label": "彭泽县",
			"value": 360430000000
		}, {
			"label": "瑞昌市",
			"value": 360481000000
		}, {
			"label": "共青城市",
			"value": 360482000000
		}]
	}, {
		"label": "新余市",
		"value": 360500000000,
		"children": [{
			"label": "渝水区",
			"value": 360502000000
		}, {
			"label": "分宜县",
			"value": 360521000000
		}]
	}, {
		"label": "鹰潭市",
		"value": 360600000000,
		"children": [{
			"label": "月湖区",
			"value": 360602000000
		}, {
			"label": "余江县",
			"value": 360622000000
		}, {
			"label": "贵溪市",
			"value": 360681000000
		}]
	}, {
		"label": "赣州市",
		"value": 360700000000,
		"children": [{
			"label": "章贡区",
			"value": 360702000000
		}, {
			"label": "南康区",
			"value": 360703000000
		}, {
			"label": "赣县",
			"value": 360721000000
		}, {
			"label": "信丰县",
			"value": 360722000000
		}, {
			"label": "大余县",
			"value": 360723000000
		}, {
			"label": "上犹县",
			"value": 360724000000
		}, {
			"label": "崇义县",
			"value": 360725000000
		}, {
			"label": "安远县",
			"value": 360726000000
		}, {
			"label": "龙南县",
			"value": 360727000000
		}, {
			"label": "定南县",
			"value": 360728000000
		}, {
			"label": "全南县",
			"value": 360729000000
		}, {
			"label": "宁都县",
			"value": 360730000000
		}, {
			"label": "于都县",
			"value": 360731000000
		}, {
			"label": "兴国县",
			"value": 360732000000
		}, {
			"label": "会昌县",
			"value": 360733000000
		}, {
			"label": "寻乌县",
			"value": 360734000000
		}, {
			"label": "石城县",
			"value": 360735000000
		}, {
			"label": "瑞金市",
			"value": 360781000000
		}]
	}, {
		"label": "吉安市",
		"value": 360800000000,
		"children": [{
			"label": "吉州区",
			"value": 360802000000
		}, {
			"label": "青原区",
			"value": 360803000000
		}, {
			"label": "吉安县",
			"value": 360821000000
		}, {
			"label": "吉水县",
			"value": 360822000000
		}, {
			"label": "峡江县",
			"value": 360823000000
		}, {
			"label": "新干县",
			"value": 360824000000
		}, {
			"label": "永丰县",
			"value": 360825000000
		}, {
			"label": "泰和县",
			"value": 360826000000
		}, {
			"label": "遂川县",
			"value": 360827000000
		}, {
			"label": "万安县",
			"value": 360828000000
		}, {
			"label": "安福县",
			"value": 360829000000
		}, {
			"label": "永新县",
			"value": 360830000000
		}, {
			"label": "井冈山市",
			"value": 360881000000
		}]
	}, {
		"label": "宜春市",
		"value": 360900000000,
		"children": [{
			"label": "袁州区",
			"value": 360902000000
		}, {
			"label": "奉新县",
			"value": 360921000000
		}, {
			"label": "万载县",
			"value": 360922000000
		}, {
			"label": "上高县",
			"value": 360923000000
		}, {
			"label": "宜丰县",
			"value": 360924000000
		}, {
			"label": "靖安县",
			"value": 360925000000
		}, {
			"label": "铜鼓县",
			"value": 360926000000
		}, {
			"label": "丰城市",
			"value": 360981000000
		}, {
			"label": "樟树市",
			"value": 360982000000
		}, {
			"label": "高安市",
			"value": 360983000000
		}]
	}, {
		"label": "抚州市",
		"value": 361000000000,
		"children": [{
			"label": "临川区",
			"value": 361002000000
		}, {
			"label": "南城县",
			"value": 361021000000
		}, {
			"label": "黎川县",
			"value": 361022000000
		}, {
			"label": "南丰县",
			"value": 361023000000
		}, {
			"label": "崇仁县",
			"value": 361024000000
		}, {
			"label": "乐安县",
			"value": 361025000000
		}, {
			"label": "宜黄县",
			"value": 361026000000
		}, {
			"label": "金溪县",
			"value": 361027000000
		}, {
			"label": "资溪县",
			"value": 361028000000
		}, {
			"label": "东乡县",
			"value": 361029000000
		}, {
			"label": "广昌县",
			"value": 361030000000
		}]
	}, {
		"label": "上饶市",
		"value": 361100000000,
		"children": [{
			"label": "信州区",
			"value": 361102000000
		}, {
			"label": "广丰区",
			"value": 361103000000
		}, {
			"label": "上饶县",
			"value": 361121000000
		}, {
			"label": "玉山县",
			"value": 361123000000
		}, {
			"label": "铅山县",
			"value": 361124000000
		}, {
			"label": "横峰县",
			"value": 361125000000
		}, {
			"label": "弋阳县",
			"value": 361126000000
		}, {
			"label": "余干县",
			"value": 361127000000
		}, {
			"label": "鄱阳县",
			"value": 361128000000
		}, {
			"label": "万年县",
			"value": 361129000000
		}, {
			"label": "婺源县",
			"value": 361130000000
		}, {
			"label": "德兴市",
			"value": 361181000000
		}]
	}]
}, {
	"label": "山东省",
	"value": 37,
	"children": [{
		"label": "济南市",
		"value": 370100000000,
		"children": [{
			"label": "历下区",
			"value": 370102000000
		}, {
			"label": "市中区",
			"value": 370103000000
		}, {
			"label": "槐荫区",
			"value": 370104000000
		}, {
			"label": "天桥区",
			"value": 370105000000
		}, {
			"label": "历城区",
			"value": 370112000000
		}, {
			"label": "长清区",
			"value": 370113000000
		}, {
			"label": "平阴县",
			"value": 370124000000
		}, {
			"label": "济阳县",
			"value": 370125000000
		}, {
			"label": "商河县",
			"value": 370126000000
		}, {
			"label": "章丘市",
			"value": 370181000000
		}]
	}, {
		"label": "青岛市",
		"value": 370200000000,
		"children": [{
			"label": "市南区",
			"value": 370202000000
		}, {
			"label": "市北区",
			"value": 370203000000
		}, {
			"label": "黄岛区",
			"value": 370211000000
		}, {
			"label": "崂山区",
			"value": 370212000000
		}, {
			"label": "李沧区",
			"value": 370213000000
		}, {
			"label": "城阳区",
			"value": 370214000000
		}, {
			"label": "胶州市",
			"value": 370281000000
		}, {
			"label": "即墨市",
			"value": 370282000000
		}, {
			"label": "平度市",
			"value": 370283000000
		}, {
			"label": "莱西市",
			"value": 370285000000
		}]
	}, {
		"label": "淄博市",
		"value": 370300000000,
		"children": [{
			"label": "淄川区",
			"value": 370302000000
		}, {
			"label": "张店区",
			"value": 370303000000
		}, {
			"label": "博山区",
			"value": 370304000000
		}, {
			"label": "临淄区",
			"value": 370305000000
		}, {
			"label": "周村区",
			"value": 370306000000
		}, {
			"label": "桓台县",
			"value": 370321000000
		}, {
			"label": "高青县",
			"value": 370322000000
		}, {
			"label": "沂源县",
			"value": 370323000000
		}]
	}, {
		"label": "枣庄市",
		"value": 370400000000,
		"children": [{
			"label": "市中区",
			"value": 370402000000
		}, {
			"label": "薛城区",
			"value": 370403000000
		}, {
			"label": "峄城区",
			"value": 370404000000
		}, {
			"label": "台儿庄区",
			"value": 370405000000
		}, {
			"label": "山亭区",
			"value": 370406000000
		}, {
			"label": "滕州市",
			"value": 370481000000
		}]
	}, {
		"label": "东营市",
		"value": 370500000000,
		"children": [{
			"label": "东营区",
			"value": 370502000000
		}, {
			"label": "河口区",
			"value": 370503000000
		}, {
			"label": "垦利县",
			"value": 370521000000
		}, {
			"label": "利津县",
			"value": 370522000000
		}, {
			"label": "广饶县",
			"value": 370523000000
		}]
	}, {
		"label": "烟台市",
		"value": 370600000000,
		"children": [{
			"label": "芝罘区",
			"value": 370602000000
		}, {
			"label": "福山区",
			"value": 370611000000
		}, {
			"label": "牟平区",
			"value": 370612000000
		}, {
			"label": "莱山区",
			"value": 370613000000
		}, {
			"label": "长岛县",
			"value": 370634000000
		}, {
			"label": "龙口市",
			"value": 370681000000
		}, {
			"label": "莱阳市",
			"value": 370682000000
		}, {
			"label": "莱州市",
			"value": 370683000000
		}, {
			"label": "蓬莱市",
			"value": 370684000000
		}, {
			"label": "招远市",
			"value": 370685000000
		}, {
			"label": "栖霞市",
			"value": 370686000000
		}, {
			"label": "海阳市",
			"value": 370687000000
		}]
	}, {
		"label": "潍坊市",
		"value": 370700000000,
		"children": [{
			"label": "潍城区",
			"value": 370702000000
		}, {
			"label": "寒亭区",
			"value": 370703000000
		}, {
			"label": "坊子区",
			"value": 370704000000
		}, {
			"label": "奎文区",
			"value": 370705000000
		}, {
			"label": "临朐县",
			"value": 370724000000
		}, {
			"label": "昌乐县",
			"value": 370725000000
		}, {
			"label": "青州市",
			"value": 370781000000
		}, {
			"label": "诸城市",
			"value": 370782000000
		}, {
			"label": "寿光市",
			"value": 370783000000
		}, {
			"label": "安丘市",
			"value": 370784000000
		}, {
			"label": "高密市",
			"value": 370785000000
		}, {
			"label": "昌邑市",
			"value": 370786000000
		}]
	}, {
		"label": "济宁市",
		"value": 370800000000,
		"children": [{
			"label": "任城区",
			"value": 370811000000
		}, {
			"label": "兖州区",
			"value": 370812000000
		}, {
			"label": "微山县",
			"value": 370826000000
		}, {
			"label": "鱼台县",
			"value": 370827000000
		}, {
			"label": "金乡县",
			"value": 370828000000
		}, {
			"label": "嘉祥县",
			"value": 370829000000
		}, {
			"label": "汶上县",
			"value": 370830000000
		}, {
			"label": "泗水县",
			"value": 370831000000
		}, {
			"label": "梁山县",
			"value": 370832000000
		}, {
			"label": "曲阜市",
			"value": 370881000000
		}, {
			"label": "邹城市",
			"value": 370883000000
		}]
	}, {
		"label": "泰安市",
		"value": 370900000000,
		"children": [{
			"label": "泰山区",
			"value": 370902000000
		}, {
			"label": "岱岳区",
			"value": 370911000000
		}, {
			"label": "宁阳县",
			"value": 370921000000
		}, {
			"label": "东平县",
			"value": 370923000000
		}, {
			"label": "新泰市",
			"value": 370982000000
		}, {
			"label": "肥城市",
			"value": 370983000000
		}]
	}, {
		"label": "威海市",
		"value": 371000000000,
		"children": [{
			"label": "环翠区",
			"value": 371002000000
		}, {
			"label": "文登区",
			"value": 371003000000
		}, {
			"label": "荣成市",
			"value": 371082000000
		}, {
			"label": "乳山市",
			"value": 371083000000
		}]
	}, {
		"label": "日照市",
		"value": 371100000000,
		"children": [{
			"label": "东港区",
			"value": 371102000000
		}, {
			"label": "岚山区",
			"value": 371103000000
		}, {
			"label": "五莲县",
			"value": 371121000000
		}, {
			"label": "莒县",
			"value": 371122000000
		}]
	}, {
		"label": "莱芜市",
		"value": 371200000000,
		"children": [{
			"label": "莱城区",
			"value": 371202000000
		}, {
			"label": "钢城区",
			"value": 371203000000
		}]
	}, {
		"label": "临沂市",
		"value": 371300000000,
		"children": [{
			"label": "兰山区",
			"value": 371302000000
		}, {
			"label": "罗庄区",
			"value": 371311000000
		}, {
			"label": "河东区",
			"value": 371312000000
		}, {
			"label": "沂南县",
			"value": 371321000000
		}, {
			"label": "郯城县",
			"value": 371322000000
		}, {
			"label": "沂水县",
			"value": 371323000000
		}, {
			"label": "兰陵县",
			"value": 371324000000
		}, {
			"label": "费县",
			"value": 371325000000
		}, {
			"label": "平邑县",
			"value": 371326000000
		}, {
			"label": "莒南县",
			"value": 371327000000
		}, {
			"label": "蒙阴县",
			"value": 371328000000
		}, {
			"label": "临沭县",
			"value": 371329000000
		}]
	}, {
		"label": "德州市",
		"value": 371400000000,
		"children": [{
			"label": "德城区",
			"value": 371402000000
		}, {
			"label": "陵城区",
			"value": 371403000000
		}, {
			"label": "宁津县",
			"value": 371422000000
		}, {
			"label": "庆云县",
			"value": 371423000000
		}, {
			"label": "临邑县",
			"value": 371424000000
		}, {
			"label": "齐河县",
			"value": 371425000000
		}, {
			"label": "平原县",
			"value": 371426000000
		}, {
			"label": "夏津县",
			"value": 371427000000
		}, {
			"label": "武城县",
			"value": 371428000000
		}, {
			"label": "乐陵市",
			"value": 371481000000
		}, {
			"label": "禹城市",
			"value": 371482000000
		}]
	}, {
		"label": "聊城市",
		"value": 371500000000,
		"children": [{
			"label": "东昌府区",
			"value": 371502000000
		}, {
			"label": "阳谷县",
			"value": 371521000000
		}, {
			"label": "莘县",
			"value": 371522000000
		}, {
			"label": "茌平县",
			"value": 371523000000
		}, {
			"label": "东阿县",
			"value": 371524000000
		}, {
			"label": "冠县",
			"value": 371525000000
		}, {
			"label": "高唐县",
			"value": 371526000000
		}, {
			"label": "临清市",
			"value": 371581000000
		}]
	}, {
		"label": "滨州市",
		"value": 371600000000,
		"children": [{
			"label": "滨城区",
			"value": 371602000000
		}, {
			"label": "沾化区",
			"value": 371603000000
		}, {
			"label": "惠民县",
			"value": 371621000000
		}, {
			"label": "阳信县",
			"value": 371622000000
		}, {
			"label": "无棣县",
			"value": 371623000000
		}, {
			"label": "博兴县",
			"value": 371625000000
		}, {
			"label": "邹平县",
			"value": 371626000000
		}]
	}, {
		"label": "菏泽市",
		"value": 371700000000,
		"children": [{
			"label": "牡丹区",
			"value": 371702000000
		}, {
			"label": "曹县",
			"value": 371721000000
		}, {
			"label": "单县",
			"value": 371722000000
		}, {
			"label": "成武县",
			"value": 371723000000
		}, {
			"label": "巨野县",
			"value": 371724000000
		}, {
			"label": "郓城县",
			"value": 371725000000
		}, {
			"label": "鄄城县",
			"value": 371726000000
		}, {
			"label": "定陶县",
			"value": 371727000000
		}, {
			"label": "东明县",
			"value": 371728000000
		}]
	}]
},{
	"label": "湖北省",
	"value": 42,
	"children": [{
		"label": "武汉市",
		"value": 420100000000,
		"children": [{
			"label": "江岸区",
			"value": 420102000000
		}, {
			"label": "江汉区",
			"value": 420103000000
		}, {
			"label": "硚口区",
			"value": 420104000000
		}, {
			"label": "汉阳区",
			"value": 420105000000
		}, {
			"label": "武昌区",
			"value": 420106000000
		}, {
			"label": "青山区",
			"value": 420107000000
		}, {
			"label": "洪山区",
			"value": 420111000000
		}, {
			"label": "东西湖区",
			"value": 420112000000
		}, {
			"label": "汉南区",
			"value": 420113000000
		}, {
			"label": "蔡甸区",
			"value": 420114000000
		}, {
			"label": "江夏区",
			"value": 420115000000
		}, {
			"label": "黄陂区",
			"value": 420116000000
		}, {
			"label": "新洲区",
			"value": 420117000000
		}]
	}, {
		"label": "黄石市",
		"value": 420200000000,
		"children": [{
			"label": "黄石港区",
			"value": 420202000000
		}, {
			"label": "西塞山区",
			"value": 420203000000
		}, {
			"label": "下陆区",
			"value": 420204000000
		}, {
			"label": "铁山区",
			"value": 420205000000
		}, {
			"label": "阳新县",
			"value": 420222000000
		}, {
			"label": "大冶市",
			"value": 420281000000
		}]
	}, {
		"label": "十堰市",
		"value": 420300000000,
		"children": [{
			"label": "茅箭区",
			"value": 420302000000
		}, {
			"label": "张湾区",
			"value": 420303000000
		}, {
			"label": "郧阳区",
			"value": 420304000000
		}, {
			"label": "郧西县",
			"value": 420322000000
		}, {
			"label": "竹山县",
			"value": 420323000000
		}, {
			"label": "竹溪县",
			"value": 420324000000
		}, {
			"label": "房县",
			"value": 420325000000
		}, {
			"label": "丹江口市",
			"value": 420381000000
		}]
	}, {
		"label": "宜昌市",
		"value": 420500000000,
		"children": [{
			"label": "西陵区",
			"value": 420502000000
		}, {
			"label": "伍家岗区",
			"value": 420503000000
		}, {
			"label": "点军区",
			"value": 420504000000
		}, {
			"label": "猇亭区",
			"value": 420505000000
		}, {
			"label": "夷陵区",
			"value": 420506000000
		}, {
			"label": "远安县",
			"value": 420525000000
		}, {
			"label": "兴山县",
			"value": 420526000000
		}, {
			"label": "秭归县",
			"value": 420527000000
		}, {
			"label": "长阳土家族自治县",
			"value": 420528000000
		}, {
			"label": "五峰土家族自治县",
			"value": 420529000000
		}, {
			"label": "宜都市",
			"value": 420581000000
		}, {
			"label": "当阳市",
			"value": 420582000000
		}, {
			"label": "枝江市",
			"value": 420583000000
		}]
	}, {
		"label": "襄阳市",
		"value": 420600000000,
		"children": [{
			"label": "襄城区",
			"value": 420602000000
		}, {
			"label": "樊城区",
			"value": 420606000000
		}, {
			"label": "襄州区",
			"value": 420607000000
		}, {
			"label": "南漳县",
			"value": 420624000000
		}, {
			"label": "谷城县",
			"value": 420625000000
		}, {
			"label": "保康县",
			"value": 420626000000
		}, {
			"label": "老河口市",
			"value": 420682000000
		}, {
			"label": "枣阳市",
			"value": 420683000000
		}, {
			"label": "宜城市",
			"value": 420684000000
		}]
	}, {
		"label": "鄂州市",
		"value": 420700000000,
		"children": [{
			"label": "梁子湖区",
			"value": 420702000000
		}, {
			"label": "华容区",
			"value": 420703000000
		}, {
			"label": "鄂城区",
			"value": 420704000000
		}]
	}, {
		"label": "荆门市",
		"value": 420800000000,
		"children": [{
			"label": "东宝区",
			"value": 420802000000
		}, {
			"label": "掇刀区",
			"value": 420804000000
		}, {
			"label": "京山县",
			"value": 420821000000
		}, {
			"label": "沙洋县",
			"value": 420822000000
		}, {
			"label": "钟祥市",
			"value": 420881000000
		}]
	}, {
		"label": "孝感市",
		"value": 420900000000,
		"children": [{
			"label": "孝南区",
			"value": 420902000000
		}, {
			"label": "孝昌县",
			"value": 420921000000
		}, {
			"label": "大悟县",
			"value": 420922000000
		}, {
			"label": "云梦县",
			"value": 420923000000
		}, {
			"label": "应城市",
			"value": 420981000000
		}, {
			"label": "安陆市",
			"value": 420982000000
		}, {
			"label": "汉川市",
			"value": 420984000000
		}]
	}, {
		"label": "荆州市",
		"value": 421000000000,
		"children": [{
			"label": "沙市区",
			"value": 421002000000
		}, {
			"label": "荆州区",
			"value": 421003000000
		}, {
			"label": "公安县",
			"value": 421022000000
		}, {
			"label": "监利县",
			"value": 421023000000
		}, {
			"label": "江陵县",
			"value": 421024000000
		}, {
			"label": "石首市",
			"value": 421081000000
		}, {
			"label": "洪湖市",
			"value": 421083000000
		}, {
			"label": "松滋市",
			"value": 421087000000
		}]
	}, {
		"label": "黄冈市",
		"value": 421100000000,
		"children": [{
			"label": "黄州区",
			"value": 421102000000
		}, {
			"label": "团风县",
			"value": 421121000000
		}, {
			"label": "红安县",
			"value": 421122000000
		}, {
			"label": "罗田县",
			"value": 421123000000
		}, {
			"label": "英山县",
			"value": 421124000000
		}, {
			"label": "浠水县",
			"value": 421125000000
		}, {
			"label": "蕲春县",
			"value": 421126000000
		}, {
			"label": "黄梅县",
			"value": 421127000000
		}, {
			"label": "麻城市",
			"value": 421181000000
		}, {
			"label": "武穴市",
			"value": 421182000000
		}]
	}, {
		"label": "咸宁市",
		"value": 421200000000,
		"children": [{
			"label": "咸安区",
			"value": 421202000000
		}, {
			"label": "嘉鱼县",
			"value": 421221000000
		}, {
			"label": "通城县",
			"value": 421222000000
		}, {
			"label": "崇阳县",
			"value": 421223000000
		}, {
			"label": "通山县",
			"value": 421224000000
		}, {
			"label": "赤壁市",
			"value": 421281000000
		}]
	}, {
		"label": "随州市",
		"value": 421300000000,
		"children": [{
			"label": "曾都区",
			"value": 421303000000
		}, {
			"label": "随县",
			"value": 421321000000
		}, {
			"label": "广水市",
			"value": 421381000000
		}]
	}, {
		"label": "恩施土家族苗族自治州",
		"value": 422800000000,
		"children": [{
			"label": "恩施市",
			"value": 422801000000
		}, {
			"label": "利川市",
			"value": 422802000000
		}, {
			"label": "建始县",
			"value": 422822000000
		}, {
			"label": "巴东县",
			"value": 422823000000
		}, {
			"label": "宣恩县",
			"value": 422825000000
		}, {
			"label": "咸丰县",
			"value": 422826000000
		}, {
			"label": "来凤县",
			"value": 422827000000
		}, {
			"label": "鹤峰县",
			"value": 422828000000
		}]
	}, {
		"label": "省直辖县级行政区划",
		"value": 429000000000,
		"children": [{
			"label": "仙桃市",
			"value": 429004000000
		}, {
			"label": "潜江市",
			"value": 429005000000
		}, {
			"label": "天门市",
			"value": 429006000000
		}, {
			"label": "神农架林区",
			"value": 429021000000
		}]
	}]
}, {
	"label": "湖南省",
	"value": 43,
	"children": [{
		"label": "长沙市",
		"value": 430100000000,
		"children": [{
			"label": "芙蓉区",
			"value": 430102000000
		}, {
			"label": "天心区",
			"value": 430103000000
		}, {
			"label": "岳麓区",
			"value": 430104000000
		}, {
			"label": "开福区",
			"value": 430105000000
		}, {
			"label": "雨花区",
			"value": 430111000000
		}, {
			"label": "望城区",
			"value": 430112000000
		}, {
			"label": "长沙县",
			"value": 430121000000
		}, {
			"label": "宁乡县",
			"value": 430124000000
		}, {
			"label": "浏阳市",
			"value": 430181000000
		}]
	}, {
		"label": "株洲市",
		"value": 430200000000,
		"children": [{
			"label": "荷塘区",
			"value": 430202000000
		}, {
			"label": "芦淞区",
			"value": 430203000000
		}, {
			"label": "石峰区",
			"value": 430204000000
		}, {
			"label": "天元区",
			"value": 430211000000
		}, {
			"label": "株洲县",
			"value": 430221000000
		}, {
			"label": "攸县",
			"value": 430223000000
		}, {
			"label": "茶陵县",
			"value": 430224000000
		}, {
			"label": "炎陵县",
			"value": 430225000000
		}, {
			"label": "醴陵市",
			"value": 430281000000
		}]
	}, {
		"label": "湘潭市",
		"value": 430300000000,
		"children": [{
			"label": "雨湖区",
			"value": 430302000000
		}, {
			"label": "岳塘区",
			"value": 430304000000
		}, {
			"label": "湘潭县",
			"value": 430321000000
		}, {
			"label": "湘乡市",
			"value": 430381000000
		}, {
			"label": "韶山市",
			"value": 430382000000
		}]
	}, {
		"label": "衡阳市",
		"value": 430400000000,
		"children": [{
			"label": "珠晖区",
			"value": 430405000000
		}, {
			"label": "雁峰区",
			"value": 430406000000
		}, {
			"label": "石鼓区",
			"value": 430407000000
		}, {
			"label": "蒸湘区",
			"value": 430408000000
		}, {
			"label": "南岳区",
			"value": 430412000000
		}, {
			"label": "衡阳县",
			"value": 430421000000
		}, {
			"label": "衡南县",
			"value": 430422000000
		}, {
			"label": "衡山县",
			"value": 430423000000
		}, {
			"label": "衡东县",
			"value": 430424000000
		}, {
			"label": "祁东县",
			"value": 430426000000
		}, {
			"label": "耒阳市",
			"value": 430481000000
		}, {
			"label": "常宁市",
			"value": 430482000000
		}]
	}, {
		"label": "邵阳市",
		"value": 430500000000,
		"children": [{
			"label": "双清区",
			"value": 430502000000
		}, {
			"label": "大祥区",
			"value": 430503000000
		}, {
			"label": "北塔区",
			"value": 430511000000
		}, {
			"label": "邵东县",
			"value": 430521000000
		}, {
			"label": "新邵县",
			"value": 430522000000
		}, {
			"label": "邵阳县",
			"value": 430523000000
		}, {
			"label": "隆回县",
			"value": 430524000000
		}, {
			"label": "洞口县",
			"value": 430525000000
		}, {
			"label": "绥宁县",
			"value": 430527000000
		}, {
			"label": "新宁县",
			"value": 430528000000
		}, {
			"label": "城步苗族自治县",
			"value": 430529000000
		}, {
			"label": "武冈市",
			"value": 430581000000
		}]
	}, {
		"label": "岳阳市",
		"value": 430600000000,
		"children": [{
			"label": "岳阳楼区",
			"value": 430602000000
		}, {
			"label": "云溪区",
			"value": 430603000000
		}, {
			"label": "君山区",
			"value": 430611000000
		}, {
			"label": "岳阳县",
			"value": 430621000000
		}, {
			"label": "华容县",
			"value": 430623000000
		}, {
			"label": "湘阴县",
			"value": 430624000000
		}, {
			"label": "平江县",
			"value": 430626000000
		}, {
			"label": "汨罗市",
			"value": 430681000000
		}, {
			"label": "临湘市",
			"value": 430682000000
		}]
	}, {
		"label": "常德市",
		"value": 430700000000,
		"children": [{
			"label": "武陵区",
			"value": 430702000000
		}, {
			"label": "鼎城区",
			"value": 430703000000
		}, {
			"label": "安乡县",
			"value": 430721000000
		}, {
			"label": "汉寿县",
			"value": 430722000000
		}, {
			"label": "澧县",
			"value": 430723000000
		}, {
			"label": "临澧县",
			"value": 430724000000
		}, {
			"label": "桃源县",
			"value": 430725000000
		}, {
			"label": "石门县",
			"value": 430726000000
		}, {
			"label": "津市市",
			"value": 430781000000
		}]
	}, {
		"label": "张家界市",
		"value": 430800000000,
		"children": [{
			"label": "永定区",
			"value": 430802000000
		}, {
			"label": "武陵源区",
			"value": 430811000000
		}, {
			"label": "慈利县",
			"value": 430821000000
		}, {
			"label": "桑植县",
			"value": 430822000000
		}]
	}, {
		"label": "益阳市",
		"value": 430900000000,
		"children": [{
			"label": "资阳区",
			"value": 430902000000
		}, {
			"label": "赫山区",
			"value": 430903000000
		}, {
			"label": "南县",
			"value": 430921000000
		}, {
			"label": "桃江县",
			"value": 430922000000
		}, {
			"label": "安化县",
			"value": 430923000000
		}, {
			"label": "沅江市",
			"value": 430981000000
		}]
	}, {
		"label": "郴州市",
		"value": 431000000000,
		"children": [{
			"label": "北湖区",
			"value": 431002000000
		}, {
			"label": "苏仙区",
			"value": 431003000000
		}, {
			"label": "桂阳县",
			"value": 431021000000
		}, {
			"label": "宜章县",
			"value": 431022000000
		}, {
			"label": "永兴县",
			"value": 431023000000
		}, {
			"label": "嘉禾县",
			"value": 431024000000
		}, {
			"label": "临武县",
			"value": 431025000000
		}, {
			"label": "汝城县",
			"value": 431026000000
		}, {
			"label": "桂东县",
			"value": 431027000000
		}, {
			"label": "安仁县",
			"value": 431028000000
		}, {
			"label": "资兴市",
			"value": 431081000000
		}]
	}, {
		"label": "永州市",
		"value": 431100000000,
		"children": [{
			"label": "零陵区",
			"value": 431102000000
		}, {
			"label": "冷水滩区",
			"value": 431103000000
		}, {
			"label": "祁阳县",
			"value": 431121000000
		}, {
			"label": "东安县",
			"value": 431122000000
		}, {
			"label": "双牌县",
			"value": 431123000000
		}, {
			"label": "道县",
			"value": 431124000000
		}, {
			"label": "江永县",
			"value": 431125000000
		}, {
			"label": "宁远县",
			"value": 431126000000
		}, {
			"label": "蓝山县",
			"value": 431127000000
		}, {
			"label": "新田县",
			"value": 431128000000
		}, {
			"label": "江华瑶族自治县",
			"value": 431129000000
		}]
	}, {
		"label": "怀化市",
		"value": 431200000000,
		"children": [{
			"label": "鹤城区",
			"value": 431202000000
		}, {
			"label": "中方县",
			"value": 431221000000
		}, {
			"label": "沅陵县",
			"value": 431222000000
		}, {
			"label": "辰溪县",
			"value": 431223000000
		}, {
			"label": "溆浦县",
			"value": 431224000000
		}, {
			"label": "会同县",
			"value": 431225000000
		}, {
			"label": "麻阳苗族自治县",
			"value": 431226000000
		}, {
			"label": "新晃侗族自治县",
			"value": 431227000000
		}, {
			"label": "芷江侗族自治县",
			"value": 431228000000
		}, {
			"label": "靖州苗族侗族自治县",
			"value": 431229000000
		}, {
			"label": "通道侗族自治县",
			"value": 431230000000
		}, {
			"label": "洪江市",
			"value": 431281000000
		}]
	}, {
		"label": "娄底市",
		"value": 431300000000,
		"children": [{
			"label": "娄星区",
			"value": 431302000000
		}, {
			"label": "双峰县",
			"value": 431321000000
		}, {
			"label": "新化县",
			"value": 431322000000
		}, {
			"label": "冷水江市",
			"value": 431381000000
		}, {
			"label": "涟源市",
			"value": 431382000000
		}]
	}, {
		"label": "湘西土家族苗族自治州",
		"value": 433100000000,
		"children": [{
			"label": "吉首市",
			"value": 433101000000
		}, {
			"label": "泸溪县",
			"value": 433122000000
		}, {
			"label": "凤凰县",
			"value": 433123000000
		}, {
			"label": "花垣县",
			"value": 433124000000
		}, {
			"label": "保靖县",
			"value": 433125000000
		}, {
			"label": "古丈县",
			"value": 433126000000
		}, {
			"label": "永顺县",
			"value": 433127000000
		}, {
			"label": "龙山县",
			"value": 433130000000
		}]
	}]
}, {
	"label": "广东省",
	"value": 44,
	"children": [{
		"label": "广州市",
		"value": 440100000000,
		"children": [{
			"label": "荔湾区",
			"value": 440103000000
		}, {
			"label": "越秀区",
			"value": 440104000000
		}, {
			"label": "海珠区",
			"value": 440105000000
		}, {
			"label": "天河区",
			"value": 440106000000
		}, {
			"label": "白云区",
			"value": 440111000000
		}, {
			"label": "黄埔区",
			"value": 440112000000
		}, {
			"label": "番禺区",
			"value": 440113000000
		}, {
			"label": "花都区",
			"value": 440114000000
		}, {
			"label": "南沙区",
			"value": 440115000000
		}, {
			"label": "从化区",
			"value": 440117000000
		}, {
			"label": "增城区",
			"value": 440118000000
		}]
	}, {
		"label": "韶关市",
		"value": 440200000000,
		"children": [{
			"label": "武江区",
			"value": 440203000000
		}, {
			"label": "浈江区",
			"value": 440204000000
		}, {
			"label": "曲江区",
			"value": 440205000000
		}, {
			"label": "始兴县",
			"value": 440222000000
		}, {
			"label": "仁化县",
			"value": 440224000000
		}, {
			"label": "翁源县",
			"value": 440229000000
		}, {
			"label": "乳源瑶族自治县",
			"value": 440232000000
		}, {
			"label": "新丰县",
			"value": 440233000000
		}, {
			"label": "乐昌市",
			"value": 440281000000
		}, {
			"label": "南雄市",
			"value": 440282000000
		}]
	}, {
		"label": "深圳市",
		"value": 440300000000,
		"children": [{
			"label": "罗湖区",
			"value": 440303000000
		}, {
			"label": "福田区",
			"value": 440304000000
		}, {
			"label": "南山区",
			"value": 440305000000
		}, {
			"label": "宝安区",
			"value": 440306000000
		}, {
			"label": "龙岗区",
			"value": 440307000000
		}, {
			"label": "盐田区",
			"value": 440308000000
		}]
	}, {
		"label": "珠海市",
		"value": 440400000000,
		"children": [{
			"label": "香洲区",
			"value": 440402000000
		}, {
			"label": "斗门区",
			"value": 440403000000
		}, {
			"label": "金湾区",
			"value": 440404000000
		}]
	}, {
		"label": "汕头市",
		"value": 440500000000,
		"children": [{
			"label": "龙湖区",
			"value": 440507000000
		}, {
			"label": "金平区",
			"value": 440511000000
		}, {
			"label": "濠江区",
			"value": 440512000000
		}, {
			"label": "潮阳区",
			"value": 440513000000
		}, {
			"label": "潮南区",
			"value": 440514000000
		}, {
			"label": "澄海区",
			"value": 440515000000
		}, {
			"label": "南澳县",
			"value": 440523000000
		}]
	}, {
		"label": "佛山市",
		"value": 440600000000,
		"children": [{
			"label": "禅城区",
			"value": 440604000000
		}, {
			"label": "南海区",
			"value": 440605000000
		}, {
			"label": "顺德区",
			"value": 440606000000
		}, {
			"label": "三水区",
			"value": 440607000000
		}, {
			"label": "高明区",
			"value": 440608000000
		}]
	}, {
		"label": "江门市",
		"value": 440700000000,
		"children": [{
			"label": "蓬江区",
			"value": 440703000000
		}, {
			"label": "江海区",
			"value": 440704000000
		}, {
			"label": "新会区",
			"value": 440705000000
		}, {
			"label": "台山市",
			"value": 440781000000
		}, {
			"label": "开平市",
			"value": 440783000000
		}, {
			"label": "鹤山市",
			"value": 440784000000
		}, {
			"label": "恩平市",
			"value": 440785000000
		}]
	}, {
		"label": "湛江市",
		"value": 440800000000,
		"children": [{
			"label": "赤坎区",
			"value": 440802000000
		}, {
			"label": "霞山区",
			"value": 440803000000
		}, {
			"label": "坡头区",
			"value": 440804000000
		}, {
			"label": "麻章区",
			"value": 440811000000
		}, {
			"label": "遂溪县",
			"value": 440823000000
		}, {
			"label": "徐闻县",
			"value": 440825000000
		}, {
			"label": "廉江市",
			"value": 440881000000
		}, {
			"label": "雷州市",
			"value": 440882000000
		}, {
			"label": "吴川市",
			"value": 440883000000
		}]
	}, {
		"label": "茂名市",
		"value": 440900000000,
		"children": [{
			"label": "茂南区",
			"value": 440902000000
		}, {
			"label": "电白区",
			"value": 440904000000
		}, {
			"label": "高州市",
			"value": 440981000000
		}, {
			"label": "化州市",
			"value": 440982000000
		}, {
			"label": "信宜市",
			"value": 440983000000
		}]
	}, {
		"label": "肇庆市",
		"value": 441200000000,
		"children": [{
			"label": "端州区",
			"value": 441202000000
		}, {
			"label": "鼎湖区",
			"value": 441203000000
		}, {
			"label": "高要区",
			"value": 441204000000
		}, {
			"label": "广宁县",
			"value": 441223000000
		}, {
			"label": "怀集县",
			"value": 441224000000
		}, {
			"label": "封开县",
			"value": 441225000000
		}, {
			"label": "德庆县",
			"value": 441226000000
		}, {
			"label": "四会市",
			"value": 441284000000
		}]
	}, {
		"label": "惠州市",
		"value": 441300000000,
		"children": [{
			"label": "惠城区",
			"value": 441302000000
		}, {
			"label": "惠阳区",
			"value": 441303000000
		}, {
			"label": "博罗县",
			"value": 441322000000
		}, {
			"label": "惠东县",
			"value": 441323000000
		}, {
			"label": "龙门县",
			"value": 441324000000
		}]
	}, {
		"label": "梅州市",
		"value": 441400000000,
		"children": [{
			"label": "梅江区",
			"value": 441402000000
		}, {
			"label": "梅县区",
			"value": 441403000000
		}, {
			"label": "大埔县",
			"value": 441422000000
		}, {
			"label": "丰顺县",
			"value": 441423000000
		}, {
			"label": "五华县",
			"value": 441424000000
		}, {
			"label": "平远县",
			"value": 441426000000
		}, {
			"label": "蕉岭县",
			"value": 441427000000
		}, {
			"label": "兴宁市",
			"value": 441481000000
		}]
	}, {
		"label": "汕尾市",
		"value": 441500000000,
		"children": [{
			"label": "城区",
			"value": 441502000000
		}, {
			"label": "海丰县",
			"value": 441521000000
		}, {
			"label": "陆河县",
			"value": 441523000000
		}, {
			"label": "陆丰市",
			"value": 441581000000
		}]
	}, {
		"label": "河源市",
		"value": 441600000000,
		"children": [{
			"label": "源城区",
			"value": 441602000000
		}, {
			"label": "紫金县",
			"value": 441621000000
		}, {
			"label": "龙川县",
			"value": 441622000000
		}, {
			"label": "连平县",
			"value": 441623000000
		}, {
			"label": "和平县",
			"value": 441624000000
		}, {
			"label": "东源县",
			"value": 441625000000
		}]
	}, {
		"label": "阳江市",
		"value": 441700000000,
		"children": [{
			"label": "江城区",
			"value": 441702000000
		}, {
			"label": "阳东区",
			"value": 441704000000
		}, {
			"label": "阳西县",
			"value": 441721000000
		}, {
			"label": "阳春市",
			"value": 441781000000
		}]
	}, {
		"label": "清远市",
		"value": 441800000000,
		"children": [{
			"label": "清城区",
			"value": 441802000000
		}, {
			"label": "清新区",
			"value": 441803000000
		}, {
			"label": "佛冈县",
			"value": 441821000000
		}, {
			"label": "阳山县",
			"value": 441823000000
		}, {
			"label": "连山壮族瑶族自治县",
			"value": 441825000000
		}, {
			"label": "连南瑶族自治县",
			"value": 441826000000
		}, {
			"label": "英德市",
			"value": 441881000000
		}, {
			"label": "连州市",
			"value": 441882000000
		}]
	}, {
		"label": "东莞市",
		"value": 441900000000,
		"children": [{
			"label": "东莞市无县级区划",
			"value": 0
		}]
	}, {
		"label": "中山市",
		"value": 442000000000,
		"children": [{
			"label": "中山市无县级区划",
			"value": 1
		}]
	}, {
		"label": "潮州市",
		"value": 445100000000,
		"children": [{
			"label": "湘桥区",
			"value": 445102000000
		}, {
			"label": "潮安区",
			"value": 445103000000
		}, {
			"label": "饶平县",
			"value": 445122000000
		}]
	}, {
		"label": "揭阳市",
		"value": 445200000000,
		"children": [{
			"label": "榕城区",
			"value": 445202000000
		}, {
			"label": "揭东区",
			"value": 445203000000
		}, {
			"label": "揭西县",
			"value": 445222000000
		}, {
			"label": "惠来县",
			"value": 445224000000
		}, {
			"label": "普宁市",
			"value": 445281000000
		}]
	}, {
		"label": "云浮市",
		"value": 445300000000,
		"children": [{
			"label": "云城区",
			"value": 445302000000
		}, {
			"label": "云安区",
			"value": 445303000000
		}, {
			"label": "新兴县",
			"value": 445321000000
		}, {
			"label": "郁南县",
			"value": 445322000000
		}, {
			"label": "罗定市",
			"value": 445381000000
		}]
	}]
}, {
	"label": "广西壮族自治区",
	"value": 45,
	"children": [{
		"label": "南宁市",
		"value": 450100000000,
		"children": [{
			"label": "兴宁区",
			"value": 450102000000
		}, {
			"label": "青秀区",
			"value": 450103000000
		}, {
			"label": "江南区",
			"value": 450105000000
		}, {
			"label": "西乡塘区",
			"value": 450107000000
		}, {
			"label": "良庆区",
			"value": 450108000000
		}, {
			"label": "邕宁区",
			"value": 450109000000
		}, {
			"label": "武鸣区",
			"value": 450110000000
		}, {
			"label": "隆安县",
			"value": 450123000000
		}, {
			"label": "马山县",
			"value": 450124000000
		}, {
			"label": "上林县",
			"value": 450125000000
		}, {
			"label": "宾阳县",
			"value": 450126000000
		}, {
			"label": "横县",
			"value": 450127000000
		}]
	}, {
		"label": "柳州市",
		"value": 450200000000,
		"children": [{
			"label": "城中区",
			"value": 450202000000
		}, {
			"label": "鱼峰区",
			"value": 450203000000
		}, {
			"label": "柳南区",
			"value": 450204000000
		}, {
			"label": "柳北区",
			"value": 450205000000
		}, {
			"label": "柳江县",
			"value": 450221000000
		}, {
			"label": "柳城县",
			"value": 450222000000
		}, {
			"label": "鹿寨县",
			"value": 450223000000
		}, {
			"label": "融安县",
			"value": 450224000000
		}, {
			"label": "融水苗族自治县",
			"value": 450225000000
		}, {
			"label": "三江侗族自治县",
			"value": 450226000000
		}]
	}, {
		"label": "桂林市",
		"value": 450300000000,
		"children": [{
			"label": "秀峰区",
			"value": 450302000000
		}, {
			"label": "叠彩区",
			"value": 450303000000
		}, {
			"label": "象山区",
			"value": 450304000000
		}, {
			"label": "七星区",
			"value": 450305000000
		}, {
			"label": "雁山区",
			"value": 450311000000
		}, {
			"label": "临桂区",
			"value": 450312000000
		}, {
			"label": "阳朔县",
			"value": 450321000000
		}, {
			"label": "灵川县",
			"value": 450323000000
		}, {
			"label": "全州县",
			"value": 450324000000
		}, {
			"label": "兴安县",
			"value": 450325000000
		}, {
			"label": "永福县",
			"value": 450326000000
		}, {
			"label": "灌阳县",
			"value": 450327000000
		}, {
			"label": "龙胜各族自治县",
			"value": 450328000000
		}, {
			"label": "资源县",
			"value": 450329000000
		}, {
			"label": "平乐县",
			"value": 450330000000
		}, {
			"label": "荔浦县",
			"value": 450331000000
		}, {
			"label": "恭城瑶族自治县",
			"value": 450332000000
		}]
	}, {
		"label": "梧州市",
		"value": 450400000000,
		"children": [{
			"label": "万秀区",
			"value": 450403000000
		}, {
			"label": "长洲区",
			"value": 450405000000
		}, {
			"label": "龙圩区",
			"value": 450406000000
		}, {
			"label": "苍梧县",
			"value": 450421000000
		}, {
			"label": "藤县",
			"value": 450422000000
		}, {
			"label": "蒙山县",
			"value": 450423000000
		}, {
			"label": "岑溪市",
			"value": 450481000000
		}]
	}, {
		"label": "北海市",
		"value": 450500000000,
		"children": [{
			"label": "海城区",
			"value": 450502000000
		}, {
			"label": "银海区",
			"value": 450503000000
		}, {
			"label": "铁山港区",
			"value": 450512000000
		}, {
			"label": "合浦县",
			"value": 450521000000
		}]
	}, {
		"label": "防城港市",
		"value": 450600000000,
		"children": [{
			"label": "港口区",
			"value": 450602000000
		}, {
			"label": "防城区",
			"value": 450603000000
		}, {
			"label": "上思县",
			"value": 450621000000
		}, {
			"label": "东兴市",
			"value": 450681000000
		}]
	}, {
		"label": "钦州市",
		"value": 450700000000,
		"children": [{
			"label": "钦南区",
			"value": 450702000000
		}, {
			"label": "钦北区",
			"value": 450703000000
		}, {
			"label": "灵山县",
			"value": 450721000000
		}, {
			"label": "浦北县",
			"value": 450722000000
		}]
	}, {
		"label": "贵港市",
		"value": 450800000000,
		"children": [{
			"label": "港北区",
			"value": 450802000000
		}, {
			"label": "港南区",
			"value": 450803000000
		}, {
			"label": "覃塘区",
			"value": 450804000000
		}, {
			"label": "平南县",
			"value": 450821000000
		}, {
			"label": "桂平市",
			"value": 450881000000
		}]
	}, {
		"label": "玉林市",
		"value": 450900000000,
		"children": [{
			"label": "玉州区",
			"value": 450902000000
		}, {
			"label": "福绵区",
			"value": 450903000000
		}, {
			"label": "容县",
			"value": 450921000000
		}, {
			"label": "陆川县",
			"value": 450922000000
		}, {
			"label": "博白县",
			"value": 450923000000
		}, {
			"label": "兴业县",
			"value": 450924000000
		}, {
			"label": "北流市",
			"value": 450981000000
		}]
	}, {
		"label": "百色市",
		"value": 451000000000,
		"children": [{
			"label": "右江区",
			"value": 451002000000
		}, {
			"label": "田阳县",
			"value": 451021000000
		}, {
			"label": "田东县",
			"value": 451022000000
		}, {
			"label": "平果县",
			"value": 451023000000
		}, {
			"label": "德保县",
			"value": 451024000000
		}, {
			"label": "那坡县",
			"value": 451026000000
		}, {
			"label": "凌云县",
			"value": 451027000000
		}, {
			"label": "乐业县",
			"value": 451028000000
		}, {
			"label": "田林县",
			"value": 451029000000
		}, {
			"label": "西林县",
			"value": 451030000000
		}, {
			"label": "隆林各族自治县",
			"value": 451031000000
		}, {
			"label": "靖西市",
			"value": 451081000000
		}]
	}, {
		"label": "贺州市",
		"value": 451100000000,
		"children": [{
			"label": "八步区",
			"value": 451102000000
		}, {
			"label": "昭平县",
			"value": 451121000000
		}, {
			"label": "钟山县",
			"value": 451122000000
		}, {
			"label": "富川瑶族自治县",
			"value": 451123000000
		}]
	}, {
		"label": "河池市",
		"value": 451200000000,
		"children": [{
			"label": "金城江区",
			"value": 451202000000
		}, {
			"label": "南丹县",
			"value": 451221000000
		}, {
			"label": "天峨县",
			"value": 451222000000
		}, {
			"label": "凤山县",
			"value": 451223000000
		}, {
			"label": "东兰县",
			"value": 451224000000
		}, {
			"label": "罗城仫佬族自治县",
			"value": 451225000000
		}, {
			"label": "环江毛南族自治县",
			"value": 451226000000
		}, {
			"label": "巴马瑶族自治县",
			"value": 451227000000
		}, {
			"label": "都安瑶族自治县",
			"value": 451228000000
		}, {
			"label": "大化瑶族自治县",
			"value": 451229000000
		}, {
			"label": "宜州市",
			"value": 451281000000
		}]
	}, {
		"label": "来宾市",
		"value": 451300000000,
		"children": [{
			"label": "兴宾区",
			"value": 451302000000
		}, {
			"label": "忻城县",
			"value": 451321000000
		}, {
			"label": "象州县",
			"value": 451322000000
		}, {
			"label": "武宣县",
			"value": 451323000000
		}, {
			"label": "金秀瑶族自治县",
			"value": 451324000000
		}, {
			"label": "合山市",
			"value": 451381000000
		}]
	}, {
		"label": "崇左市",
		"value": 451400000000,
		"children": [{
			"label": "江州区",
			"value": 451402000000
		}, {
			"label": "扶绥县",
			"value": 451421000000
		}, {
			"label": "宁明县",
			"value": 451422000000
		}, {
			"label": "龙州县",
			"value": 451423000000
		}, {
			"label": "大新县",
			"value": 451424000000
		}, {
			"label": "天等县",
			"value": 451425000000
		}, {
			"label": "凭祥市",
			"value": 451481000000
		}]
	}]
}, {
	"label": "海南省",
	"value": 46,
	"children": [{
		"label": "海口市",
		"value": 460100000000,
		"children": [{
			"label": "秀英区",
			"value": 460105000000
		}, {
			"label": "龙华区",
			"value": 460106000000
		}, {
			"label": "琼山区",
			"value": 460107000000
		}, {
			"label": "美兰区",
			"value": 460108000000
		}]
	}, {
		"label": "三亚市",
		"value": 460200000000,
		"children": [{
			"label": "海棠区",
			"value": 460202000000
		}, {
			"label": "吉阳区",
			"value": 460203000000
		}, {
			"label": "天涯区",
			"value": 460204000000
		}, {
			"label": "崖州区",
			"value": 460205000000
		}]
	}, {
		"label": "省直辖县级行政区划",
		"value": 469000000000,
		"children": [{
			"label": "五指山市",
			"value": 469001000000
		}, {
			"label": "琼海市",
			"value": 469002000000
		}, {
			"label": "儋州市",
			"value": 469003000000
		}, {
			"label": "文昌市",
			"value": 469005000000
		}, {
			"label": "万宁市",
			"value": 469006000000
		}, {
			"label": "东方市",
			"value": 469007000000
		}, {
			"label": "定安县",
			"value": 469021000000
		}, {
			"label": "屯昌县",
			"value": 469022000000
		}, {
			"label": "澄迈县",
			"value": 469023000000
		}, {
			"label": "临高县",
			"value": 469024000000
		}, {
			"label": "白沙黎族自治县",
			"value": 469025000000
		}, {
			"label": "昌江黎族自治县",
			"value": 469026000000
		}, {
			"label": "乐东黎族自治县",
			"value": 469027000000
		}, {
			"label": "陵水黎族自治县",
			"value": 469028000000
		}, {
			"label": "保亭黎族苗族自治县",
			"value": 469029000000
		}, {
			"label": "琼中黎族苗族自治县",
			"value": 469030000000
		}]
	}]
}, {
	"label": "重庆市",
	"value": 50,
	"children": [{
		"label": "市辖区",
		"value": 500100000000,
		"children": [{
			"label": "万州区",
			"value": 500101000000
		}, {
			"label": "涪陵区",
			"value": 500102000000
		}, {
			"label": "渝中区",
			"value": 500103000000
		}, {
			"label": "大渡口区",
			"value": 500104000000
		}, {
			"label": "江北区",
			"value": 500105000000
		}, {
			"label": "沙坪坝区",
			"value": 500106000000
		}, {
			"label": "九龙坡区",
			"value": 500107000000
		}, {
			"label": "南岸区",
			"value": 500108000000
		}, {
			"label": "北碚区",
			"value": 500109000000
		}, {
			"label": "綦江区",
			"value": 500110000000
		}, {
			"label": "大足区",
			"value": 500111000000
		}, {
			"label": "渝北区",
			"value": 500112000000
		}, {
			"label": "巴南区",
			"value": 500113000000
		}, {
			"label": "黔江区",
			"value": 500114000000
		}, {
			"label": "长寿区",
			"value": 500115000000
		}, {
			"label": "江津区",
			"value": 500116000000
		}, {
			"label": "合川区",
			"value": 500117000000
		}, {
			"label": "永川区",
			"value": 500118000000
		}, {
			"label": "南川区",
			"value": 500119000000
		}, {
			"label": "璧山区",
			"value": 500120000000
		}, {
			"label": "铜梁区",
			"value": 500151000000
		}, {
			"label": "潼南区",
			"value": 500152000000
		}, {
			"label": "荣昌区",
			"value": 500153000000
		}]
	}, {
		"label": "县",
		"value": 500200000000,
		"children": [{
			"label": "梁平县",
			"value": 500228000000
		}, {
			"label": "城口县",
			"value": 500229000000
		}, {
			"label": "丰都县",
			"value": 500230000000
		}, {
			"label": "垫江县",
			"value": 500231000000
		}, {
			"label": "武隆县",
			"value": 500232000000
		}, {
			"label": "忠县",
			"value": 500233000000
		}, {
			"label": "开县",
			"value": 500234000000
		}, {
			"label": "云阳县",
			"value": 500235000000
		}, {
			"label": "奉节县",
			"value": 500236000000
		}, {
			"label": "巫山县",
			"value": 500237000000
		}, {
			"label": "巫溪县",
			"value": 500238000000
		}, {
			"label": "石柱土家族自治县",
			"value": 500240000000
		}, {
			"label": "秀山土家族苗族自治县",
			"value": 500241000000
		}, {
			"label": "酉阳土家族苗族自治县",
			"value": 500242000000
		}, {
			"label": "彭水苗族土家族自治县",
			"value": 500243000000
		}]
	}]
}, {
	"label": "四川省",
	"value": 51,
	"children": [{
		"label": "成都市",
		"value": 510100000000,
		"children": [{
			"label": "锦江区",
			"value": 510104000000
		}, {
			"label": "青羊区",
			"value": 510105000000
		}, {
			"label": "金牛区",
			"value": 510106000000
		}, {
			"label": "武侯区",
			"value": 510107000000
		}, {
			"label": "成华区",
			"value": 510108000000
		}, {
			"label": "龙泉驿区",
			"value": 510112000000
		}, {
			"label": "青白江区",
			"value": 510113000000
		}, {
			"label": "新都区",
			"value": 510114000000
		}, {
			"label": "温江区",
			"value": 510115000000
		}, {
			"label": "金堂县",
			"value": 510121000000
		}, {
			"label": "双流县",
			"value": 510122000000
		}, {
			"label": "郫县",
			"value": 510124000000
		}, {
			"label": "大邑县",
			"value": 510129000000
		}, {
			"label": "蒲江县",
			"value": 510131000000
		}, {
			"label": "新津县",
			"value": 510132000000
		}, {
			"label": "都江堰市",
			"value": 510181000000
		}, {
			"label": "彭州市",
			"value": 510182000000
		}, {
			"label": "邛崃市",
			"value": 510183000000
		}, {
			"label": "崇州市",
			"value": 510184000000
		}]
	}, {
		"label": "自贡市",
		"value": 510300000000,
		"children": [{
			"label": "自流井区",
			"value": 510302000000
		}, {
			"label": "贡井区",
			"value": 510303000000
		}, {
			"label": "大安区",
			"value": 510304000000
		}, {
			"label": "沿滩区",
			"value": 510311000000
		}, {
			"label": "荣县",
			"value": 510321000000
		}, {
			"label": "富顺县",
			"value": 510322000000
		}]
	}, {
		"label": "攀枝花市",
		"value": 510400000000,
		"children": [{
			"label": "东区",
			"value": 510402000000
		}, {
			"label": "西区",
			"value": 510403000000
		}, {
			"label": "仁和区",
			"value": 510411000000
		}, {
			"label": "米易县",
			"value": 510421000000
		}, {
			"label": "盐边县",
			"value": 510422000000
		}]
	}, {
		"label": "泸州市",
		"value": 510500000000,
		"children": [{
			"label": "江阳区",
			"value": 510502000000
		}, {
			"label": "纳溪区",
			"value": 510503000000
		}, {
			"label": "龙马潭区",
			"value": 510504000000
		}, {
			"label": "泸县",
			"value": 510521000000
		}, {
			"label": "合江县",
			"value": 510522000000
		}, {
			"label": "叙永县",
			"value": 510524000000
		}, {
			"label": "古蔺县",
			"value": 510525000000
		}]
	}, {
		"label": "德阳市",
		"value": 510600000000,
		"children": [{
			"label": "旌阳区",
			"value": 510603000000
		}, {
			"label": "中江县",
			"value": 510623000000
		}, {
			"label": "罗江县",
			"value": 510626000000
		}, {
			"label": "广汉市",
			"value": 510681000000
		}, {
			"label": "什邡市",
			"value": 510682000000
		}, {
			"label": "绵竹市",
			"value": 510683000000
		}]
	}, {
		"label": "绵阳市",
		"value": 510700000000,
		"children": [{
			"label": "涪城区",
			"value": 510703000000
		}, {
			"label": "游仙区",
			"value": 510704000000
		}, {
			"label": "三台县",
			"value": 510722000000
		}, {
			"label": "盐亭县",
			"value": 510723000000
		}, {
			"label": "安县",
			"value": 510724000000
		}, {
			"label": "梓潼县",
			"value": 510725000000
		}, {
			"label": "北川羌族自治县",
			"value": 510726000000
		}, {
			"label": "平武县",
			"value": 510727000000
		}, {
			"label": "江油市",
			"value": 510781000000
		}]
	}, {
		"label": "广元市",
		"value": 510800000000,
		"children": [{
			"label": "利州区",
			"value": 510802000000
		}, {
			"label": "昭化区",
			"value": 510811000000
		}, {
			"label": "朝天区",
			"value": 510812000000
		}, {
			"label": "旺苍县",
			"value": 510821000000
		}, {
			"label": "青川县",
			"value": 510822000000
		}, {
			"label": "剑阁县",
			"value": 510823000000
		}, {
			"label": "苍溪县",
			"value": 510824000000
		}]
	}, {
		"label": "遂宁市",
		"value": 510900000000,
		"children": [{
			"label": "船山区",
			"value": 510903000000
		}, {
			"label": "安居区",
			"value": 510904000000
		}, {
			"label": "蓬溪县",
			"value": 510921000000
		}, {
			"label": "射洪县",
			"value": 510922000000
		}, {
			"label": "大英县",
			"value": 510923000000
		}]
	}, {
		"label": "内江市",
		"value": 511000000000,
		"children": [{
			"label": "市中区",
			"value": 511002000000
		}, {
			"label": "东兴区",
			"value": 511011000000
		}, {
			"label": "威远县",
			"value": 511024000000
		}, {
			"label": "资中县",
			"value": 511025000000
		}, {
			"label": "隆昌县",
			"value": 511028000000
		}]
	}, {
		"label": "乐山市",
		"value": 511100000000,
		"children": [{
			"label": "市中区",
			"value": 511102000000
		}, {
			"label": "沙湾区",
			"value": 511111000000
		}, {
			"label": "五通桥区",
			"value": 511112000000
		}, {
			"label": "金口河区",
			"value": 511113000000
		}, {
			"label": "犍为县",
			"value": 511123000000
		}, {
			"label": "井研县",
			"value": 511124000000
		}, {
			"label": "夹江县",
			"value": 511126000000
		}, {
			"label": "沐川县",
			"value": 511129000000
		}, {
			"label": "峨边彝族自治县",
			"value": 511132000000
		}, {
			"label": "马边彝族自治县",
			"value": 511133000000
		}, {
			"label": "峨眉山市",
			"value": 511181000000
		}]
	}, {
		"label": "南充市",
		"value": 511300000000,
		"children": [{
			"label": "顺庆区",
			"value": 511302000000
		}, {
			"label": "高坪区",
			"value": 511303000000
		}, {
			"label": "嘉陵区",
			"value": 511304000000
		}, {
			"label": "南部县",
			"value": 511321000000
		}, {
			"label": "营山县",
			"value": 511322000000
		}, {
			"label": "蓬安县",
			"value": 511323000000
		}, {
			"label": "仪陇县",
			"value": 511324000000
		}, {
			"label": "西充县",
			"value": 511325000000
		}, {
			"label": "阆中市",
			"value": 511381000000
		}]
	}, {
		"label": "眉山市",
		"value": 511400000000,
		"children": [{
			"label": "东坡区",
			"value": 511402000000
		}, {
			"label": "彭山区",
			"value": 511403000000
		}, {
			"label": "仁寿县",
			"value": 511421000000
		}, {
			"label": "洪雅县",
			"value": 511423000000
		}, {
			"label": "丹棱县",
			"value": 511424000000
		}, {
			"label": "青神县",
			"value": 511425000000
		}]
	}, {
		"label": "宜宾市",
		"value": 511500000000,
		"children": [{
			"label": "翠屏区",
			"value": 511502000000
		}, {
			"label": "南溪区",
			"value": 511503000000
		}, {
			"label": "宜宾县",
			"value": 511521000000
		}, {
			"label": "江安县",
			"value": 511523000000
		}, {
			"label": "长宁县",
			"value": 511524000000
		}, {
			"label": "高县",
			"value": 511525000000
		}, {
			"label": "珙县",
			"value": 511526000000
		}, {
			"label": "筠连县",
			"value": 511527000000
		}, {
			"label": "兴文县",
			"value": 511528000000
		}, {
			"label": "屏山县",
			"value": 511529000000
		}]
	}, {
		"label": "广安市",
		"value": 511600000000,
		"children": [{
			"label": "广安区",
			"value": 511602000000
		}, {
			"label": "前锋区",
			"value": 511603000000
		}, {
			"label": "岳池县",
			"value": 511621000000
		}, {
			"label": "武胜县",
			"value": 511622000000
		}, {
			"label": "邻水县",
			"value": 511623000000
		}, {
			"label": "华蓥市",
			"value": 511681000000
		}]
	}, {
		"label": "达州市",
		"value": 511700000000,
		"children": [{
			"label": "通川区",
			"value": 511702000000
		}, {
			"label": "达川区",
			"value": 511703000000
		}, {
			"label": "宣汉县",
			"value": 511722000000
		}, {
			"label": "开江县",
			"value": 511723000000
		}, {
			"label": "大竹县",
			"value": 511724000000
		}, {
			"label": "渠县",
			"value": 511725000000
		}, {
			"label": "万源市",
			"value": 511781000000
		}]
	}, {
		"label": "雅安市",
		"value": 511800000000,
		"children": [{
			"label": "雨城区",
			"value": 511802000000
		}, {
			"label": "名山区",
			"value": 511803000000
		}, {
			"label": "荥经县",
			"value": 511822000000
		}, {
			"label": "汉源县",
			"value": 511823000000
		}, {
			"label": "石棉县",
			"value": 511824000000
		}, {
			"label": "天全县",
			"value": 511825000000
		}, {
			"label": "芦山县",
			"value": 511826000000
		}, {
			"label": "宝兴县",
			"value": 511827000000
		}]
	}, {
		"label": "巴中市",
		"value": 511900000000,
		"children": [{
			"label": "巴州区",
			"value": 511902000000
		}, {
			"label": "恩阳区",
			"value": 511903000000
		}, {
			"label": "通江县",
			"value": 511921000000
		}, {
			"label": "南江县",
			"value": 511922000000
		}, {
			"label": "平昌县",
			"value": 511923000000
		}]
	}, {
		"label": "资阳市",
		"value": 512000000000,
		"children": [{
			"label": "雁江区",
			"value": 512002000000
		}, {
			"label": "安岳县",
			"value": 512021000000
		}, {
			"label": "乐至县",
			"value": 512022000000
		}, {
			"label": "简阳市",
			"value": 512081000000
		}]
	}, {
		"label": "阿坝藏族羌族自治州",
		"value": 513200000000,
		"children": [{
			"label": "汶川县",
			"value": 513221000000
		}, {
			"label": "理县",
			"value": 513222000000
		}, {
			"label": "茂县",
			"value": 513223000000
		}, {
			"label": "松潘县",
			"value": 513224000000
		}, {
			"label": "九寨沟县",
			"value": 513225000000
		}, {
			"label": "金川县",
			"value": 513226000000
		}, {
			"label": "小金县",
			"value": 513227000000
		}, {
			"label": "黑水县",
			"value": 513228000000
		}, {
			"label": "马尔康县",
			"value": 513229000000
		}, {
			"label": "壤塘县",
			"value": 513230000000
		}, {
			"label": "阿坝县",
			"value": 513231000000
		}, {
			"label": "若尔盖县",
			"value": 513232000000
		}, {
			"label": "红原县",
			"value": 513233000000
		}]
	}, {
		"label": "甘孜藏族自治州",
		"value": 513300000000,
		"children": [{
			"label": "康定市",
			"value": 513301000000
		}, {
			"label": "泸定县",
			"value": 513322000000
		}, {
			"label": "丹巴县",
			"value": 513323000000
		}, {
			"label": "九龙县",
			"value": 513324000000
		}, {
			"label": "雅江县",
			"value": 513325000000
		}, {
			"label": "道孚县",
			"value": 513326000000
		}, {
			"label": "炉霍县",
			"value": 513327000000
		}, {
			"label": "甘孜县",
			"value": 513328000000
		}, {
			"label": "新龙县",
			"value": 513329000000
		}, {
			"label": "德格县",
			"value": 513330000000
		}, {
			"label": "白玉县",
			"value": 513331000000
		}, {
			"label": "石渠县",
			"value": 513332000000
		}, {
			"label": "色达县",
			"value": 513333000000
		}, {
			"label": "理塘县",
			"value": 513334000000
		}, {
			"label": "巴塘县",
			"value": 513335000000
		}, {
			"label": "乡城县",
			"value": 513336000000
		}, {
			"label": "稻城县",
			"value": 513337000000
		}, {
			"label": "得荣县",
			"value": 513338000000
		}]
	}, {
		"label": "凉山彝族自治州",
		"value": 513400000000,
		"children": [{
			"label": "西昌市",
			"value": 513401000000
		}, {
			"label": "木里藏族自治县",
			"value": 513422000000
		}, {
			"label": "盐源县",
			"value": 513423000000
		}, {
			"label": "德昌县",
			"value": 513424000000
		}, {
			"label": "会理县",
			"value": 513425000000
		}, {
			"label": "会东县",
			"value": 513426000000
		}, {
			"label": "宁南县",
			"value": 513427000000
		}, {
			"label": "普格县",
			"value": 513428000000
		}, {
			"label": "布拖县",
			"value": 513429000000
		}, {
			"label": "金阳县",
			"value": 513430000000
		}, {
			"label": "昭觉县",
			"value": 513431000000
		}, {
			"label": "喜德县",
			"value": 513432000000
		}, {
			"label": "冕宁县",
			"value": 513433000000
		}, {
			"label": "越西县",
			"value": 513434000000
		}, {
			"label": "甘洛县",
			"value": 513435000000
		}, {
			"label": "美姑县",
			"value": 513436000000
		}, {
			"label": "雷波县",
			"value": 513437000000
		}]
	}]
}, {
	"label": "贵州省",
	"value": 52,
	"children": [{
		"label": "贵阳市",
		"value": 520100000000,
		"children": [{
			"label": "南明区",
			"value": 520102000000
		}, {
			"label": "云岩区",
			"value": 520103000000
		}, {
			"label": "花溪区",
			"value": 520111000000
		}, {
			"label": "乌当区",
			"value": 520112000000
		}, {
			"label": "白云区",
			"value": 520113000000
		}, {
			"label": "观山湖区",
			"value": 520115000000
		}, {
			"label": "开阳县",
			"value": 520121000000
		}, {
			"label": "息烽县",
			"value": 520122000000
		}, {
			"label": "修文县",
			"value": 520123000000
		}, {
			"label": "清镇市",
			"value": 520181000000
		}]
	}, {
		"label": "六盘水市",
		"value": 520200000000,
		"children": [{
			"label": "钟山区",
			"value": 520201000000
		}, {
			"label": "六枝特区",
			"value": 520203000000
		}, {
			"label": "水城县",
			"value": 520221000000
		}, {
			"label": "盘县",
			"value": 520222000000
		}]
	}, {
		"label": "遵义市",
		"value": 520300000000,
		"children": [{
			"label": "红花岗区",
			"value": 520302000000
		}, {
			"label": "汇川区",
			"value": 520303000000
		}, {
			"label": "遵义县",
			"value": 520321000000
		}, {
			"label": "桐梓县",
			"value": 520322000000
		}, {
			"label": "绥阳县",
			"value": 520323000000
		}, {
			"label": "正安县",
			"value": 520324000000
		}, {
			"label": "道真仡佬族苗族自治县",
			"value": 520325000000
		}, {
			"label": "务川仡佬族苗族自治县",
			"value": 520326000000
		}, {
			"label": "凤冈县",
			"value": 520327000000
		}, {
			"label": "湄潭县",
			"value": 520328000000
		}, {
			"label": "余庆县",
			"value": 520329000000
		}, {
			"label": "习水县",
			"value": 520330000000
		}, {
			"label": "赤水市",
			"value": 520381000000
		}, {
			"label": "仁怀市",
			"value": 520382000000
		}]
	}, {
		"label": "安顺市",
		"value": 520400000000,
		"children": [{
			"label": "西秀区",
			"value": 520402000000
		}, {
			"label": "平坝区",
			"value": 520403000000
		}, {
			"label": "普定县",
			"value": 520422000000
		}, {
			"label": "镇宁布依族苗族自治县",
			"value": 520423000000
		}, {
			"label": "关岭布依族苗族自治县",
			"value": 520424000000
		}, {
			"label": "紫云苗族布依族自治县",
			"value": 520425000000
		}]
	}, {
		"label": "毕节市",
		"value": 520500000000,
		"children": [{
			"label": "七星关区",
			"value": 520502000000
		}, {
			"label": "大方县",
			"value": 520521000000
		}, {
			"label": "黔西县",
			"value": 520522000000
		}, {
			"label": "金沙县",
			"value": 520523000000
		}, {
			"label": "织金县",
			"value": 520524000000
		}, {
			"label": "纳雍县",
			"value": 520525000000
		}, {
			"label": "威宁彝族回族苗族自治县",
			"value": 520526000000
		}, {
			"label": "赫章县",
			"value": 520527000000
		}]
	}, {
		"label": "铜仁市",
		"value": 520600000000,
		"children": [{
			"label": "碧江区",
			"value": 520602000000
		}, {
			"label": "万山区",
			"value": 520603000000
		}, {
			"label": "江口县",
			"value": 520621000000
		}, {
			"label": "玉屏侗族自治县",
			"value": 520622000000
		}, {
			"label": "石阡县",
			"value": 520623000000
		}, {
			"label": "思南县",
			"value": 520624000000
		}, {
			"label": "印江土家族苗族自治县",
			"value": 520625000000
		}, {
			"label": "德江县",
			"value": 520626000000
		}, {
			"label": "沿河土家族自治县",
			"value": 520627000000
		}, {
			"label": "松桃苗族自治县",
			"value": 520628000000
		}]
	}, {
		"label": "黔西南布依族苗族自治州",
		"value": 522300000000,
		"children": [{
			"label": "兴义市",
			"value": 522301000000
		}, {
			"label": "兴仁县",
			"value": 522322000000
		}, {
			"label": "普安县",
			"value": 522323000000
		}, {
			"label": "晴隆县",
			"value": 522324000000
		}, {
			"label": "贞丰县",
			"value": 522325000000
		}, {
			"label": "望谟县",
			"value": 522326000000
		}, {
			"label": "册亨县",
			"value": 522327000000
		}, {
			"label": "安龙县",
			"value": 522328000000
		}]
	}, {
		"label": "黔东南苗族侗族自治州",
		"value": 522600000000,
		"children": [{
			"label": "凯里市",
			"value": 522601000000
		}, {
			"label": "黄平县",
			"value": 522622000000
		}, {
			"label": "施秉县",
			"value": 522623000000
		}, {
			"label": "三穗县",
			"value": 522624000000
		}, {
			"label": "镇远县",
			"value": 522625000000
		}, {
			"label": "岑巩县",
			"value": 522626000000
		}, {
			"label": "天柱县",
			"value": 522627000000
		}, {
			"label": "锦屏县",
			"value": 522628000000
		}, {
			"label": "剑河县",
			"value": 522629000000
		}, {
			"label": "台江县",
			"value": 522630000000
		}, {
			"label": "黎平县",
			"value": 522631000000
		}, {
			"label": "榕江县",
			"value": 522632000000
		}, {
			"label": "从江县",
			"value": 522633000000
		}, {
			"label": "雷山县",
			"value": 522634000000
		}, {
			"label": "麻江县",
			"value": 522635000000
		}, {
			"label": "丹寨县",
			"value": 522636000000
		}]
	}, {
		"label": "黔南布依族苗族自治州",
		"value": 522700000000,
		"children": [{
			"label": "都匀市",
			"value": 522701000000
		}, {
			"label": "福泉市",
			"value": 522702000000
		}, {
			"label": "荔波县",
			"value": 522722000000
		}, {
			"label": "贵定县",
			"value": 522723000000
		}, {
			"label": "瓮安县",
			"value": 522725000000
		}, {
			"label": "独山县",
			"value": 522726000000
		}, {
			"label": "平塘县",
			"value": 522727000000
		}, {
			"label": "罗甸县",
			"value": 522728000000
		}, {
			"label": "长顺县",
			"value": 522729000000
		}, {
			"label": "龙里县",
			"value": 522730000000
		}, {
			"label": "惠水县",
			"value": 522731000000
		}, {
			"label": "三都水族自治县",
			"value": 522732000000
		}]
	}]
}, {
	"label": "云南省",
	"value": 53,
	"children": [{
		"label": "昆明市",
		"value": 530100000000,
		"children": [{
			"label": "五华区",
			"value": 530102000000
		}, {
			"label": "盘龙区",
			"value": 530103000000
		}, {
			"label": "官渡区",
			"value": 530111000000
		}, {
			"label": "西山区",
			"value": 530112000000
		}, {
			"label": "东川区",
			"value": 530113000000
		}, {
			"label": "呈贡区",
			"value": 530114000000
		}, {
			"label": "晋宁县",
			"value": 530122000000
		}, {
			"label": "富民县",
			"value": 530124000000
		}, {
			"label": "宜良县",
			"value": 530125000000
		}, {
			"label": "石林彝族自治县",
			"value": 530126000000
		}, {
			"label": "嵩明县",
			"value": 530127000000
		}, {
			"label": "禄劝彝族苗族自治县",
			"value": 530128000000
		}, {
			"label": "寻甸回族彝族自治县",
			"value": 530129000000
		}, {
			"label": "安宁市",
			"value": 530181000000
		}]
	}, {
		"label": "曲靖市",
		"value": 530300000000,
		"children": [{
			"label": "麒麟区",
			"value": 530302000000
		}, {
			"label": "马龙县",
			"value": 530321000000
		}, {
			"label": "陆良县",
			"value": 530322000000
		}, {
			"label": "师宗县",
			"value": 530323000000
		}, {
			"label": "罗平县",
			"value": 530324000000
		}, {
			"label": "富源县",
			"value": 530325000000
		}, {
			"label": "会泽县",
			"value": 530326000000
		}, {
			"label": "沾益县",
			"value": 530328000000
		}, {
			"label": "宣威市",
			"value": 530381000000
		}]
	}, {
		"label": "玉溪市",
		"value": 530400000000,
		"children": [{
			"label": "红塔区",
			"value": 530402000000
		}, {
			"label": "江川县",
			"value": 530421000000
		}, {
			"label": "澄江县",
			"value": 530422000000
		}, {
			"label": "通海县",
			"value": 530423000000
		}, {
			"label": "华宁县",
			"value": 530424000000
		}, {
			"label": "易门县",
			"value": 530425000000
		}, {
			"label": "峨山彝族自治县",
			"value": 530426000000
		}, {
			"label": "新平彝族傣族自治县",
			"value": 530427000000
		}, {
			"label": "元江哈尼族彝族傣族自治县",
			"value": 530428000000
		}]
	}, {
		"label": "保山市",
		"value": 530500000000,
		"children": [{
			"label": "隆阳区",
			"value": 530502000000
		}, {
			"label": "施甸县",
			"value": 530521000000
		}, {
			"label": "龙陵县",
			"value": 530523000000
		}, {
			"label": "昌宁县",
			"value": 530524000000
		}, {
			"label": "腾冲市",
			"value": 530581000000
		}]
	}, {
		"label": "昭通市",
		"value": 530600000000,
		"children": [{
			"label": "昭阳区",
			"value": 530602000000
		}, {
			"label": "鲁甸县",
			"value": 530621000000
		}, {
			"label": "巧家县",
			"value": 530622000000
		}, {
			"label": "盐津县",
			"value": 530623000000
		}, {
			"label": "大关县",
			"value": 530624000000
		}, {
			"label": "永善县",
			"value": 530625000000
		}, {
			"label": "绥江县",
			"value": 530626000000
		}, {
			"label": "镇雄县",
			"value": 530627000000
		}, {
			"label": "彝良县",
			"value": 530628000000
		}, {
			"label": "威信县",
			"value": 530629000000
		}, {
			"label": "水富县",
			"value": 530630000000
		}]
	}, {
		"label": "丽江市",
		"value": 530700000000,
		"children": [{
			"label": "古城区",
			"value": 530702000000
		}, {
			"label": "玉龙纳西族自治县",
			"value": 530721000000
		}, {
			"label": "永胜县",
			"value": 530722000000
		}, {
			"label": "华坪县",
			"value": 530723000000
		}, {
			"label": "宁蒗彝族自治县",
			"value": 530724000000
		}]
	}, {
		"label": "普洱市",
		"value": 530800000000,
		"children": [{
			"label": "思茅区",
			"value": 530802000000
		}, {
			"label": "宁洱哈尼族彝族自治县",
			"value": 530821000000
		}, {
			"label": "墨江哈尼族自治县",
			"value": 530822000000
		}, {
			"label": "景东彝族自治县",
			"value": 530823000000
		}, {
			"label": "景谷傣族彝族自治县",
			"value": 530824000000
		}, {
			"label": "镇沅彝族哈尼族拉祜族自治县",
			"value": 530825000000
		}, {
			"label": "江城哈尼族彝族自治县",
			"value": 530826000000
		}, {
			"label": "孟连傣族拉祜族佤族自治县",
			"value": 530827000000
		}, {
			"label": "澜沧拉祜族自治县",
			"value": 530828000000
		}, {
			"label": "西盟佤族自治县",
			"value": 530829000000
		}]
	}, {
		"label": "临沧市",
		"value": 530900000000,
		"children": [{
			"label": "临翔区",
			"value": 530902000000
		}, {
			"label": "凤庆县",
			"value": 530921000000
		}, {
			"label": "云县",
			"value": 530922000000
		}, {
			"label": "永德县",
			"value": 530923000000
		}, {
			"label": "镇康县",
			"value": 530924000000
		}, {
			"label": "双江拉祜族佤族布朗族傣族自治县",
			"value": 530925000000
		}, {
			"label": "耿马傣族佤族自治县",
			"value": 530926000000
		}, {
			"label": "沧源佤族自治县",
			"value": 530927000000
		}]
	}, {
		"label": "楚雄彝族自治州",
		"value": 532300000000,
		"children": [{
			"label": "楚雄市",
			"value": 532301000000
		}, {
			"label": "双柏县",
			"value": 532322000000
		}, {
			"label": "牟定县",
			"value": 532323000000
		}, {
			"label": "南华县",
			"value": 532324000000
		}, {
			"label": "姚安县",
			"value": 532325000000
		}, {
			"label": "大姚县",
			"value": 532326000000
		}, {
			"label": "永仁县",
			"value": 532327000000
		}, {
			"label": "元谋县",
			"value": 532328000000
		}, {
			"label": "武定县",
			"value": 532329000000
		}, {
			"label": "禄丰县",
			"value": 532331000000
		}]
	}, {
		"label": "红河哈尼族彝族自治州",
		"value": 532500000000,
		"children": [{
			"label": "个旧市",
			"value": 532501000000
		}, {
			"label": "开远市",
			"value": 532502000000
		}, {
			"label": "蒙自市",
			"value": 532503000000
		}, {
			"label": "弥勒市",
			"value": 532504000000
		}, {
			"label": "屏边苗族自治县",
			"value": 532523000000
		}, {
			"label": "建水县",
			"value": 532524000000
		}, {
			"label": "石屏县",
			"value": 532525000000
		}, {
			"label": "泸西县",
			"value": 532527000000
		}, {
			"label": "元阳县",
			"value": 532528000000
		}, {
			"label": "红河县",
			"value": 532529000000
		}, {
			"label": "金平苗族瑶族傣族自治县",
			"value": 532530000000
		}, {
			"label": "绿春县",
			"value": 532531000000
		}, {
			"label": "河口瑶族自治县",
			"value": 532532000000
		}]
	}, {
		"label": "文山壮族苗族自治州",
		"value": 532600000000,
		"children": [{
			"label": "文山市",
			"value": 532601000000
		}, {
			"label": "砚山县",
			"value": 532622000000
		}, {
			"label": "西畴县",
			"value": 532623000000
		}, {
			"label": "麻栗坡县",
			"value": 532624000000
		}, {
			"label": "马关县",
			"value": 532625000000
		}, {
			"label": "丘北县",
			"value": 532626000000
		}, {
			"label": "广南县",
			"value": 532627000000
		}, {
			"label": "富宁县",
			"value": 532628000000
		}]
	}, {
		"label": "西双版纳傣族自治州",
		"value": 532800000000,
		"children": [{
			"label": "景洪市",
			"value": 532801000000
		}, {
			"label": "勐海县",
			"value": 532822000000
		}, {
			"label": "勐腊县",
			"value": 532823000000
		}]
	}, {
		"label": "大理白族自治州",
		"value": 532900000000,
		"children": [{
			"label": "大理市",
			"value": 532901000000
		}, {
			"label": "漾濞彝族自治县",
			"value": 532922000000
		}, {
			"label": "祥云县",
			"value": 532923000000
		}, {
			"label": "宾川县",
			"value": 532924000000
		}, {
			"label": "弥渡县",
			"value": 532925000000
		}, {
			"label": "南涧彝族自治县",
			"value": 532926000000
		}, {
			"label": "巍山彝族回族自治县",
			"value": 532927000000
		}, {
			"label": "永平县",
			"value": 532928000000
		}, {
			"label": "云龙县",
			"value": 532929000000
		}, {
			"label": "洱源县",
			"value": 532930000000
		}, {
			"label": "剑川县",
			"value": 532931000000
		}, {
			"label": "鹤庆县",
			"value": 532932000000
		}]
	}, {
		"label": "德宏傣族景颇族自治州",
		"value": 533100000000,
		"children": [{
			"label": "瑞丽市",
			"value": 533102000000
		}, {
			"label": "芒市",
			"value": 533103000000
		}, {
			"label": "梁河县",
			"value": 533122000000
		}, {
			"label": "盈江县",
			"value": 533123000000
		}, {
			"label": "陇川县",
			"value": 533124000000
		}]
	}, {
		"label": "怒江傈僳族自治州",
		"value": 533300000000,
		"children": [{
			"label": "泸水县",
			"value": 533321000000
		}, {
			"label": "福贡县",
			"value": 533323000000
		}, {
			"label": "贡山独龙族怒族自治县",
			"value": 533324000000
		}, {
			"label": "兰坪白族普米族自治县",
			"value": 533325000000
		}]
	}, {
		"label": "迪庆藏族自治州",
		"value": 533400000000,
		"children": [{
			"label": "香格里拉市",
			"value": 533401000000
		}, {
			"label": "德钦县",
			"value": 533422000000
		}, {
			"label": "维西傈僳族自治县",
			"value": 533423000000
		}]
	}]
}, {
	"label": "西藏自治区",
	"value": 54,
	"children": [{
		"label": "拉萨市",
		"value": 540100000000,
		"children": [{
			"label": "城关区",
			"value": 540102000000
		}, {
			"label": "林周县",
			"value": 540121000000
		}, {
			"label": "当雄县",
			"value": 540122000000
		}, {
			"label": "尼木县",
			"value": 540123000000
		}, {
			"label": "曲水县",
			"value": 540124000000
		}, {
			"label": "堆龙德庆县",
			"value": 540125000000
		}, {
			"label": "达孜县",
			"value": 540126000000
		}, {
			"label": "墨竹工卡县",
			"value": 540127000000
		}]
	}, {
		"label": "日喀则市",
		"value": 540200000000,
		"children": [{
			"label": "桑珠孜区",
			"value": 540202000000
		}, {
			"label": "南木林县",
			"value": 540221000000
		}, {
			"label": "江孜县",
			"value": 540222000000
		}, {
			"label": "定日县",
			"value": 540223000000
		}, {
			"label": "萨迦县",
			"value": 540224000000
		}, {
			"label": "拉孜县",
			"value": 540225000000
		}, {
			"label": "昂仁县",
			"value": 540226000000
		}, {
			"label": "谢通门县",
			"value": 540227000000
		}, {
			"label": "白朗县",
			"value": 540228000000
		}, {
			"label": "仁布县",
			"value": 540229000000
		}, {
			"label": "康马县",
			"value": 540230000000
		}, {
			"label": "定结县",
			"value": 540231000000
		}, {
			"label": "仲巴县",
			"value": 540232000000
		}, {
			"label": "亚东县",
			"value": 540233000000
		}, {
			"label": "吉隆县",
			"value": 540234000000
		}, {
			"label": "聂拉木县",
			"value": 540235000000
		}, {
			"label": "萨嘎县",
			"value": 540236000000
		}, {
			"label": "岗巴县",
			"value": 540237000000
		}]
	}, {
		"label": "昌都市",
		"value": 540300000000,
		"children": [{
			"label": "卡若区",
			"value": 540302000000
		}, {
			"label": "江达县",
			"value": 540321000000
		}, {
			"label": "贡觉县",
			"value": 540322000000
		}, {
			"label": "类乌齐县",
			"value": 540323000000
		}, {
			"label": "丁青县",
			"value": 540324000000
		}, {
			"label": "察雅县",
			"value": 540325000000
		}, {
			"label": "八宿县",
			"value": 540326000000
		}, {
			"label": "左贡县",
			"value": 540327000000
		}, {
			"label": "芒康县",
			"value": 540328000000
		}, {
			"label": "洛隆县",
			"value": 540329000000
		}, {
			"label": "边坝县",
			"value": 540330000000
		}]
	}, {
		"label": "林芝市",
		"value": 540400000000,
		"children": [{
			"label": "巴宜区",
			"value": 540402000000
		}, {
			"label": "工布江达县",
			"value": 540421000000
		}, {
			"label": "米林县",
			"value": 540422000000
		}, {
			"label": "墨脱县",
			"value": 540423000000
		}, {
			"label": "波密县",
			"value": 540424000000
		}, {
			"label": "察隅县",
			"value": 540425000000
		}, {
			"label": "朗县",
			"value": 540426000000
		}]
	}, {
		"label": "山南地区",
		"value": 542200000000,
		"children": [{
			"label": "乃东县",
			"value": 542221000000
		}, {
			"label": "扎囊县",
			"value": 542222000000
		}, {
			"label": "贡嘎县",
			"value": 542223000000
		}, {
			"label": "桑日县",
			"value": 542224000000
		}, {
			"label": "琼结县",
			"value": 542225000000
		}, {
			"label": "曲松县",
			"value": 542226000000
		}, {
			"label": "措美县",
			"value": 542227000000
		}, {
			"label": "洛扎县",
			"value": 542228000000
		}, {
			"label": "加查县",
			"value": 542229000000
		}, {
			"label": "隆子县",
			"value": 542231000000
		}, {
			"label": "错那县",
			"value": 542232000000
		}, {
			"label": "浪卡子县",
			"value": 542233000000
		}]
	}, {
		"label": "那曲地区",
		"value": 542400000000,
		"children": [{
			"label": "那曲县",
			"value": 542421000000
		}, {
			"label": "嘉黎县",
			"value": 542422000000
		}, {
			"label": "比如县",
			"value": 542423000000
		}, {
			"label": "聂荣县",
			"value": 542424000000
		}, {
			"label": "安多县",
			"value": 542425000000
		}, {
			"label": "申扎县",
			"value": 542426000000
		}, {
			"label": "索县",
			"value": 542427000000
		}, {
			"label": "班戈县",
			"value": 542428000000
		}, {
			"label": "巴青县",
			"value": 542429000000
		}, {
			"label": "尼玛县",
			"value": 542430000000
		}, {
			"label": "双湖县",
			"value": 542431000000
		}]
	}, {
		"label": "阿里地区",
		"value": 542500000000,
		"children": [{
			"label": "普兰县",
			"value": 542521000000
		}, {
			"label": "札达县",
			"value": 542522000000
		}, {
			"label": "噶尔县",
			"value": 542523000000
		}, {
			"label": "日土县",
			"value": 542524000000
		}, {
			"label": "革吉县",
			"value": 542525000000
		}, {
			"label": "改则县",
			"value": 542526000000
		}, {
			"label": "措勤县",
			"value": 542527000000
		}]
	}]
}, {
	"label": "陕西省",
	"value": 61,
	"children": [{
		"label": "西安市",
		"value": 610100000000,
		"children": [{
			"label": "新城区",
			"value": 610102000000
		}, {
			"label": "碑林区",
			"value": 610103000000
		}, {
			"label": "莲湖区",
			"value": 610104000000
		}, {
			"label": "灞桥区",
			"value": 610111000000
		}, {
			"label": "未央区",
			"value": 610112000000
		}, {
			"label": "雁塔区",
			"value": 610113000000
		}, {
			"label": "阎良区",
			"value": 610114000000
		}, {
			"label": "临潼区",
			"value": 610115000000
		}, {
			"label": "长安区",
			"value": 610116000000
		}, {
			"label": "高陵区",
			"value": 610117000000
		}, {
			"label": "蓝田县",
			"value": 610122000000
		}, {
			"label": "周至县",
			"value": 610124000000
		}, {
			"label": "户县",
			"value": 610125000000
		}]
	}, {
		"label": "铜川市",
		"value": 610200000000,
		"children": [{
			"label": "王益区",
			"value": 610202000000
		}, {
			"label": "印台区",
			"value": 610203000000
		}, {
			"label": "耀州区",
			"value": 610204000000
		}, {
			"label": "宜君县",
			"value": 610222000000
		}]
	}, {
		"label": "宝鸡市",
		"value": 610300000000,
		"children": [{
			"label": "渭滨区",
			"value": 610302000000
		}, {
			"label": "金台区",
			"value": 610303000000
		}, {
			"label": "陈仓区",
			"value": 610304000000
		}, {
			"label": "凤翔县",
			"value": 610322000000
		}, {
			"label": "岐山县",
			"value": 610323000000
		}, {
			"label": "扶风县",
			"value": 610324000000
		}, {
			"label": "眉县",
			"value": 610326000000
		}, {
			"label": "陇县",
			"value": 610327000000
		}, {
			"label": "千阳县",
			"value": 610328000000
		}, {
			"label": "麟游县",
			"value": 610329000000
		}, {
			"label": "凤县",
			"value": 610330000000
		}, {
			"label": "太白县",
			"value": 610331000000
		}]
	}, {
		"label": "咸阳市",
		"value": 610400000000,
		"children": [{
			"label": "秦都区",
			"value": 610402000000
		}, {
			"label": "杨陵区",
			"value": 610403000000
		}, {
			"label": "渭城区",
			"value": 610404000000
		}, {
			"label": "三原县",
			"value": 610422000000
		}, {
			"label": "泾阳县",
			"value": 610423000000
		}, {
			"label": "乾县",
			"value": 610424000000
		}, {
			"label": "礼泉县",
			"value": 610425000000
		}, {
			"label": "永寿县",
			"value": 610426000000
		}, {
			"label": "彬县",
			"value": 610427000000
		}, {
			"label": "长武县",
			"value": 610428000000
		}, {
			"label": "旬邑县",
			"value": 610429000000
		}, {
			"label": "淳化县",
			"value": 610430000000
		}, {
			"label": "武功县",
			"value": 610431000000
		}, {
			"label": "兴平市",
			"value": 610481000000
		}]
	}, {
		"label": "渭南市",
		"value": 610500000000,
		"children": [{
			"label": "临渭区",
			"value": 610502000000
		}, {
			"label": "华县",
			"value": 610521000000
		}, {
			"label": "潼关县",
			"value": 610522000000
		}, {
			"label": "大荔县",
			"value": 610523000000
		}, {
			"label": "合阳县",
			"value": 610524000000
		}, {
			"label": "澄城县",
			"value": 610525000000
		}, {
			"label": "蒲城县",
			"value": 610526000000
		}, {
			"label": "白水县",
			"value": 610527000000
		}, {
			"label": "富平县",
			"value": 610528000000
		}, {
			"label": "韩城市",
			"value": 610581000000
		}, {
			"label": "华阴市",
			"value": 610582000000
		}]
	}, {
		"label": "延安市",
		"value": 610600000000,
		"children": [{
			"label": "宝塔区",
			"value": 610602000000
		}, {
			"label": "延长县",
			"value": 610621000000
		}, {
			"label": "延川县",
			"value": 610622000000
		}, {
			"label": "子长县",
			"value": 610623000000
		}, {
			"label": "安塞县",
			"value": 610624000000
		}, {
			"label": "志丹县",
			"value": 610625000000
		}, {
			"label": "吴起县",
			"value": 610626000000
		}, {
			"label": "甘泉县",
			"value": 610627000000
		}, {
			"label": "富县",
			"value": 610628000000
		}, {
			"label": "洛川县",
			"value": 610629000000
		}, {
			"label": "宜川县",
			"value": 610630000000
		}, {
			"label": "黄龙县",
			"value": 610631000000
		}, {
			"label": "黄陵县",
			"value": 610632000000
		}]
	}, {
		"label": "汉中市",
		"value": 610700000000,
		"children": [{
			"label": "汉台区",
			"value": 610702000000
		}, {
			"label": "南郑县",
			"value": 610721000000
		}, {
			"label": "城固县",
			"value": 610722000000
		}, {
			"label": "洋县",
			"value": 610723000000
		}, {
			"label": "西乡县",
			"value": 610724000000
		}, {
			"label": "勉县",
			"value": 610725000000
		}, {
			"label": "宁强县",
			"value": 610726000000
		}, {
			"label": "略阳县",
			"value": 610727000000
		}, {
			"label": "镇巴县",
			"value": 610728000000
		}, {
			"label": "留坝县",
			"value": 610729000000
		}, {
			"label": "佛坪县",
			"value": 610730000000
		}]
	}, {
		"label": "榆林市",
		"value": 610800000000,
		"children": [{
			"label": "榆阳区",
			"value": 610802000000
		}, {
			"label": "神木县",
			"value": 610821000000
		}, {
			"label": "府谷县",
			"value": 610822000000
		}, {
			"label": "横山县",
			"value": 610823000000
		}, {
			"label": "靖边县",
			"value": 610824000000
		}, {
			"label": "定边县",
			"value": 610825000000
		}, {
			"label": "绥德县",
			"value": 610826000000
		}, {
			"label": "米脂县",
			"value": 610827000000
		}, {
			"label": "佳县",
			"value": 610828000000
		}, {
			"label": "吴堡县",
			"value": 610829000000
		}, {
			"label": "清涧县",
			"value": 610830000000
		}, {
			"label": "子洲县",
			"value": 610831000000
		}]
	}, {
		"label": "安康市",
		"value": 610900000000,
		"children": [{
			"label": "汉滨区",
			"value": 610902000000
		}, {
			"label": "汉阴县",
			"value": 610921000000
		}, {
			"label": "石泉县",
			"value": 610922000000
		}, {
			"label": "宁陕县",
			"value": 610923000000
		}, {
			"label": "紫阳县",
			"value": 610924000000
		}, {
			"label": "岚皋县",
			"value": 610925000000
		}, {
			"label": "平利县",
			"value": 610926000000
		}, {
			"label": "镇坪县",
			"value": 610927000000
		}, {
			"label": "旬阳县",
			"value": 610928000000
		}, {
			"label": "白河县",
			"value": 610929000000
		}]
	}, {
		"label": "商洛市",
		"value": 611000000000,
		"children": [{
			"label": "商州区",
			"value": 611002000000
		}, {
			"label": "洛南县",
			"value": 611021000000
		}, {
			"label": "丹凤县",
			"value": 611022000000
		}, {
			"label": "商南县",
			"value": 611023000000
		}, {
			"label": "山阳县",
			"value": 611024000000
		}, {
			"label": "镇安县",
			"value": 611025000000
		}, {
			"label": "柞水县",
			"value": 611026000000
		}]
	}]
}, {
	"label": "甘肃省",
	"value": 62,
	"children": [{
		"label": "兰州市",
		"value": 620100000000,
		"children": [{
			"label": "城关区",
			"value": 620102000000
		}, {
			"label": "七里河区",
			"value": 620103000000
		}, {
			"label": "西固区",
			"value": 620104000000
		}, {
			"label": "安宁区",
			"value": 620105000000
		}, {
			"label": "红古区",
			"value": 620111000000
		}, {
			"label": "永登县",
			"value": 620121000000
		}, {
			"label": "皋兰县",
			"value": 620122000000
		}, {
			"label": "榆中县",
			"value": 620123000000
		}]
	}, {
		"label": "嘉峪关市",
		"value": 620200000000,
		"children": [{
			"label": "市辖区",
			"value": 620201000000
		}]
	}, {
		"label": "金昌市",
		"value": 620300000000,
		"children": [{
			"label": "金川区",
			"value": 620302000000
		}, {
			"label": "永昌县",
			"value": 620321000000
		}]
	}, {
		"label": "白银市",
		"value": 620400000000,
		"children": [{
			"label": "白银区",
			"value": 620402000000
		}, {
			"label": "平川区",
			"value": 620403000000
		}, {
			"label": "靖远县",
			"value": 620421000000
		}, {
			"label": "会宁县",
			"value": 620422000000
		}, {
			"label": "景泰县",
			"value": 620423000000
		}]
	}, {
		"label": "天水市",
		"value": 620500000000,
		"children": [{
			"label": "秦州区",
			"value": 620502000000
		}, {
			"label": "麦积区",
			"value": 620503000000
		}, {
			"label": "清水县",
			"value": 620521000000
		}, {
			"label": "秦安县",
			"value": 620522000000
		}, {
			"label": "甘谷县",
			"value": 620523000000
		}, {
			"label": "武山县",
			"value": 620524000000
		}, {
			"label": "张家川回族自治县",
			"value": 620525000000
		}]
	}, {
		"label": "武威市",
		"value": 620600000000,
		"children": [{
			"label": "凉州区",
			"value": 620602000000
		}, {
			"label": "民勤县",
			"value": 620621000000
		}, {
			"label": "古浪县",
			"value": 620622000000
		}, {
			"label": "天祝藏族自治县",
			"value": 620623000000
		}]
	}, {
		"label": "张掖市",
		"value": 620700000000,
		"children": [{
			"label": "甘州区",
			"value": 620702000000
		}, {
			"label": "肃南裕固族自治县",
			"value": 620721000000
		}, {
			"label": "民乐县",
			"value": 620722000000
		}, {
			"label": "临泽县",
			"value": 620723000000
		}, {
			"label": "高台县",
			"value": 620724000000
		}, {
			"label": "山丹县",
			"value": 620725000000
		}]
	}, {
		"label": "平凉市",
		"value": 620800000000,
		"children": [{
			"label": "崆峒区",
			"value": 620802000000
		}, {
			"label": "泾川县",
			"value": 620821000000
		}, {
			"label": "灵台县",
			"value": 620822000000
		}, {
			"label": "崇信县",
			"value": 620823000000
		}, {
			"label": "华亭县",
			"value": 620824000000
		}, {
			"label": "庄浪县",
			"value": 620825000000
		}, {
			"label": "静宁县",
			"value": 620826000000
		}]
	}, {
		"label": "酒泉市",
		"value": 620900000000,
		"children": [{
			"label": "肃州区",
			"value": 620902000000
		}, {
			"label": "金塔县",
			"value": 620921000000
		}, {
			"label": "瓜州县",
			"value": 620922000000
		}, {
			"label": "肃北蒙古族自治县",
			"value": 620923000000
		}, {
			"label": "阿克塞哈萨克族自治县",
			"value": 620924000000
		}, {
			"label": "玉门市",
			"value": 620981000000
		}, {
			"label": "敦煌市",
			"value": 620982000000
		}]
	}, {
		"label": "庆阳市",
		"value": 621000000000,
		"children": [{
			"label": "西峰区",
			"value": 621002000000
		}, {
			"label": "庆城县",
			"value": 621021000000
		}, {
			"label": "环县",
			"value": 621022000000
		}, {
			"label": "华池县",
			"value": 621023000000
		}, {
			"label": "合水县",
			"value": 621024000000
		}, {
			"label": "正宁县",
			"value": 621025000000
		}, {
			"label": "宁县",
			"value": 621026000000
		}, {
			"label": "镇原县",
			"value": 621027000000
		}]
	}, {
		"label": "定西市",
		"value": 621100000000,
		"children": [{
			"label": "安定区",
			"value": 621102000000
		}, {
			"label": "通渭县",
			"value": 621121000000
		}, {
			"label": "陇西县",
			"value": 621122000000
		}, {
			"label": "渭源县",
			"value": 621123000000
		}, {
			"label": "临洮县",
			"value": 621124000000
		}, {
			"label": "漳县",
			"value": 621125000000
		}, {
			"label": "岷县",
			"value": 621126000000
		}]
	}, {
		"label": "陇南市",
		"value": 621200000000,
		"children": [{
			"label": "武都区",
			"value": 621202000000
		}, {
			"label": "成县",
			"value": 621221000000
		}, {
			"label": "文县",
			"value": 621222000000
		}, {
			"label": "宕昌县",
			"value": 621223000000
		}, {
			"label": "康县",
			"value": 621224000000
		}, {
			"label": "西和县",
			"value": 621225000000
		}, {
			"label": "礼县",
			"value": 621226000000
		}, {
			"label": "徽县",
			"value": 621227000000
		}, {
			"label": "两当县",
			"value": 621228000000
		}]
	}, {
		"label": "临夏回族自治州",
		"value": 622900000000,
		"children": [{
			"label": "临夏市",
			"value": 622901000000
		}, {
			"label": "临夏县",
			"value": 622921000000
		}, {
			"label": "康乐县",
			"value": 622922000000
		}, {
			"label": "永靖县",
			"value": 622923000000
		}, {
			"label": "广河县",
			"value": 622924000000
		}, {
			"label": "和政县",
			"value": 622925000000
		}, {
			"label": "东乡族自治县",
			"value": 622926000000
		}, {
			"label": "积石山保安族东乡族撒拉族自治县",
			"value": 622927000000
		}]
	}, {
		"label": "甘南藏族自治州",
		"value": 623000000000,
		"children": [{
			"label": "合作市",
			"value": 623001000000
		}, {
			"label": "临潭县",
			"value": 623021000000
		}, {
			"label": "卓尼县",
			"value": 623022000000
		}, {
			"label": "舟曲县",
			"value": 623023000000
		}, {
			"label": "迭部县",
			"value": 623024000000
		}, {
			"label": "玛曲县",
			"value": 623025000000
		}, {
			"label": "碌曲县",
			"value": 623026000000
		}, {
			"label": "夏河县",
			"value": 623027000000
		}]
	}]
}, {
	"label": "青海省",
	"value": 63,
	"children": [{
		"label": "西宁市",
		"value": 630100000000,
		"children": [{
			"label": "城东区",
			"value": 630102000000
		}, {
			"label": "城中区",
			"value": 630103000000
		}, {
			"label": "城西区",
			"value": 630104000000
		}, {
			"label": "城北区",
			"value": 630105000000
		}, {
			"label": "大通回族土族自治县",
			"value": 630121000000
		}, {
			"label": "湟中县",
			"value": 630122000000
		}, {
			"label": "湟源县",
			"value": 630123000000
		}]
	}, {
		"label": "海东市",
		"value": 630200000000,
		"children": [{
			"label": "乐都区",
			"value": 630202000000
		}, {
			"label": "平安区",
			"value": 630203000000
		}, {
			"label": "民和回族土族自治县",
			"value": 630222000000
		}, {
			"label": "互助土族自治县",
			"value": 630223000000
		}, {
			"label": "化隆回族自治县",
			"value": 630224000000
		}, {
			"label": "循化撒拉族自治县",
			"value": 630225000000
		}]
	}, {
		"label": "海北藏族自治州",
		"value": 632200000000,
		"children": [{
			"label": "门源回族自治县",
			"value": 632221000000
		}, {
			"label": "祁连县",
			"value": 632222000000
		}, {
			"label": "海晏县",
			"value": 632223000000
		}, {
			"label": "刚察县",
			"value": 632224000000
		}]
	}, {
		"label": "黄南藏族自治州",
		"value": 632300000000,
		"children": [{
			"label": "同仁县",
			"value": 632321000000
		}, {
			"label": "尖扎县",
			"value": 632322000000
		}, {
			"label": "泽库县",
			"value": 632323000000
		}, {
			"label": "河南蒙古族自治县",
			"value": 632324000000
		}]
	}, {
		"label": "海南藏族自治州",
		"value": 632500000000,
		"children": [{
			"label": "共和县",
			"value": 632521000000
		}, {
			"label": "同德县",
			"value": 632522000000
		}, {
			"label": "贵德县",
			"value": 632523000000
		}, {
			"label": "兴海县",
			"value": 632524000000
		}, {
			"label": "贵南县",
			"value": 632525000000
		}]
	}, {
		"label": "果洛藏族自治州",
		"value": 632600000000,
		"children": [{
			"label": "玛沁县",
			"value": 632621000000
		}, {
			"label": "班玛县",
			"value": 632622000000
		}, {
			"label": "甘德县",
			"value": 632623000000
		}, {
			"label": "达日县",
			"value": 632624000000
		}, {
			"label": "久治县",
			"value": 632625000000
		}, {
			"label": "玛多县",
			"value": 632626000000
		}]
	}, {
		"label": "玉树藏族自治州",
		"value": 632700000000,
		"children": [{
			"label": "玉树市",
			"value": 632701000000
		}, {
			"label": "杂多县",
			"value": 632722000000
		}, {
			"label": "称多县",
			"value": 632723000000
		}, {
			"label": "治多县",
			"value": 632724000000
		}, {
			"label": "囊谦县",
			"value": 632725000000
		}, {
			"label": "曲麻莱县",
			"value": 632726000000
		}]
	}, {
		"label": "海西蒙古族藏族自治州",
		"value": 632800000000,
		"children": [{
			"label": "格尔木市",
			"value": 632801000000
		}, {
			"label": "德令哈市",
			"value": 632802000000
		}, {
			"label": "乌兰县",
			"value": 632821000000
		}, {
			"label": "都兰县",
			"value": 632822000000
		}, {
			"label": "天峻县",
			"value": 632823000000
		}, {
			"label": "大柴旦行政委员会",
			"value": 632857000000
		}, {
			"label": "冷湖行政委员会",
			"value": 632858000000
		}, {
			"label": "茫崖行政委员会",
			"value": 632859000000
		}]
	}]
}, {
	"label": "宁夏回族自治区",
	"value": 64,
	"children": [{
		"label": "银川市",
		"value": 640100000000,
		"children": [{
			"label": "兴庆区",
			"value": 640104000000
		}, {
			"label": "西夏区",
			"value": 640105000000
		}, {
			"label": "金凤区",
			"value": 640106000000
		}, {
			"label": "永宁县",
			"value": 640121000000
		}, {
			"label": "贺兰县",
			"value": 640122000000
		}, {
			"label": "灵武市",
			"value": 640181000000
		}]
	}, {
		"label": "石嘴山市",
		"value": 640200000000,
		"children": [{
			"label": "大武口区",
			"value": 640202000000
		}, {
			"label": "惠农区",
			"value": 640205000000
		}, {
			"label": "平罗县",
			"value": 640221000000
		}]
	}, {
		"label": "吴忠市",
		"value": 640300000000,
		"children": [{
			"label": "利通区",
			"value": 640302000000
		}, {
			"label": "红寺堡区",
			"value": 640303000000
		}, {
			"label": "盐池县",
			"value": 640323000000
		}, {
			"label": "同心县",
			"value": 640324000000
		}, {
			"label": "青铜峡市",
			"value": 640381000000
		}]
	}, {
		"label": "固原市",
		"value": 640400000000,
		"children": [{
			"label": "原州区",
			"value": 640402000000
		}, {
			"label": "西吉县",
			"value": 640422000000
		}, {
			"label": "隆德县",
			"value": 640423000000
		}, {
			"label": "泾源县",
			"value": 640424000000
		}, {
			"label": "彭阳县",
			"value": 640425000000
		}]
	}, {
		"label": "中卫市",
		"value": 640500000000,
		"children": [{
			"label": "沙坡头区",
			"value": 640502000000
		}, {
			"label": "中宁县",
			"value": 640521000000
		}, {
			"label": "海原县",
			"value": 640522000000
		}]
	}]
}, {
	"label": "新疆维吾尔自治区",
	"value": 65,
	"children": [{
		"label": "乌鲁木齐市",
		"value": 650100000000,
		"children": [{
			"label": "天山区",
			"value": 650102000000
		}, {
			"label": "沙依巴克区",
			"value": 650103000000
		}, {
			"label": "新市区",
			"value": 650104000000
		}, {
			"label": "水磨沟区",
			"value": 650105000000
		}, {
			"label": "头屯河区",
			"value": 650106000000
		}, {
			"label": "达坂城区",
			"value": 650107000000
		}, {
			"label": "米东区",
			"value": 650109000000
		}, {
			"label": "乌鲁木齐县",
			"value": 650121000000
		}]
	}, {
		"label": "克拉玛依市",
		"value": 650200000000,
		"children": [{
			"label": "独山子区",
			"value": 650202000000
		}, {
			"label": "克拉玛依区",
			"value": 650203000000
		}, {
			"label": "白碱滩区",
			"value": 650204000000
		}, {
			"label": "乌尔禾区",
			"value": 650205000000
		}]
	}, {
		"label": "吐鲁番市",
		"value": 650400000000,
		"children": [{
			"label": "高昌区",
			"value": 650402000000
		}, {
			"label": "鄯善县",
			"value": 650421000000
		}, {
			"label": "托克逊县",
			"value": 650422000000
		}]
	}, {
		"label": "哈密地区",
		"value": 652200000000,
		"children": [{
			"label": "哈密市",
			"value": 652201000000
		}, {
			"label": "巴里坤哈萨克自治县",
			"value": 652222000000
		}, {
			"label": "伊吾县",
			"value": 652223000000
		}]
	}, {
		"label": "昌吉回族自治州",
		"value": 652300000000,
		"children": [{
			"label": "昌吉市",
			"value": 652301000000
		}, {
			"label": "阜康市",
			"value": 652302000000
		}, {
			"label": "呼图壁县",
			"value": 652323000000
		}, {
			"label": "玛纳斯县",
			"value": 652324000000
		}, {
			"label": "奇台县",
			"value": 652325000000
		}, {
			"label": "吉木萨尔县",
			"value": 652327000000
		}, {
			"label": "木垒哈萨克自治县",
			"value": 652328000000
		}]
	}, {
		"label": "博尔塔拉蒙古自治州",
		"value": 652700000000,
		"children": [{
			"label": "博乐市",
			"value": 652701000000
		}, {
			"label": "精河县",
			"value": 652722000000
		}, {
			"label": "温泉县",
			"value": 652723000000
		}]
	}, {
		"label": "巴音郭楞蒙古自治州",
		"value": 652800000000,
		"children": [{
			"label": "库尔勒市",
			"value": 652801000000
		}, {
			"label": "轮台县",
			"value": 652822000000
		}, {
			"label": "尉犁县",
			"value": 652823000000
		}, {
			"label": "若羌县",
			"value": 652824000000
		}, {
			"label": "且末县",
			"value": 652825000000
		}, {
			"label": "焉耆回族自治县",
			"value": 652826000000
		}, {
			"label": "和静县",
			"value": 652827000000
		}, {
			"label": "和硕县",
			"value": 652828000000
		}, {
			"label": "博湖县",
			"value": 652829000000
		}]
	}, {
		"label": "阿克苏地区",
		"value": 652900000000,
		"children": [{
			"label": "阿克苏市",
			"value": 652901000000
		}, {
			"label": "温宿县",
			"value": 652922000000
		}, {
			"label": "库车县",
			"value": 652923000000
		}, {
			"label": "沙雅县",
			"value": 652924000000
		}, {
			"label": "新和县",
			"value": 652925000000
		}, {
			"label": "拜城县",
			"value": 652926000000
		}, {
			"label": "乌什县",
			"value": 652927000000
		}, {
			"label": "阿瓦提县",
			"value": 652928000000
		}, {
			"label": "柯坪县",
			"value": 652929000000
		}]
	}, {
		"label": "克孜勒苏柯尔克孜自治州",
		"value": 653000000000,
		"children": [{
			"label": "阿图什市",
			"value": 653001000000
		}, {
			"label": "阿克陶县",
			"value": 653022000000
		}, {
			"label": "阿合奇县",
			"value": 653023000000
		}, {
			"label": "乌恰县",
			"value": 653024000000
		}]
	}, {
		"label": "喀什地区",
		"value": 653100000000,
		"children": [{
			"label": "喀什市",
			"value": 653101000000
		}, {
			"label": "疏附县",
			"value": 653121000000
		}, {
			"label": "疏勒县",
			"value": 653122000000
		}, {
			"label": "英吉沙县",
			"value": 653123000000
		}, {
			"label": "泽普县",
			"value": 653124000000
		}, {
			"label": "莎车县",
			"value": 653125000000
		}, {
			"label": "叶城县",
			"value": 653126000000
		}, {
			"label": "麦盖提县",
			"value": 653127000000
		}, {
			"label": "岳普湖县",
			"value": 653128000000
		}, {
			"label": "伽师县",
			"value": 653129000000
		}, {
			"label": "巴楚县",
			"value": 653130000000
		}, {
			"label": "塔什库尔干塔吉克自治县",
			"value": 653131000000
		}]
	}, {
		"label": "和田地区",
		"value": 653200000000,
		"children": [{
			"label": "和田市",
			"value": 653201000000
		}, {
			"label": "和田县",
			"value": 653221000000
		}, {
			"label": "墨玉县",
			"value": 653222000000
		}, {
			"label": "皮山县",
			"value": 653223000000
		}, {
			"label": "洛浦县",
			"value": 653224000000
		}, {
			"label": "策勒县",
			"value": 653225000000
		}, {
			"label": "于田县",
			"value": 653226000000
		}, {
			"label": "民丰县",
			"value": 653227000000
		}]
	}, {
		"label": "伊犁哈萨克自治州",
		"value": 654000000000,
		"children": [{
			"label": "伊宁市",
			"value": 654002000000
		}, {
			"label": "奎屯市",
			"value": 654003000000
		}, {
			"label": "霍尔果斯市",
			"value": 654004000000
		}, {
			"label": "伊宁县",
			"value": 654021000000
		}, {
			"label": "察布查尔锡伯自治县",
			"value": 654022000000
		}, {
			"label": "霍城县",
			"value": 654023000000
		}, {
			"label": "巩留县",
			"value": 654024000000
		}, {
			"label": "新源县",
			"value": 654025000000
		}, {
			"label": "昭苏县",
			"value": 654026000000
		}, {
			"label": "特克斯县",
			"value": 654027000000
		}, {
			"label": "尼勒克县",
			"value": 654028000000
		}]
	}, {
		"label": "塔城地区",
		"value": 654200000000,
		"children": [{
			"label": "塔城市",
			"value": 654201000000
		}, {
			"label": "乌苏市",
			"value": 654202000000
		}, {
			"label": "额敏县",
			"value": 654221000000
		}, {
			"label": "沙湾县",
			"value": 654223000000
		}, {
			"label": "托里县",
			"value": 654224000000
		}, {
			"label": "裕民县",
			"value": 654225000000
		}, {
			"label": "和布克赛尔蒙古自治县",
			"value": 654226000000
		}]
	}, {
		"label": "阿勒泰地区",
		"value": 654300000000,
		"children": [{
			"label": "阿勒泰市",
			"value": 654301000000
		}, {
			"label": "布尔津县",
			"value": 654321000000
		}, {
			"label": "富蕴县",
			"value": 654322000000
		}, {
			"label": "福海县",
			"value": 654323000000
		}, {
			"label": "哈巴河县",
			"value": 654324000000
		}, {
			"label": "青河县",
			"value": 654325000000
		}, {
			"label": "吉木乃县",
			"value": 654326000000
		}]
	}, {
		"label": "自治区直辖县级行政区划",
		"value": 659000000000,
		"children": [{
			"label": "石河子市",
			"value": 659001000000
		}, {
			"label": "阿拉尔市",
			"value": 659002000000
		}, {
			"label": "图木舒克市",
			"value": 659003000000
		}, {
			"label": "五家渠市",
			"value": 659004000000
		}]
	}]
}]

module.exports = area_values