<template>
  <div class="mainbs">
    <div id="container">
      <Header />
    </div>

    <Banner />

    <div >
      <div class="nav" id="container" >
        <router-link  @click.native="doScroll1" :to="{ path: '/mainbs/mainbscms' }"  > 普惠金融板块</router-link>
        <router-link @click.native="doScroll2" :to="{ path: '/mainbs/mainbsinfo' }" >信用信息板块</router-link>
        <router-link @click.native="doScroll3" :to="{ path: '/mainbs/mainbszd' }" >乡村振兴板块</router-link>
        <router-link @click.native="doScroll4" :to="{ path: '/mainbs/mainbshnl' }" >便民生活服务板块</router-link>
        <!-- <router-link :to="{path:'/mainbs/mainbscredit'}">征信产品</router-link> -->
    </div>
    <div class="show" id="aaa">
      <router-view ></router-view>
    </div> 
</div>
      <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Mainbs",
  data() {
    return {
      mainbsBannerUrl: "",
    };
  },
  components: {
    Header,
    Footer,
    Sidebar,
    Banner,
  },
  created() { },
  methods:{
    
    doScroll1(){
      this.$el.querySelector('#aaa').scrollIntoView();
      
    },
    doScroll2(){
      this.$el.querySelector('#aaa').scrollIntoView();
        // window.scrollTo({
        //   top:,
        //   left:0,
        //   behavior: "smooth"
        // })
    },
  
  doScroll3(){
     
       this.$el.querySelector('#aaa').scrollIntoView();
    },
  
  doScroll4(){
      
       this.$el.querySelector('#aaa').scrollIntoView();
    }
  
}
}
</script>

<style scoped>
.mainbs .banner {
  width: 100%;
  height: auto;
  /* height: 25vw; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
}

.nav {
  box-sizing: border-box;
  width: 74.94rem;
  margin-top: 2.06rem;
  padding: 0.69rem 0 0.69rem 2.44rem;
  border-bottom: 1px solid #e6e6e6;
  position: sticky;
  top: 0;
  background-color:white ;
  z-index: 999;
}

.nav a {
  margin-right: 3.94rem;
  color: #969696;
  font-size: 1rem;
  position: relative;
}

.nav a.router-link-active {
  font-size: 1.5rem;
  color: #000000;
}

.nav a.router-link-active::after {
  position: absolute;
  left: 50%;
  margin-left: -2.19rem;
  bottom: -0.69rem;
  content: "";
  display: block;
  width: 4.38rem;
  height: 0.19rem;
  background: #1564db;
}
</style>