<template>
  <div class="home">
    <Header />

    <div class="video-wrap">
      <video
        ref="videoRef"
        autoplay
        controls
        muted
        @play="monitorVideoPlay"
        @pause="monitorVideoPause"
        loop
        src="../assets/img/home/video_30s.mp4"
        class="video-item"
      ></video>
      <!-- <img src="@/assets/img/home/shipin_bg.png" style="width:100%" alt=""> -->
      <div class="play-wrap cursor-pointer center" @click="videoPlay">
        <div class="csc" v-show="showPlay">
          <img
            src="@/assets/img/home/pht_video_tip.png"
            style="margin-bottom: 8.81rem;"
            alt="普及金融 惠及民生"
          />

          <!-- <div style="font-size: 2.5rem; margin-bottom: 8.81rem;font-weight: 500;color: #FFFFFF;line-height: 2.63rem;">普及金融 惠及民生</div> -->
          <img
            src="@/assets/img/home/shipin.png"
            style="width:4rem;height:4rem"
            alt="play_btn"
          />
        </div>
      </div>
    </div>

    <div class="tags-wrap ">
      <div class="rac" style="height:100%">
        <div class="tags-item-wrap r">
          <img
            src="@/assets/img/home/tag_1.png"
            style="width:2.95rem;height: 2.36rem;"
            alt=""
          />
          <div class="text-wrap">
            <div class="title-wrap">专业务实</div>
            <div class="desc-wrap">
              专业化的产品和服务，精准解决客户差异化问题
            </div>
          </div>
        </div>
        <div class="tags-item-wrap r">
          <img
            src="@/assets/img/home/tag_2.png"
            style="width:3.46rem;height:2.72rem;"
            alt=""
          />
          <div class="text-wrap">
            <div class="title-wrap">方便快捷</div>
            <div class="desc-wrap">
              一次性提供多种服务，让您办事省时、省事、省心
            </div>
          </div>
        </div>
        <div class="tags-item-wrap r">
          <img
            src="@/assets/img/home/tag_3.png"
            style="width:2.88rem;height: 2.63rem;"
            alt=""
          />
          <div class="text-wrap">
            <div class="title-wrap">优质高效</div>
            <div class="desc-wrap">
              用更有广度、更有精度的服务，为客户创造价值
            </div>
          </div>
        </div>
      </div>
      <img
        class="img-bg"
        src="@/assets/img/home/bg.png"
        style="width:100%;height: 100%;"
        alt=""
      />
    </div>

    <div class="pht-intro-wrap w1200 rsc">
      <div class="left-wrap">
        <div class="title">
          普惠通简介
        </div>
        <div class="desc-wrap">
          <p>
            河南省兰考县是我国县域经济的典型代表。习近平总书记前往调研时，要求兰考要准确把握县域治理特点和规律，在县域改革中走出一条好路子。
          </p>

          <p>
            为有效破解传统金融服务不足、服务效率低、融资难、信用缺失等突出问题。2016年8月，人民银行郑州中心支行积极探索，开发上线了微信公众号形式的“普惠金融一网通”移动金融服务平台，涵盖支付服务、便民查询、金融宣传三大类金融服务，在贯彻落实国务院普惠金融发展规划的同时，提升金融服务覆盖率、可得性和满意度。
          </p>

          <p>
            2016年12月，兰考获批成为全国首个国家级普惠金融改革试验区。随着试验区建设的深化和金融服务需求的不断增多，河南省在充分调研论证的前提下，决定引入第三方市场主体建设运营“普惠金融一网通”移动金融服务平台。
            经过多方精心筹备，2017年6月，普惠通科技（河南）股份有限公司注册成立，被中国人民银行郑州中心支行确定为“普惠金融一网通”建设、运营方。通过几个月的精心打磨，同年10月，兰考普惠金融综合服务平台华丽转身，顺利从“普惠金融一网通”微信公众号升级为“普惠通”APP。
          </p>
        </div>
        <div class="more-btn rcc" @click="$router.push('/contact')">
          了解更多
          <img
            src="@/assets/img/home/r_arr.png"
            style="margin-left:1rem;"
            alt=""
          />
        </div>
        <div class="labels-wrap rsc" ref="countWrap">
          <div class="label-item">
            <div class="num-wrap">
              <count-to
                :startVal="0"
                :autoplay="false"
                ref="count1"
                :endVal="7"
                :duration="2000"
              ></count-to
              >+
            </div>
            <div class="intro-wrap">
              7年累计经验
            </div>
          </div>
          <div class="lineV"></div>
          <div class="label-item">
            <div class="num-wrap">
              <count-to
                :startVal="0"
                :autoplay="false"
                ref="count2"
                :endVal="200"
                :duration="2500"
              ></count-to
              >+
            </div>
            <div class="intro-wrap">
              200+精英团队
            </div>
          </div>
          <div class="lineV"></div>
          <div class="label-item">
            <div class="num-wrap">
              <count-to
                :startVal="0"
                :endVal="400"
                :autoplay="false"
                ref="count3"
                :duration="2500"
              ></count-to
              >+
            </div>
            <div class="intro-wrap">
              400+合作金融机构
            </div>
          </div>
          <div class="lineV"></div>
          <div class="label-item">
            <div class="num-wrap">
              <count-to
                :startVal="0"
                :endVal="1200"
                :duration="2500"
                :autoplay="false"
                ref="count4"
                separator=""
              ></count-to
              >万+
            </div>
            <div class="intro-wrap">
              普惠通APP累计下载
            </div>
          </div>
        </div>
      </div>
      <div class="right-wrap">
        <img src="@/assets/img/home/jianjie.png" alt="" />
      </div>
    </div>

    <div class="product-news-wrap">
      <div class="product-wrap w1200 rsc">
        <div class="prod-item p1" @click="$router.push('/mainprod')">
          <div class="title-w rc ">
            <div class="text">
              金融科技
            </div>
            <div class="img-w">
              <img src="@/assets/img/home/circle_arrow.png" alt="" />
            </div>
          </div>
          <div class="desc-w">
            加大研发投入，将数字元素注入金融服务全流程，将数字思维贯穿业务运营全链条，注重金融创新的科技驱动和数据赋能。
          </div>
        </div>
        <div class="prod-item csc">
          <div class="item-w img-bg1" @click="$router.push('/mainserv')">
            <div class="content-w csc">
              <img
                src="@/assets/img/home/jinrong.png"
                style="width: 2.76rem;height: 2.76rem;"
                alt=""
              />
              <div class="text-w">金融服务</div>
            </div>
          </div>

          <div class="item-w img-bg2" @click="$router.push('/ruralrevital')">
            <div class="content-w csc">
              <img
                src="@/assets/img/home/xiangcun.png"
                style="width: 2.76rem;height: 2.76rem;"
                alt=""
              />
              <div class="text-w">乡村振兴</div>
            </div>
          </div>
        </div>
        <div class="prod-item csc">
          <div class="item-w img-bg3" @click="$router.push('/mainprod/qyzx')">
            <div class="content-w csc">
              <img
                src="@/assets/img/home/xinyong.png"
                style="width: 2.76rem;height: 2.76rem;"
                alt=""
              />
              <div class="text-w">信用信息</div>
            </div>
          </div>

          <div class="item-w img-bg4" @click="$router.push('/mainserv/4')">
            <div class="content-w csc">
              <img
                src="@/assets/img/home/bianmin.png"
                style="width: 2.76rem;height: 2.76rem;"
                alt=""
              />
              <div class="text-w">电商服务</div>
            </div>
          </div>
        </div>
      </div>

      <div class="news-wrap w1200">
        <div class="title-wrap">洞察未来，探索行业发展新趋势</div>

        <div class="news-list rc">
          <div
            class="news-item"
            v-for="item in showNewsList"
            :key="item.id"
            @click="$router.push(`/article/${item.id}`)"
          >
            <img
              :src="item.thumb_image"
              style="width:23.75rem;height:12.04rem;border-radius: 0.13rem;"
              alt=""
            />
            <div class="news-desc rs">
              <div class="date-wrap">
                <div class="day-wrap">{{ getDay(item.created_at) }}</div>
                <div class="month-wrap">{{ getMonthUS(item.created_at) }}</div>
              </div>
              <div class="news-w">
                <div class="news-title oneLine">{{ item.title }}</div>
                <div class="news-content threeLine">
                  {{ item.seo_description }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="news-more-wrap rsc">
          <div class="dots-wrap rsc">
            <!-- 从1开始，不包含0 -->
            <div
              v-for="num in dotNum"
              :key="num"
              class="dot cursor-pointer "
              :class="showDotIndex == num - 1 ? 'active' : ''"
              @click="handleDot(num)"
            ></div>
          </div>
          <div class="img-wrap cursor-pointer">
            <router-link to="/policy"
              ><img src="../assets/img/home/gengduo.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="partner-wrap w1200">
      <div class="title-wrap">专业可靠的合作伙伴</div>
      <div
        class="showview-wrap"
        ref="showviewRef"
        :style="{
          '--scrollWidth': -scrollWidth + 'px',
          '--scrollWidth2': -scrollWidth2 + 'px'
        }"
      >
        <div class="banner-list-wrap  r" ref="bannerListRef">
          <div
            class="banner-item-wrap"
            v-for="item in cooperatorList1"
            :key="item.id"
          >
            <a
              :href="
                item.action_uri == 'http://'
                  ? 'javascript:void(0)'
                  : item.action_uri
              "
              target="_blank"
            >
              <img :src="item.img" :alt="item.title" />
            </a>
          </div>
          <div
            class="banner-item-wrap"
            v-for="item in cooperatorList1"
            :key="item.id + '2'"
          >
            <a
              :href="
                item.action_uri == 'http://'
                  ? 'javascript:void(0)'
                  : item.action_uri
              "
              target="_blank"
            >
              <img :src="item.img" :alt="item.title" />
            </a>
          </div>
        </div>
        <!-- 第二行 反向排序数组 -->
        <div class="banner-list-wrap-reverse  r" ref="bannerListRef2">
          <div
            class="banner-item-wrap"
            v-for="item in cooperatorList2"
            :key="item.id"
          >
            <a
              :href="
                item.action_uri == 'http://'
                  ? 'javascript:void(0)'
                  : item.action_uri
              "
              target="_blank"
            >
              <img :src="item.img" :alt="item.title" />
            </a>
          </div>
          <div
            class="banner-item-wrap"
            v-for="item in cooperatorList2"
            :key="item.id + '2'"
          >
            <a
              :href="
                item.action_uri == 'http://'
                  ? 'javascript:void(0)'
                  : item.action_uri
              "
              target="_blank"
            >
              <img :src="item.img" :alt="item.title" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <Sidebar v-show="scrollTop > 500" />
    <Footer />
    <!-- <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style="display: none"
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&referer=myapp"
    ></iframe> -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
// import area_ids from '@/utils/AreaID.js'
import { setSpot } from "@/utils/tools.js";
import countTo from "vue-count-to";
export default {
  name: "Home",

  data() {
    return {
      scrollTop: 0, //侧边滚动条到头部高度，控制Sidebar不遮挡视频
      showPlay: true, //播放按钮展示
      policyArticleList: [],
      showDotIndex: 0, //默认展示的下标
      newsNumPerList: 3, //每次展示3条
      showNewsList: [], //展示的新闻列表，一次3个，从policyArticleList中取值
      cooperatorList: [],
      cooperatorList1: [], //全部的一半前半部
      cooperatorList2: [], //后半部
      cooperatorReverseList: [],
      scrollWidth: 0, //底部跑马灯滚动宽度
      scrollWidth2: 0 //第二排
      // timer:null,
    };
  },
  computed: {
    dotNum() {
      if (this.policyArticleList.length == 0) {
        return 0;
      }
      let num =
        this.policyArticleList.length % this.newsNumPerList == 0
          ? this.policyArticleList.length / this.newsNumPerList
          : parseInt(this.policyArticleList.length / this.newsNumPerList + 1);
      return num;
    }
  },
  components: {
    countTo,
    Header,
    Footer,
    Sidebar
  },
  async created() {
    setSpot("官网首页");
  },
  mounted() {
    this.Init();
    //监听并处理函数
    window.addEventListener("scroll", this.handleScroll);

    this.observerCount();
  },
  beforeDestroy() {},
  methods: {
    handleScroll(e) {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      this.scrollTop = scrollTop;
    },

    observerCount() {
      let _this = this;
      let el = this.$refs.countWrap;
      // 判断是否可以使用
      if ("IntersectionObserver" in window) {
        // IntersectionObserver对象，浏览器原生提供的构造函数
        let observer = new IntersectionObserver(entries => {
          // intersectionRatio: 目标元素的可见比例，即intersectionRect占boundingClientRect的比例，完全可见时为1，完全不可见时小于等于0
          // 是否交叉
          const isIntersected = entries.some(
            entry => entry.intersectionRatio > 0
          );
          if (isIntersected) {
            // 执行绑定的事件
            _this.startCount();

            // 停止观察
            // observer.unobserve(el);

            // 关闭观察器
            // observer.disconnect();
          } else {
            _this.resetCount();
          }
        });

        // 开始观察， el: DOM对象
        observer.observe(el);
      } else {
        _this.startCount();
      }
    },
    resetCount() {
      if (this.$refs.count1) {
        this.$refs.count1.reset();
        this.$refs.count2.reset();
        this.$refs.count3.reset();
        this.$refs.count4.reset();
      }
    },
    startCount() {
      this.$refs.count1.start();
      this.$refs.count2.start();
      this.$refs.count3.start();
      this.$refs.count4.start();
    },
    videoPlay() {
      let video = this.$refs.videoRef;
      if (video.paused || video.ended) {
        video.play();
        this.showPlay = false;
      } else {
        video.pause();
        this.showPlay = true;
      }
    },
    // videoEnd(){
    //   let video = this.$refs.videoRef
    //   video.currentTime = 0;
    //   this.showPlay=true
    // },
    monitorVideoPlay() {
      this.showPlay = false;
    },
    monitorVideoPause() {
      this.showPlay = true;
    },
    Init() {
      this.initPolicyArticleList();
      this.initWebHomeCooperative();
    },
    /*** root是要监听的根元素，我们要对root节点内的img元素进行监听* 这里用到Promise.all*/
    async onAllImgLoaded(root) {
      // console.log(root);
      const imgNodes = !root
        ? []
        : root instanceof HTMLImageElement
        ? [root]
        : root.querySelectorAll("img");
      // console.log(imgNodes);
      //为了使用Array的map方法
      let imgArr = Array.prototype.slice.call(imgNodes);
      return await Promise.all(
        imgArr.map(img => {
          return new Promise(resolve => {
            img.addEventListener("load", () => resolve(img));
          });
        })
      );
    },
    async initPolicyArticleList() {
      const policyArticleList = await this.$get(
        "/operation-management/api/articles?category_key=hot&paginate=6"
      );
      this.policyArticleList = policyArticleList.data;
      this.handleDot(1); //默认取第一个
    },

    async initWebHomeCooperative() {
      const cooperatorList = await this.$get(
        "/operation-management/api/banner?plate=cooperative"
      );
      this.cooperatorList = cooperatorList;
      let halflen = (cooperatorList.length >> 1) + 1;
      this.cooperatorList2.push(...cooperatorList.slice(halflen));
      this.cooperatorList1.push(...cooperatorList.slice(0, halflen));
      // let arr = [...cooperatorList].reverse()
      // this.cooperatorReverseList = [...arr]

      // 初始化样式后获取宽高属性
      this.$nextTick(() => {
        // console.log(this.$refs.bannerListRef2.clientWidth)
        if(this.$refs.bannerListRef){
          this.onAllImgLoaded(this.$refs.bannerListRef).then(datas => {
            // console.log(datas)
            // console.log(this.$refs.bannerListRef.scrollWidth);
            this.scrollWidth = this.$refs.bannerListRef.scrollWidth / 2;
            // this.scrollWidth2 = this.$refs.bannerListRef2.scrollWidth / 2
          });
        }
        if(this.$refs.bannerListRef2){
          this.onAllImgLoaded(this.$refs.bannerListRef2).then(datas => {
            // this.scrollWidth = this.$refs.bannerListRef.scrollWidth / 2
            this.scrollWidth2 = this.$refs.bannerListRef2.scrollWidth / 2;
          });
        }
        // let _this=this
        // this.timer = setInterval(()=>{
        //   _this.scrollWidth =_this.scrollWidth - 10
        // },100)
      });
    },
    // 支持一页多条数据，只需改动newsNumPerList参数即可
    handleDot(num) {
      this.showDotIndex = num - 1;
      let idx = this.showDotIndex * this.newsNumPerList;
      let arr = [];
      for (let i = 0; i < this.newsNumPerList; i++) {
        if (this.policyArticleList[idx + i]) {
          arr.push(this.policyArticleList[idx + i]);
        }
      }
      this.showNewsList = [...arr];
    },
    getDay(datetime) {
      if (datetime) {
        return datetime.substring(8, 10);
      }
      return "";
    },
    getMonthUS(datetime) {
      if (datetime) {
        switch (datetime.substring(5, 7)) {
          case "01":
          case "1":
            return "Jan";
            break;
          case "02":
          case "2":
            return "Feb";
            break;
          case "03":
          case "3":
            return "Mar";
            break;
          case "04":
          case "4":
            return "Apr";
            break;
          case "05":
          case "5":
            return "May";
            break;
          case "06":
          case "6":
            return "Jun";
            break;
          case "07":
          case "7":
            return "Jul";
            break;
          case "08":
          case "8":
            return "Aug";
            break;
          case "09":
          case "9":
            return "Sep";
            break;
          case "10":
            return "Oct";
            break;
          case "11":
            return "Nov";
            break;
          case "12":
            return "Dec";
            break;
          default:
            break;
        }
      }
      return "";
    }
  }
};
</script>
<style scoped lang="scss">
@keyframes brandScroll {
  from {
    left: 0;
  }
  to {
    left: var(--scrollWidth);
  }
}
@keyframes brandScroll2 {
  from {
    left: 0;
  }
  to {
    left: var(--scrollWidth2);
  }
}
.home {
  .video-wrap {
    width: 100%;
    min-width: 75rem;
    font-size: 0px;
    position: relative;
    .video-item {
      width: 100%;
    }
    .play-wrap {
      position: absolute;
      width: 100%;
      height: 80%;
      top: 0%;
      left: 0%;
      z-index: 10;
    }
  }
  .tags-wrap {
    width: 100%;
    min-width: 75rem;
    font-size: 0px;
    position: relative;
    height: 7.5rem;
    .img-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .tags-item-wrap {
      .text-wrap {
        margin-left: 1.06rem;
        .title-wrap {
          text-align: left;
          font-size: 1.25rem;
          font-weight: bold;
          color: #191e4c;
          padding-bottom: 0.63rem;
        }
        .desc-wrap {
          font-size: 0.88rem;
          color: #687990;
        }
      }
    }
  }

  .pht-intro-wrap {
    padding: 2.75rem 0 1.88rem;
    text-align: left;
    .left-wrap {
      width: 44.06rem;
      .title {
        font-size: 1.5rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 2.63rem;
      }
      .desc-wrap {
        margin-top: 1.88rem;
        font-size: 0.88rem;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #333333;
        line-height: 1.88rem;
        margin-bottom: 1.25rem;
        p {
          text-indent: 2em;
          margin-bottom: 1rem;
        }
      }
      .more-btn {
        cursor: pointer;
        width: 10.31rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        background: linear-gradient(92deg, #f25a4f 0%, #d62d20 100%);
        box-shadow: 0rem 0.19rem 0.38rem 0.06rem rgba(213, 34, 34, 0.3);
        border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
        font-size: 1.13rem;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #ffffff;
      }
      .labels-wrap {
        margin-top: 2.63rem;
        .label-item {
          .num-wrap {
            font-size: 2.25rem;
            color: #d83124;
            line-height: 1.88rem;
            font-weight: bold;
          }

          .intro-wrap {
            font-size: 1rem;
            font-weight: 500;
            color: #333333;
            line-height: 1.88rem;
            margin-top: 0.5rem;
          }
        }
        .lineV {
          height: 3.19rem;
          width: 0.06rem;
          background-color: #c6c6c6;
        }
      }
    }
  }

  .product-news-wrap {
    width: 100%;
    min-width: 75rem;
    height: 72.88rem;
    box-sizing: border-box;
    padding-top: 2.75rem;
    background: no-repeat url("../assets/img/home/bg2.png") 100% 100%;
    .product-wrap {
      .prod-item {
        width: 24.06rem;
        height: 32.75rem;
        box-sizing: border-box;
        .item-w {
          width: 100%;
          height: 16.06rem;
          box-sizing: border-box;
          padding: 5.25rem 0 5.06rem;
          cursor: pointer;

          .content-w {
            height: 100%;
            .text-w {
              font-size: 1.13rem;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #ffffff;
              line-height: 1.88rem;
            }
          }
        }
        .img-bg1 {
          background: no-repeat url("../assets/img/home/jinrong_bg.png") 100%
            100%;
        }
        .img-bg2 {
          background: no-repeat url("../assets/img/home/xiangcun_bg.png") 100%
            100%;
        }
        .img-bg3 {
          background: no-repeat url("../assets/img/home/xinxi_bg.png") 100% 100%;
        }
        .img-bg4 {
          background: no-repeat url("../assets/img/home/shenghuo_bg.png") 100%
            100%;
        }
      }
      .p1 {
        padding: 4.31rem 4.25rem 0 5.51rem;
        background-color: #d83124;
        cursor: pointer;
        text-align: left;
        .title-w {
          justify-content: flex-end;
          .text {
            width: 9rem;
            font-size: 1.5rem;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 2.63rem;
          }
        }
        .desc-w {
          margin-top: 2.5rem;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 1.88rem;
        }
      }
    }

    .news-wrap {
      margin-top: 3.06rem;
      .title-wrap {
        margin-bottom: 2.69rem;
        font-size: 2.25rem;
        font-family: PingFangSC-Medium-, PingFangSC-Medium;
        font-weight: normal;
        text-align: left;
        color: #191e4c;
      }
      .news-list {
        .news-item {
          width: 23.75rem;
          cursor: pointer;
          margin-right: 1.88rem;
          .news-desc {
            margin-top: 1.25rem;

            .date-wrap {
              padding-top: 0.5rem;
              box-sizing: border-box;
              width: 4.125rem;
              height: 5.375rem;
              flex-shrink: 0;
              color: #ffffff;
              background: no-repeat url(../assets/img/home/biaoqian.png) 100%
                100%;
              .day-wrap {
                font-size: 1.5rem;
              }
              .month-wrap {
                font-size: 0.88rem;
              }
            }
            .news-w {
              text-align: left;
              width: 18.5rem;
              .news-title {
                font-size: 1.13rem;
                font-family: PingFangSC-Regular-, PingFangSC-Regular;
                color: #191e4c;
              }
              .news-content {
                font-size: 0.88rem;
                font-family: PingFangSC-Regular-, PingFangSC-Regular;
                color: #687990;
                line-height: 1.5rem;
              }
            }
          }
        }
      }
      .news-more-wrap {
        margin-top: 3.56rem;
        .dots-wrap {
          // width: 4rem;
          .dot {
            width: 0.75rem;
            height: 0.75rem;
            margin-right: 0.75rem;
            border-radius: 50%;
            background: #2b33aa;
            opacity: 0.17;
            &.active {
              background: #d83124;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .partner-wrap {
    margin-top: 2.06rem;
    margin-bottom: 5.63rem;
    .title-wrap {
      margin-bottom: 2.69rem;
      font-size: 2.25rem;
      font-family: PingFangSC-Medium-, PingFangSC-Medium;
      font-weight: normal;
      text-align: left;
      color: #191e4c;
    }
    .showview-wrap {
      background: #ffffff;
      height: 16.25rem;
      width: 75rem;
      box-sizing: border-box;
      padding: 2.63rem 1rem 0 1rem;
      box-shadow: 0rem 0rem 0.38rem 0.06rem #cccccc4d;
      position: relative;
      overflow: hidden;
      &:hover {
        .banner-list-wrap,
        .banner-list-wrap-reverse {
          animation-play-state: paused;
        }
      }
    }
    .banner-list-wrap {
      position: absolute;
      // left: 0;
      flex-wrap: nowrap;
      flex-shrink: 0;
      animation: brandScroll 60s linear 0.5s infinite;
    }
    .banner-item-wrap {
      height: 5rem;
      margin-right: 1rem;

      a {
        height: 3.75rem;
        display: inline-block;
        border: #dddddd solid 1px;
        img {
          height: 100%;
        }
      }
    }
    .banner-list-wrap-reverse {
      position: absolute;
      // left: 0;
      bottom: 2rem;
      flex-wrap: nowrap;
      flex-shrink: 0;
      animation: brandScroll2 60s linear 0.5s infinite;
    }
  }
}
</style>
