<template>
  <div class="art-title" :style="{ 'text-align': textAlign }">
    <div class="num-wrap">{{ num }}</div>
    <div class="title-wrap">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "ArtTitle",
  props: {
    num: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    textAlign: {
      type: String,
      default: "left"
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.art-title {
  width: 100%;
  .num-wrap {
    font-size: 3rem;
    font-family: SapientSansBold;
    font-weight: 600;
    line-height: 3.69rem;
    color: #333333;
    opacity: 0.15;
  }
  .title-wrap {
    margin-top: 0.1rem;
    font-size: 1.5rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 2.06rem;
    color: #191e4c;
    opacity: 1;
  }
}
</style>
