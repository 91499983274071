<template>
  <div class="header">
    <div class="w1200 rsc">
      <router-link to="/home" class="logo"><img src="@/assets/img/logo.jpg" alt="" style="height:2.2rem"></router-link>
      <nav class="nav">
        <router-link to="/home">首页</router-link>
        <!-- <router-link to="/loan">贷款产品</router-link> -->
        <!-- <router-link to="/mainbs">主要业务</router-link> -->
        <router-link to="/mainprod">金融科技</router-link>
        <router-link to="/mainserv">金融服务</router-link>
        <router-link to="/ruralrevital">乡村振兴</router-link>
        <router-link to="/typicalcase">典型案例</router-link>
        <!-- <router-link to="/policy">新闻动态</router-link> -->
        <!-- <router-link to="/credit">企业征信</router-link> -->
        <router-link to="/contact">关于我们</router-link>
        <a href="https://platform.phjrt.com/#/loginforOrg" target="_blank" class="btnin bankin">银行入口</a>
        <a href="https://platform.phjrt.com/#/loginforOrg" target="_blank" class="btnin governmentin">政府入口</a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.header {
  /* 同菜单字体行高一致 */
  --height: 4.44rem;
  width: 100%;
  height: var(--height);
  background: #ffffff;
  opacity: 1;
  box-shadow: 0rem 0.19rem 1.25rem 0.06rem rgba(12, 18, 107, 0.05);
}
.logo {
  display: block;
  width: 5.38rem;
  height: 1.75rem;
  line-height: 1.75rem;
}
/* #container {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
} */
.nav {
  width: 50rem;
  height: var(--height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav a {
  display: block;
  box-sizing: border-box;
  padding: 0 0.625rem;
  line-height: var(--height);
  position: relative;
  font-weight: 400;
  font-size: 1rem;
}

.nav a:hover::before,
.nav a.router-link-active::before {
  content: '';
  position: absolute;
  height: 0.1875rem;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #d83023;
}
.nav a.btnin:hover::before,
.nav a.btnin.router-link-active::before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  bottom: 0;
  left: 0;
}
.nav a.btnin.router-link-active::before {
  content: '';
  position: absolute;
  height: 0.1875rem;
  width: 100%;
  bottom: 0;
  left: 0;
  background: transparent;
}
.nav a:hover,
.nav span:hover,
.nav a.router-link-active {
  cursor: pointer;
  font-weight: 600;
  color: #000000;
}
.nav .btnin:hover::before {
  content: '';
}
.nav .btnin {
  display: block;
  width: 5.375rem;
  height: 2rem;
  line-height: 2rem;
  background: linear-gradient(180deg, #ffffff 0%, #e6f0ff 100%);
  opacity: 1;
  border-radius: 1.25rem;
}
/* .nav a.btnin:hover::before{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
} */
.nav .btnin.bankin {
  color: #1564db;
}
.nav .btnin.goverment {
  background: linear-gradient(180deg, #ffffff 0%, #ffe5e7 100%);
}
.nav .btnin.governmentin {
  color: #d93340;
}
</style>
