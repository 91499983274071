<template>
  <div class="policy">
    <Header />
    <div class="banner" v-if="newsBannerUrl.img" :style="'background-image:url('+newsBannerUrl.img+')'">
    </div>
    <div id="container" class="main">
      <div class="navlist">
        <span :class="item.id==currentCategoryIndex?'active':''" v-show="item.title" v-for="item in categoryList" :key="item.id" @click="getCategoryArticleList(item.id)">{{item.title}}</span>
      </div>
      <div class="newslist">
        <div v-loading="isloading" style="width:100%;height: 84rem;">
          <div class="newsitem" v-for="item in categoryArticleList" :key="item.id">
              <div class="time"><p class="month">{{ item.created_at[0] }}</p>  <p class="year">{{item.created_at[1]+'-'+item.created_at[2]}}</p> </div>
              <img class="img" v-if="item.thumb_image" :src="item.thumb_image" alt=""  @click="$router.push({path:`/article/${item.id}`})">
              <div class="detail" >
                <p class="title" @click="$router.push({path:`/article/${item.id}`})">{{item.title}}</p>
                <p class="source">来源：{{item.source_author}}</p>
              </div>
          </div>
        </div>
        <div class="pagination">
          <div class="block">
            <el-pagination
              @current-change="handlePageChange"
              @prev-click="handlePageChange"
              @next-click="handlePageChange"
              :current-page.sync="currentPage"
              :page-size="5"
              layout="prev, pager, next, jumper"
              :total="paginationTotal"
              :background="true">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import {setSpot} from "@/utils/tools.js"
export default {
  name: "Mainbs",
  components: {
    Header,
    Sidebar,
    Footer,
  },
  data(){
    return {
      currentCategoryIndex:'',
      newsBannerUrl:'',
      categoryList:[],
      categoryArticleList:[],
      currentPage:1,
      paginationTotal:0,
      isloading: false,
    }
  },
  async created(){    
    setSpot('新闻动态')
    this.initBannerUrl()
    this.initcategoryList()
  },
  methods:{
    async initBannerUrl(){
      const res = await this.$get('/operation-management/api/banner?plate=webnews')
      this.newsBannerUrl = res[0]
    },
    async initcategoryList(){
      const res = await this.$get('/operation-management/api/articles/category')
      this.categoryList = res
      this.currentCategoryIndex = res[0].id
      this.getCategoryArticleList(this.currentCategoryIndex)
    },
    async getCategoryArticleList(id){
      this.currentPage = 1
      this.currentCategoryIndex = id
      this.isloading = true;
      this.$get(`/operation-management/api/articles?category_id=${id}`).then((res)=>{
        this.paginationTotal = res.total
        this.categoryArticleList = res.data
        for(let i = 0;i<this.categoryArticleList.length;i++){
          this.categoryArticleList[i].created_at  = this.categoryArticleList[i].created_at.split(' ')[0]
          this.categoryArticleList[i].created_at = this.categoryArticleList[i].created_at.split('-')
        }   
        this.isloading = false;
      })
    },
    async handlePageChange(page) {
      this.isloading = true;
      this.$get(`/operation-management/api/articles?category_id=${this.currentCategoryIndex}&page=${page}`).then((res)=>{
        this.categoryArticleList = res.data
        for(let i = 0;i<this.categoryArticleList.length;i++){
          this.categoryArticleList[i].created_at  = this.categoryArticleList[i].created_at.split(' ')[0]
          this.categoryArticleList[i].created_at = this.categoryArticleList[i].created_at.split('-')
        }
        this.isloading = false;
      })
    },
  }
};
</script>

<style scoped>

.banner{
  box-sizing: border-box;
  width: 100%;
  height: 12.8125rem;
  padding: 4.25rem 0 4.25rem 2.38rem;
  background-position: center center;
    background-repeat: no-repeat;
  background-size: cover;
}
.title-zh{
  text-align: left;
  font-size: 2rem;
  color: #FFFFFF;
  margin-bottom: 0.5rem;
}
.title-en{
  text-align: left;
  font-size: 1rem;
  color: #F3F3F3;
  opacity: 0.5;
}

#container.main{
  /* height: 78.94rem; */
  height: 97rem;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 5.63rem 4.25rem 5rem 2.13rem;
  display: flex;
  flex-direction: row;
}
.navlist{
  box-sizing: border-box;
  margin-right: 4.06rem;
  width: 12.94rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #707070;
}
.navlist span{
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.63rem;
  color: #030303;
  margin-bottom: 4.75rem;
  padding: 0.63rem 0;
  cursor: pointer;
}

.navlist span.active{
  color: #D81E06;
  border-right: 3px solid #D81E06;;
}
.newslist{
  height: 87rem;
  overflow: hidden;
}
.newsitem{
  width: 51.32rem;
  height: 13.13rem;
  display: flex;
  margin-bottom: 3.75rem;
}
.img{
  width: 17.5rem;
  height: 13.13rem;
  margin-left: 2.81rem;
  margin-right: 2.13rem;
  cursor: pointer;
}
.detail p{
  text-align: left;
}
.time{
  width: 4.38rem;
}
.month{
  width: 4.38rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 1.25rem;
}
.year{
  width: 4.38rem;
  font-size: 0.88rem;
  font-weight: 400;
  color: #B1B1B1;
  text-align: right;
  position: relative;
  right: .5rem;
}
.detail .title{
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #000000;
  margin-bottom: 1.56rem;
  cursor: pointer;
}
.detail .source{
  font-size: 0.88rem;
  line-height: 1.63rem;
  color: #B1B1B1;
}

</style>