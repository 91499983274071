<template>
  <div class="typicalcase">
    <Header />
    <div style="font-size:0;">
      <img
        src="@/assets/img/typicalcase/banner1.png"
        style="width:100%;min-width: 75rem;"
        alt=""
      />
    </div>
    <div class="bg1 padding-bottom-6 padding-top-4">
      <div class="w1200">
        <div
          class="rs margin-auto margin-bottom-4 height10"
          style="align-items:baseline;width: 60rem;"
        >
          <div
            class="img cursor-pointer"
            :class="tabIndex == 1 ? '' : 'zoom'"
            @click="handleTab(1)"
          >
            <img
              :src="
                require(`@/assets/img/typicalcase/${
                  tabIndex == 1 ? 'da' : 'xiao'
                }-1.png`)
              "
              alt=""
            />
          </div>
          <div
            class="img cursor-pointer"
            :class="tabIndex == 2 ? '' : 'zoom'"
            @click="handleTab(2)"
          >
            <img
              :src="
                require(`@/assets/img/typicalcase/${
                  tabIndex == 2 ? 'da' : 'xiao'
                }-2.png`)
              "
              alt=""
            />
          </div>
          <div
            class="img cursor-pointer"
            :class="tabIndex == 3 ? '' : 'zoom'"
            @click="handleTab(3)"
          >
            <img
              :src="
                require(`@/assets/img/typicalcase/${
                  tabIndex == 3 ? 'da' : 'xiao'
                }-3.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="relative">
        <div class="card" v-show="tabIndex == 1">
          <div class="rsc w1200 bg2 margin-bottom-3">
            <div>
              <img src="@/assets/img/typicalcase/img1.png" alt="" />
            </div>
            <div class="margin-left-2 margin-right-2">
              <div class="title margin-bottom-3">普惠金融“兰考模式”</div>
              <div class="content margin-bottom-1">
                <p class="margin-bottom-1">
                  公司成立之初，为兰考试验区普惠金融推广工作提供重要技术支撑，建立包括<b>数字普惠金融综合服务平台（普惠通APP)</b>、<b>普惠金融综合管理系统</b>及<b>大数据展示系统</b>在内的普惠金融推广技术体系，打造线上金融超市，打通普惠金融在县域农村的“最后一公里”，逐步形成了以数字普惠金融综合服务平台（普惠通APP)为核心，以普惠授信体系、信用信息体系、金融服务体系、风险防控体系为基本内容的“一平台四体系”普惠金融“兰考模式”。
                </p>
                <p>
                  普惠金融“兰考模式”推广复制的技术支撑。助力兰考模式在全省22个县（市、区）短时间复制推广。
                </p>
              </div>
              <!-- <div>
                <div class="rc margin-bottom-1">
                  <div>
                    <img src="@/assets/img/typicalcase/img2.png" alt="" />
                  </div>
                  <div class="second-title margin-left-1">
                    普惠金融板块：
                  </div>
                </div>
                <div class="rc">
                  <div class="tag">
                    数字普惠金融综合服务平台（普惠通APP)
                  </div>
                  <div class="tag  ">普惠金融综合管理系统</div>
                  <div class="tag  ">大数据展示系统</div>
                </div>
              </div> -->
            </div>
          </div>

          <div class="rsc w1200 bg2 margin-bottom-4">
            <div class="margin-left-3 margin-right-1">
              <div class="title margin-bottom-3">普惠金融“兰考模式”</div>
              <div class="content margin-bottom-2">
                <p>
                  <b>一平台：</b
                  >即“普惠通”平台，向全部金融机构开放，打造线上金融超市，打通普惠金融在县域农村的“最后一公里”。
                </p>
                <p>
                  <b>四体系：</b>即<span class="red"
                    >普惠授信体系、信用信息体系、金融服务体系、风险防控体系。</span
                  >
                </p>
              </div>
              <div class="rs text-center">
                <div class="w75">
                  <div class="margin-bottom-1">
                    <img src="@/assets/img/typicalcase/img4.png" alt="" />
                  </div>
                  <div class="t">普惠授信体系</div>
                  <div class="desc">提供各类<br />信贷服务</div>
                </div>
                <div class="w75">
                  <div class="margin-bottom-1">
                    <img src="@/assets/img/typicalcase/img5.png" alt="" />
                  </div>
                  <div class="t">信用信息体系</div>
                  <div class="desc">
                    “先授信后用信 信用信贷相长”的创新授信模式
                  </div>
                </div>
                <div class="w75">
                  <div class="margin-bottom-1">
                    <img src="@/assets/img/typicalcase/img6.png" alt="" />
                  </div>
                  <div class="t">金融服务体系</div>
                  <div class="desc">建设“4+X”功能的村级普惠金融服务站</div>
                </div>
                <div class="w75">
                  <div class="margin-bottom-1">
                    <img src="@/assets/img/typicalcase/img7.png" alt="" />
                  </div>
                  <div class="t">风险防控体系</div>
                  <div class="desc">
                    根据银行贷款不良率，分担机制和风险隔离机制
                  </div>
                </div>
              </div>
            </div>

            <div>
              <img src="@/assets/img/typicalcase/img3.png" alt="" />
            </div>
          </div>

          <div style="font-size:0;">
            <img
              src="@/assets/img/typicalcase/img8.png"
              style="width:100%;min-width: 75rem;"
              alt=""
            />
          </div>
        </div>

        <div class="card " v-show="tabIndex == 2">
          <div class="w1200 second-card" style="">
            <div class="title text-center margin-bottom-2">
              数字金融开放服务平台
            </div>
            <div class="content margin-bottom-4 second-content" style="text-align:left;text-indent:2em;">
              2022年，数字金融开放服务平台作为“智慧岛1号”工程在郑州中原科技城智慧岛落地部署，将为“智慧岛”发力提供强大引擎。该平台通过最大限度整合企业公共信用信息、政府扶持政策、担保机构、信用服务机构，打造一“码”、一“劵”、一“平台”的金融服务体系，引入“含金量、含绿量、含新量”三评价及五维信用评分机制，为金融机构提供小微企业精准画像，实现贷前、贷中、贷后全流程风险防控。营造“信息共享+信用赋能+融资匹配+服务推广+政策集成”的中小微企业信用金融服务生态。
            </div>
            <div style="font-size:0;">
              <img
                src="@/assets/img/typicalcase/img9.png"
                style="width:100%;"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="card " v-show="tabIndex == 3">
          <div class="title text-center margin-bottom-2">电商服务</div>
          <div class="w1200 rsc">
            <div class="card-item padding-bottom-2">
              <div class="margin-bottom-2" style="font-size:0;">
                <img
                  src="@/assets/img/typicalcase/img10.png"
                  style="width:100%;"
                  alt=""
                />
              </div>
              <div class="text-center ">
                <div class="tip-title ">
                  <div class="tip-pic">
                    <img src="@/assets/img/typicalcase/img11.png" alt="" />
                  </div>
                  供应链运营
                </div>
              </div>
              <div class="tip-desc">赋能兰考乡村振兴</div>
              <div class="tip-content">
                以与兰考乡村振兴局
                、商务局深度战略合作为基础，与多家本地助农企业进行线上线下助农产品的销售，以供应链为核心，以电商为发力点，带动当地农产品销售
                、雇佣就业等，促进兰考地区电商互联网发展。
              </div>

              <div class="rc ">
                <div class="div-box">
                  <div class="box-title">涉农选品</div>
                  <div class="box-digtal">
                    <count-to
                      :startVal="0"
                      :autoplay="false"
                      ref="count1"
                      :endVal="40"
                      :duration="2000"
                    ></count-to>+
                  </div>
                </div>
                <div class="v-line"></div>
                <div class="div-box">
                  <div class="box-title">2022年助农直播场次</div>
                  <div class="box-digtal">
                    <count-to
                      :startVal="0"
                      :autoplay="false"
                      ref="count2"
                      :endVal="36"
                      :duration="2000"
                    ></count-to>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-item padding-bottom-2">
              <div class="margin-bottom-2" style="font-size:0;">
                <img
                  src="@/assets/img/typicalcase/img12.png"
                  style="width:100%;"
                  alt=""
                />
              </div>
              <div class="text-center ">
                <div class="tip-title ">
                  <div class="tip-pic">
                    <img src="@/assets/img/typicalcase/img13.png" alt="" />
                  </div>
                  产业品牌孵化
                </div>
              </div>
              <div class="tip-desc">助郏县传统企业完成电商转型</div>
              <div class="tip-content">
                与郏县政府达成战略合作，以铁锅为核心产业带基础，在抖音、快手等平台持续发力电商供应链，用时2个月，电商订单破万。
                帮助当地传统企业完成电商转型。
                包括电商资源整合，电商体系搭建，电商供应链完善。
              </div>

              <div class="rc ">
                <div class="div-box">
                  <div class="box-title">仅仅</div>
                  <div class="box-digtal">
                    <count-to
                      :startVal="0"
                      :autoplay="false"
                      ref="count3"
                      :endVal="2"
                      :duration="2000"
                    ></count-to
                    >个月
                  </div>
                </div>
                <div class="v-line"></div>
                <div class="div-box">
                  <div class="box-title">电商订单</div>
                  <div class="box-digtal">
                    <count-to
                      :startVal="0"
                      :autoplay="false"
                      ref="count4"
                      :endVal="10000"
                      :duration="2000"
                    ></count-to>+
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import countTo from "vue-count-to";

export default {
  components: {
    Header,
    Footer,
    Sidebar,
    countTo
  },
  data() {
    return {
      tabIndex: 1,
      metas: [
        {
          title: "普惠通官网-普惠金融“兰考模式”",
          keywords:
            "普惠通,兰考模式,普惠金融,普惠授信体系,信用信息体系,金融服务体系,风险防控体系",
          description:
            "形成了以“数字普惠金融服务平台为核心，以金融服务体系、普惠授信体系、信用信息体系、风险防控体系为主要内容”的“一平台四体系”普惠金融兰考模式。"
        },
        {
          title: "普惠通官网-数字金融开放服务平台",
          keywords: "普惠通,征信,信用,大数据,智慧岛,中小微企业,融资",
          description:
            "该平台以数据、特别是信用大数据为基础，以风险分担机制为杠杆，通过数据聚合、系统直连、智能风控、风险补偿等创新手段与补贴政策，解决中小微企业融资难、融资贵问题，促进当地经济活力，大力支持中原地区企业健康发展。 "
        },
        {
          title: "普惠通官网-电商服务",
          keywords: "普惠通,电商,乡村振兴,供应链,直播,农产品,销售",
          description:
            "帮助多家本地助农企业进行线上线下助农产品销售，帮助当地传统企业完成电商资源整合，电商体系搭建，电商供应链完善，促进地区电商互联网发展。"
        }
      ]
    };
  },
  methods: {
    handleTab(idx) {
      this.tabIndex = idx;

      if (idx > 0) {
        // 设置meta标签
        document.title = this.metas[idx - 1].title;
        if (this.metas[idx - 1].description) {
          if (document.querySelector('meta[name="description"]')) {
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", this.metas[idx - 1].description);
          } else {
            let meta = document.createElement("meta");
            meta.content = this.metas[idx - 1].description;
            meta.name = "description";
            document.getElementsByTagName("head")[0].appendChild(meta);
          }
        }
        if (this.metas[idx - 1].keywords) {
          if (document.querySelector('meta[name="keywords"]')) {
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", this.metas[idx - 1].keywords);
          } else {
            let meta = document.createElement("meta");
            meta.content = this.metas[idx - 1].keywords;
            meta.name = "keywords";
            document.getElementsByTagName("head")[0].appendChild(meta);
          }
        }
      this.startCount(idx);
      this.resetCount(idx);
      }
    },
    resetCount(idx) {
      if (this.$refs.count1 && idx != 3) {
        this.$refs.count1.reset();
        this.$refs.count2.reset();
        this.$refs.count3.reset();
        this.$refs.count4.reset();
      }
    },
    startCount(idx) {
      switch (idx) {
        case 3:
          this.$refs.count1.start();
          this.$refs.count2.start();
          this.$refs.count3.start();
          this.$refs.count4.start();
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$count: 5;
@for $i from 0 through $count * 2 {
  .margin-bottom-#{ $i } {
    margin-bottom: $i * 1rem;
  }
  .margin-left-#{ $i } {
    margin-left: $i * 1rem;
  }
  .margin-right-#{ $i } {
    margin-right: $i * 1rem;
  }
  .padding-bottom-#{ $i } {
    padding-bottom: $i * 1rem;
  }
  .padding-top-#{ $i } {
    padding-top: $i * 1rem;
  }
}
.typicalcase {
  .relative {
    position: relative;
    min-height: 29.13rem;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .bg1 {
    background: #f2f4f9;
    // .zoom:hover{
    //   img{
    //     zoom: 1.2;
    //   }
    // }
    .img {
      width: 10.25rem;
    }
    .height10 {
      height: 10.3125rem;
    }
    .card {
      // position: absolute;
      // top: 0;
      // left: 0;
      text-align: left;
      width: 100%;

      .second-card {
        border-radius: 0.63rem;
        background: #ffffff;
        box-sizing: border-box;
        padding: 2.94rem 3.44rem 3.25rem;
      }
      .title {
        font-size: 1.5rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #191e4c;
      }
      .content {
        font-size: 1rem;
        font-family: PingFang SC;
        text-align: justify;
        font-weight: 400;
        line-height: 1.88rem;
        color: #333333;
        .red {
          color: #e25f55;
          font-weight: bold;
        }
      }
      .second-content {
        text-align: center;
      }

      .second-title {
        font-size: 1.2rem;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 1.88rem;
        color: #333333;
      }
      .tag {
        font-size: 0.88rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #d83124;
        padding: 0.31rem 0.45rem;
        background: #ffe6e6;
        margin-right: 0.2rem;
      }
      .bg2 {
        background: #fff;
        border-radius: 0.63rem;
        box-sizing: border-box;
        padding: 2.06rem 0 2.25rem 0;
      }
      .w75 {
        width: 7.5rem;
      }
      .t {
        font-size: 1rem;
        font-family: PingFangSC-Medium;
        line-height: 1.38rem;
        color: #333333;
        font-weight: bold;
        margin-bottom: 0.75rem;
      }
      .desc {
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 1.75rem;
        color: #666666;
      }
      .card-item {
        width: 36.25rem;
        border-radius: 0.63rem;
        background: #ffffff;
        .tip-title {
          position: relative;
          display: inline-block;
          line-height: 1.78rem;
          font-size: 1.25rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .tip-pic {
            position: absolute;
            top: 0;
            left: -3.75rem;
          }
        }
        .tip-desc {
          text-align: center;
          margin-top: 0.31rem;
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 2.63rem;
          color: #333333;
        }
        .tip-content {
          width: 30.19rem;
          height: 6.94rem;
          font-size: 0.88rem;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 1.88rem;
          margin: 0 auto 1.25rem;
        }
        .v-line {
          background: #c6c6c6;
          width: 0.06rem;

          height: 3.19rem;
        }
        .div-box {
          width: 10rem;
          margin-left: 6rem;
          .box-title {
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 1.88rem;
            color: #666666;
          }
          .box-digtal {
            font-size: 2.25rem;
            margin-top: 0.81rem;
            font-family: SapientSansBold;
            font-weight: 500;
            line-height: 1.88rem;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
