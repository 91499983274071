<template>
  <div>
    <div class="title">
      <div class="circle"></div>
      <div class="text">{{ text }}</div>
      <div class="backText">{{ backText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArtText",
  props: {
    text: {
      type: String,
      default: "",
    },
    backText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style  scoped>
.title {
  height: 2.5rem;
  position: relative;
}
.circle {
  position: absolute;
  left: 0;
  bottom: 1.13rem;
  width: 1.44rem;
  height: 1.44rem;
  background: #bbd9ff;
  border-radius: 50%;
  display: inline-block;
}
.text {
  position: absolute;
  left: 0.38rem;
  bottom: 0.63rem;
  height: 1.63rem;
  font-weight: bold;
  line-height: 1.63rem;
font-size: 1.88rem;
  color: #004db3;
  display: inline-block;
}
.backText {
  display: inline-block;
  position: absolute;
  left: 2.88rem;
  bottom: -1.5rem;
  font-size: 3rem;
  line-height: 4.94rem;
  height: 4.94rem;
  font-weight: bold;
  color: #919090;
  opacity: 0.1;
}
</style>