// 倒序时间
export function format_before_time(dateTimeStamp) {
  var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  // var halfamonth = day * 15;
  var month = day * 30;
  var year = day * 365;
  var now = new Date().getTime(); //获取当前时间毫秒
  // console.log(now);
  var time = new Date(dateTimeStamp).getTime()
  
  var diffValue = now - time; //时间差

  if (diffValue < 0) {
    return;
  }
  var minC = diffValue / minute; //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  var yearC = diffValue / year;
  var result;
  if (yearC >= 1) {
    result = " " + parseInt(yearC) + "年前";
  } else if (monthC >= 1 && monthC <= 12) {
    result = " " + parseInt(monthC) + "月前";
  } else if (weekC >= 1 && weekC <= 4) {
    result = " " + parseInt(weekC) + "周前";
  } else if (dayC >= 1 && dayC <= 7) {
    result = " " + parseInt(dayC) + "天前";
  } else if (hourC >= 1 && hourC <= 24) {
    result = " " + parseInt(hourC) + "小时前";
  } else if (minC >= 1 && minC <= 60) {
    result = " " + parseInt(minC) + "分钟前";
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚";
  } else {
    var datetime = new Date();
    datetime.setTime(dateTimeStamp);
    var Nyear = datetime.getFullYear();
    var Nmonth =
      datetime.getMonth() + 1 < 10
        ? "0" + (datetime.getMonth() + 1)
        : datetime.getMonth() + 1;
    var Ndate =
      datetime.getDate() < 10
        ? "0" + datetime.getDate()
        : datetime.getDate();
    // var Nhour =
    //   datetime.getHours() < 10
    //     ? "0" + datetime.getHours()
    //     : datetime.getHours();
    // var Nminute =
    //   datetime.getMinutes() < 10
    //     ? "0" + datetime.getMinutes()
    //     : datetime.getMinutes();
    // var Nsecond =
    //   datetime.getSeconds() < 10
    //     ? "0" + datetime.getSeconds()
    //     : datetime.getSeconds();
    result = Nyear + "-" + Nmonth + "-" + Ndate;
  }
  return result;
}


export function getTreeDeepArr(key, treeData) {

  let arr = []; // 在递归时操作的数组
  let returnArr = []; // 存放结果的数组
  let depth = 0; // 定义全局层级
  // 定义递归函数
  function childrenEach(childrenData, depthN) {

      for (var j = 0; j < childrenData.length; j++) {

          depth = depthN; // 将执行的层级赋值 到 全局层级

          arr[depthN] = (childrenData[j].value);
          
          if (childrenData[j].value == key) {

              // returnArr = arr; // 原写法不行, 因 此赋值存在指针关系
              returnArr = arr.slice(0, depthN+1); //将目前匹配的数组，截断并保存到结果数组，
              break

          } else {

              if (childrenData[j].children) {

                  depth ++;
                  childrenEach(childrenData[j].children, depth);

              }
          }

      }
      return returnArr;
  }
  return childrenEach(treeData, depth);
}

// 数组去重
export function unique(arr){            
  for(var i=0; i<arr.length; i++){
    if(arr[i]){
      for(var j=i+1; j<arr.length; j++){
          if(arr[i]==arr[j]){         //第一个等同于第二个，splice方法删除第二个
              arr.splice(j,1);
              j--;
          }
      }
    }else{
      arr.splice(i,1)
    }
  }
return arr;
}


// talkingData统计平台埋点
export function setSpot (sendMsg, logMsg) {
  try {
    window.TDAPP.onEvent(sendMsg)
    return true
  } catch (err) {
    return false
  }
}