<template>
  <div>
    <Header />

    <img src="../assets/img/creditBanner.png" alt=""  style="image-rendering: -webkit-optimize-contrast;"/>
    <div id="container" class="credit">
      <div style="display: flex; justify-content: space-between">
        <div class="creditBox1">
          <img src="../assets/img/creditpicture1.png" alt="" />
          <div class="sys-head">数据维度最全面</div>
          <div class="blueLine"></div>
          <p>
            <!-- 融合73个委办局单位政务数据，重点整合小额贷款、融资担保、保理公司等地方金融机构和其他市场化服务机构的数据，首次归集数据超十亿条 -->
          </p>
        </div>
        <div class="creditBox1">
          <img src="../assets/img/creditpicture2.png" alt="" />
          <div class="sys-head">产品类型最丰富</div>
          <div class="blueLine"></div>
          <p>
            <!-- 信用平台围绕金融服务、社会治理、公共服务三大领域的重点应用场景，研发涉及相关的信用风控产品或服务 -->
          </p>
        </div>
        <div class="creditBox1">
          <img src="../assets/img/creditpicture3.png" alt="" />
          <div class="sys-head">技术手段最先进</div>
          <div class="blueLine"></div>
          <p>
            <!-- 信用平台全面采用区块链、可信计算等新技术解决方案，保障了数据主体的权利，解决了数据管理安全和保密问题 -->
          </p>
        </div>
        <div class="creditBox1">
          <img src="../assets/img/creditpicture4.png" alt="" />
          <div class="sys-head">服务方式最多样</div>
          <div class="blueLine"></div>
          <p>
            <!-- 线上搭建智能服务web端、APP、微信小程序等服务矩阵，线下通过金融机构对接会、政府服务宣讲会等多种方式提供信用服务 -->
          </p>
        </div>
      </div>
      <div @click="goUrl('http://cip.phjrt.com')">
        <img src="../assets/img/creditpicture5.png" alt=""
          style="width: 19.5rem; height: 3.5rem; margin-top: 3.5rem;cursor: pointer;" />
      </div>
    </div>
    <div class="credit" id="container">
      <div class="sys-head">输出产品</div>
      <div>
        <img src="../assets/img/creditPicture7.png" alt="" style="width: 1rem; height: 2.8rem; margin-top: 3rem" />
      </div>

      <img src="../assets/img/creditPicture6.png" alt="" style="width: 74.5rem;  margin-top: -0.4rem" />
    </div>
    <div class="credit" id="container">
      <div class="sys-head">典型应用案例</div>
    </div>
    <div>
      <img src="../assets/img/creditPicture7.png" alt="" style="width: 1rem; height: 2.8rem; margin-top: 3rem" />
    </div>
    <div :style="{
      backgroundImage: 'url(' + require('@/assets/img/creditBg1.png') + ')',
    }" class="creditBox2">
      <div style="position: relative">
        <img src="../assets/img/creditPicture9.png" alt="" style="width: 37.25rem; height: 41rem" />
        <div class="creditBox4">智慧岛数字金融开放服务平台</div>
        <div class="creditBox3">
          智慧岛数字金融开放服务平台，依托国家及省“信易贷”平台，建设“智慧岛”数字金融开放服务平台，借鉴国内各地金融服务平台建设经验，以数据、特别是信用大数据为基础，以智能化风控技术为依托，以风险分担机制为杠杆，以“科技贷”、“信用贷”为抓手，打造数字化、开放式金融服务平台，通过数据聚合、系统直连、智能风控、风险补偿等创新手段与补贴政策，解决中小微企业融资难、融资贵问题，支持中原科技城园区企业发展。
        </div>
        <img src="../assets/img/creditPicture8.png" alt="" class="creditBox5" />
        <div @click="goUrl('https://www.ccftis.net')" style="position:absolute;top: 36.5rem;
    left: 14rem;cursor: pointer;">
          <img src="../assets/img/Checkwebsite.png" alt="" style="width: 9rem; height: 3.5rem; " />
        </div>
      </div>
      <div style="position: relative">
        <img src="../assets/img/creditPicture10.png" alt="" style="width: 37.25rem; height: 41rem" />
        <div class="creditBox4">智慧岛基金大数据服务平台</div>
        <div class="creditBox6">
          <div style="display:flex;">
            <div class="blueLine1"></div>
            <div class="boxTitle">定位</div>
            <div class="blueLine1"></div>
          </div>
          <div class="creditBox7">
            基金大数据平台目标打造全方位、高水平、数字化的金融服务平台，以构建中原科技城内科技企业的股权融资体系。利用人工智能、大数据、知识图谱等高新技术，打通“三网五数三中心”实现供需智能匹配、信息共享、在线图谱、知识库、分析决策等，构建“智慧基金”。
          </div>
          <div style="display:flex;margin-top: 1rem;">
            <div class="blueLine1"></div>
            <div class="boxTitle">目标</div>
            <div class="blueLine1"></div>
          </div>
          <div class="creditBox7">
            通过基金信息服务、基金招商服务、基金银行投贷联动等功能，实现为基金投资人、基金管理人、被投企业等各方提供“互联网+”服务和业务咨询、项目尽调、人才培养、投融资对接、资产管理等增值服务，构建金融大数据服务体系。
          </div>

        </div>
        <div @click="goUrl('https://www.ccvcpe.net/')" style="position:absolute;top: 36.5rem;
    left: 14rem;cursor: pointer;">
          <img src="../assets/img/Checkwebsite.png" alt="" style="width: 9rem; height: 3.5rem; " />
        </div>
      </div>
    </div>
    <div style="width:75rem;margin:8rem auto 10rem auto;">
      <div style="width: 6.56rem;
height: 2.44rem;margin-left: 0.5rem">
        <img src="../assets/img/creditPicture11.png" alt="">
      </div>
      <div v-for="item in creditNews" :key="item.id">
        <div style="position:relative;margin-top: 0.8rem;cursor: pointer; "  @click="$router.push(`/article/${item.id}`)">
          <img src="../assets/img/creditPicture12.png" alt="" style="height: 5rem">

          <div style="position:absolute;    top: 1rem;
    left: 3rem;" class="creditText1">{{ item.created_at.substring(0, 4) }}/{{ item.created_at.substring(5, 7) }}</div>
          <div style="position:absolute;    top: 2.3rem;
    left: 4.6rem;" class="creditText4">{{ item.created_at.substring(8, 10) }}</div>
          <div class="creditText2">{{ item.title }}</div>
          <div class="creditText3">
            {{ item.source_author }}
          </div>
        </div>
      </div>
    </div>
    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import { setSpot } from "@/utils/tools.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "credit",
  components: {
    Header,
    Footer,
    Sidebar,
  },
  data() {
    return {
      creditNews: [],
    }
  },
  created() {
    setSpot("主要业务-征信产品");
    this.initCreditNews()
  },
  methods: {
    async initCreditNews() {
      const res = await this.$get('/operation-management/api/articles', { category_id: 2 })
      this.creditNews = res.data
      // console.log("creditNews", creditNews)
    },
    goUrl(url) {
      window.open.href = url;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
p {
  text-align: justify;
  /* margin-bottom: 1.13rem; */
  margin-top: 1.2rem;
  font-size: 1.05rem;
  font-family: Microsoft YaHei;
  font-weight: 500;
  line-height: 1.5rem;
  color: #515a67;
  opacity: 1;
}

.credit {
  width: 74.94rem;
  /* height: 45.63rem; */
  box-sizing: border-box;
  padding: 4.5rem 0 0 0;
  margin-bottom: 3.57rem;
}

img {
  width: 100%;
}

.sys-head {
  margin-top: 2rem;
  color: #2d2d2d;
  font-weight: bold;
  font-size: 1.13rem;
}

/* .sys-title{

font-size: 1.3rem;
font-family: Microsoft YaHei;
font-weight: 550;
line-height: 1.94rem;
color: #000000;
opacity: 1;
} */
.sys-name {
  font-size: 1.88rem;
  color: #004db3;
}

.sys-desc {
  width: 45.06rem;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #515a67;
}

img {
  width: 100%;
}

p.line {
  width: 28.44rem;
  height: 1px;
  background: #e6e6e6;
}

.sys-admin {
  margin-top: 4rem;
}

.creditBox1 {
  width: 14rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.blueLine {
  width: 2.19rem;
  height: 0.25rem;
  background: #d83023;
  opacity: 1;
  margin: 0.5rem auto 0 auto;
}

.creditBox2 {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-bottom: 3.75rem;
  padding-top: 2rem;
}

.creditBox3 {
  position: absolute;
  top: 16.5rem;
  left: 3rem;
  width: 30.06rem;
  height: 9.94rem;
  font-size: 0.88rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #000000;
  opacity: 1;
  text-align: left;
  text-indent: 1.76rem;
}

.creditBox4 {
  position: absolute;
  top: 13.7rem;
  left: 8rem;
  font-size: 1.6rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #000000;
  opacity: 1;
}

.creditBox5 {
  width: 29.63rem;
  height: 8.94rem;
  position: absolute;
  top: 27rem;
  left: 3.3rem;
}

.creditBox6 {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 16.5rem;
  left: 4.5rem;
}

.blueLine1 {
  width: 12rem;
  height: 0.1rem;
  background: #1564db;
  opacity: 1;
  align-self: center;
}

.boxTitle {
  font-size: 1.13rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #000000;
  opacity: 1;
  width: 3.5rem;
}

.creditBox7 {
  width: 28.1rem;
  height: 6.06rem;
  font-size: 0.88rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #000000;
  opacity: 1;
  text-align: left;
  margin-top: 1rem;
}

.creditText1 {
  font-size: 0.8rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #111111;
  opacity: 1;
}

.creditText4 {
  font-size: 1.25rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #111111;
  opacity: 1;
}

.creditText2 {
  font-size: 1.13rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.5rem;
  color: #000000;
  opacity: 1;
  position: absolute;
  left: 9rem;
  top: 1rem;
}

.creditText3 {
  width: 61.75rem;
  height: 2.81rem;
  font-size: 0.7rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.63rem;
  color: #737373;
  opacity: 1;
  position: absolute;
  top: 2.8rem;
  left: 9.2rem;
  text-align: left;
}
</style>