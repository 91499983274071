<template>
<div style="padding-bottom:15rem">
  <div  class="info">
    <div class="info-head">
      <p class="title"></p>
     <ArtText text="大数据决策引擎" backText="Big data decision engine" ></ArtText>
    </div>
    <div class="line"></div>
     <p class="info-desc">
           利用人工智能、大数据、云计算等技术，对接多方征信数据，实现精准企业画像，通过多维度数据分析和模型计算，为用户提供定制化全生命周期风险
管理服务。
          </p>
            <div class="img-card">
            <img src="../assets/img/maininfo1.png" alt="">
          </div>
          </div>
        <div  class="info">
         <div class="info-head">
      <p class="title"></p>
     <ArtText text="政务大数据管理平台" backText="Big data in government affairs" ></ArtText>
    </div>
    <div class="line"></div>
     <p class="info-desc">
           通过数据交换或数据高铁等方式，离线或实时归集自建业务系统数据，形成区县级数仓和专题库，统一公共数据共享服务，强化数据源头管理，提升公 共数据质量，建设一体化智能化公共数据平台，实现数据高度整合、数据能力复用。
          </p>
          </div>
           <div class="img-card2" :style="{backgroundImage:'url('+require('@/assets/img/maininfobg.png')+')'}">
            <div class="img-card3">
               <img src="../assets/img/maininfo2.png" alt="">
            </div>
           
          </div>
          <div  class="info">
          <div class="info-head">
      <p class="title"></p>
     <ArtText text="信用信息采集系统" backText="Big data in government affairs" ></ArtText>
    </div>
    <div class="line"></div>
     <p class="info-desc">
           以“数据库+网络”为核心，依据信用信息主体指标，形成“基本信息+行业信息”的全量信息采集模式，并生成用户信用信息证，解决金融机构尽调 难度大、成本高、效率低的问题。
          </p>
           <div class="img-card">
            <img src="../assets/img/maininfo3.png" alt="">
          </div>
          </div>
          <div  class="info">
          <div class="info-head">
      <p class="title"></p>
     <ArtText text="企业征信平台" backText="Enterprise credit reporting platform" ></ArtText>
    </div>
    <div class="line"></div>
     <p class="info-desc">
           利用大数据风控技术，整合司法、纳税、社保公积金、水电煤缴费、中国银联、信用河南、中原资产及农开集团体系内数据源，打造企业征信平台，为政府部门、银行、担保公司等机构提供量化的、可视化的企业信用决策分析报告。
          </p>
          </div>
           <div class="img-card2" :style="{backgroundImage:'url('+require('@/assets/img/maininfobg.png')+')'}">
            <div class="img-card3">
              <img src="../assets/img/maininfo4.png" alt="">
            </div>
            
          </div>
          
           <div  class="info">
          <div class="info-head">
      <p class="title"></p>
     <ArtText text="普惠金融管理系统" backText="Financial Inclusion Management System" ></ArtText>
    </div>
    <div class="line"></div>
     <p class="info-desc">
           基于“一平台四体系”兰考模式的数字化管理系统，形成县、乡、村三级管理体系，融入智慧党建、数字文旅、惠民便民等服务，通过技术、理念及模 式创新，实现普惠金融助推乡村振兴。
          </p>
           <div class="img-card">
            <img src="../assets/img/maininfo5.png" alt="">
          </div>
  </div>
  </div>
</template>

<script>
import {setSpot} from "@/utils/tools.js"
import ArtText from "@/components/ArtText";
export default {
  components: { ArtText },
  created(){
     setSpot('主要业务-信用信息系统')
  }
}
</script>

<style scoped>
.info{
  box-sizing: border-box;
  padding: 0 4.3rem 0 4.31rem;
  width: 75rem;
  margin: 0 auto;
}
.info-head{
  box-sizing: border-box;
  width: 100%;
  height: 11.06rem;
  text-align: left;
  /* margin-left: 0.5rem; */
  padding-top: 3.5rem;
}
.title{
  font-size: 1.13rem;
  font-weight: bold;
  color: #2D2D2D;
  margin-bottom: 1.2rem;
}
.title-sub{
  font-size: 1.88rem;
  font-weight: 400;
  color: #004DB3;
  margin-top: 1.13rem;
  margin-bottom: 2.44rem;
}
.line{
  display: block;
  width: 29rem;
  height: 1px;
  background-color: #E6E6E6;
 margin-top: -2.2rem;
  margin-bottom: 2rem;
}
.info-body{
  height: 34.56rem;
}
.info-top,
.info-bottom{
  box-sizing: border-box;
  padding-top: 1.13rem;
  display: flex;
}
.info-top div,
.info-bottom div{
  width: 22.94rem;
  height: 12rem;
  margin-right: 14.81rem;
}
.info-bottom div{
  height: 20rem;
}
.left{
  display: flex;
  flex-direction: column;
}
div.info-title{
  width: 100%;
  height: 5.25rem;
  text-align: left;
  position: relative;
}
.num{
  width: 6rem;
  height: 5.25rem;
  font-size: 4.38rem;
  font-weight: bold;
  color: #1564DB;
  opacity: 0.1;
}
.name{
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1.13rem;
  font-weight: bold;
  color: #333333;
}
.info-desc{
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  color: #707070;
  line-height: 1.88rem;
   margin-left: 0.5rem;
}
p {
  text-align: left;
  margin-bottom: 1.13rem;
  width: 66rem;
}
.img-card{
  width: 65rem;
  padding-left: 0.7rem;
}
.img-card2{
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background: no-repeat;
  background-size: cover;
  background-position:center center ;
}
.img-card3{
 width: 65rem;
 padding-left: 0.7rem;
 margin:0 auto;
}
img{
  width: 100%;
}
</style>