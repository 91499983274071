<template>
  <div class="ruralrevital">
    <Header />
    <div style="font-size:0;">
      <img
        :src="require(`@/assets/img/ruralrevital/banner1.png`)"
        style="width:100%;min-width: 75rem;"
        alt=""
      />
    </div>
    <div class="bg">
      <div
        class="w1200"
        style="background: #fff;border-radius: 0.63rem;"
      >
        <Mainbszd></Mainbszd>
      </div>
    </div>
    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import Mainbszd from "@/views/Mainbszd.vue";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
    Mainbszd
  }
};
</script>
<style lang="scss" scoped>
.ruralrevital {
  .bg {
    background: #f2f4f9;
    padding: 3.25rem 0 2.06rem;
  }
}
</style>
