import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Loan from "../views/Loan.vue";
import Contact from "../views/Contact.vue";
import Policy from "../views/Policy.vue";
import Article from "../views/Article.vue";
import Mainbs from "../views/Mainbs.vue";
import Mainbscms from "../views/Mainbscms.vue";
import Mainbshnl from "../views/Mainbshnl.vue";
import Mainbsinfo from "../views/Mainbsinfo.vue";
import Mainbszd from "../views/Mainbszd.vue";
import Credit from "../views/Credit.vue";
import Mainprod from "../views/Mainprod.vue";
import Mainserv from "../views/Mainserv.vue";
import Typicalcase from "../views/Typicalcase.vue";
import Ruralrevital from "../views/Ruralrevital.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "普惠通官网-普及金融 惠及民生",
      content: {
        keywords: "普惠通，普惠金融，兰考模式，金惠普民，乡村振兴",
        description:
          "公司以“普及金融、惠及民生”为理念，以解决传统金融环境下农民、中小微企业，贷款难、信息采集难、风险防控难等痛点为己任，运用大数据、区块链、AI 等技术搭建起数字普惠金融综合服务平台——“普惠通”，通过技术创新、理念创新及模式创新，实现普惠金融助推乡村振兴-普及金融 惠及民生-普惠通"
      }
    }
  },
  {
    path: "/loan",
    name: "loan",
    component: Loan,
    meta: {
      title: "普惠通官网-贷款产品",
      content: {
        keywords: "贷款,极速贷,个人贷,普惠授信,企业贷,银行贷款",
        description:
          "河南省银行贷款产品搜索推荐平台，为河南省个人、企业提供无抵押贷款、抵押贷款、住房贷款、购车贷款等贷款产品推荐，每天有数万用户在这里找到合适、优惠的贷款-普及金融 惠及民生-普惠通"
      }
    }
  },
  {
    path: "/mainserv/:idx?",
    name: "mainserv",
    component: Mainserv,
    meta: {
      title: "普惠通官网-金融服务",
      content: {
        keywords: "普惠通,金融服务,普惠金融,中小微企业,贷款",
        description:
          "充分发挥400多家金融机构合作优势，线上依托普惠通APP及惠e众推分销系统，线下依托专业服务团队，为政、企及个人用户提供全方位金融服务。"
      }
    }
  },
  {
    path: "/mainprod/:ref?",
    name: "mainprod",
    component: Mainprod,
    meta: {
      title: "普惠通官网-金融科技",
      content: {
        keywords: "普惠通APP,大数据展示系统,企业征信平台,乡村振兴产业链金融AI平台,普惠金融综合管理系统",
        description:
          "普惠通以金融科技为基础支撑体，先后研发了普惠通APP、数字金融开放服务平台、企业征信平台、乡村振兴产业链金融AI平台、普惠金融综合管理系统、大数据展示系统、大数据决策引擎和信用信息采集系统等，为金融服务资源与产业振兴的智能联动提供重要技术支撑。"
      }
    }
  },
  {
    path: "/typicalcase",
    name: "typicalcase",
    component: Typicalcase,
    meta: {
      title: "普惠通官网-普惠金融“兰考模式”",
      content: {
        keywords: "普惠通,兰考模式,普惠金融,普惠授信体系,信用信息体系,金融服务体系,风险防控体系",
        description:
          "形成了以“数字普惠金融服务平台为核心，以金融服务体系、普惠授信体系、信用信息体系、风险防控体系为主要内容”的“一平台四体系”普惠金融兰考模式。"
      }
    }
  },
  {
    path: "/ruralrevital",
    name: "ruralrevital",
    component: Ruralrevital,
    meta: {
      title: "普惠通官网-乡村振兴",
      content: {
        keywords: "普惠通,乡村振兴,高标准农田,大宗农产品,供应链,金融,农田",
        description:
          "通过运用云计算、物联网、大数据等新一代信息技术，数字化赋能高标准农田建设，打造农业综合信息服务系统。"
      }
    }
  },
  {
    path: "/mainbs",
    component: Mainbs,
    children: [
      {
        path: "",
        redirect: "/mainbs/mainbscms"
      },
      {
        path: "mainbscms",
        name: "mainbscms",
        component: Mainbscms,
        meta: {
          title: "普惠通官网-普惠金融板块",
          content: {
            keywords: "普惠通,兰考模式,普惠金融管理系统,大数据展示系统,转贷",
            description:
              "普惠金融管理系统是 “一平台四体系”兰考模式的承载工具，由金融服务体系、普惠授信体系、信用信息体系、风险防控体系组成-普及金融 惠及民生-普惠通"
          }
        }
      },
      {
        path: "mainbsinfo",
        name: "mainbsinfo",
        component: Mainbsinfo,
        meta: {
          title: "普惠通官网-信用信息板块",
          content: {
            keywords:
              "普惠通,大数据决策引擎,政务大数据管理平台,信用信息采集系统,企业征信",
            description:
              "利用人工智能、大数据、云计算等技术，为用户提供定制化全生命周期风险管理服务,通过数据交换或数据高铁等方式，建设一体化智能化公共数据平台,以“数据库+网络”为核心，依据信用信息主体指标，形成“基本信息+行业信息”的全量信息采集模式，利用大数据风控技术，提供量化的、可视化的企业信用决策分析报告-普及金融 惠及民生-普惠通 "
          }
        }
      },
      {
        path: "mainbszd",
        name: "mainbszd",
        component: Mainbszd,
        meta: {
          title: "普惠通官网-乡村振兴板块",
          content: {
            keywords: "普惠通,乡村振兴,高标准农田,大宗农产品,供应链金融",
            description:
              "运用云计算、大数据等新一贷信息技术，打造农业综合信息服务系统，为农户、种粮大户、家庭农场提供专属金融融资服务，以定向购销模式开展小麦、玉米、花生等大宗农产品贸易-普及金融 惠及民生-普惠通"
          }
        }
      },
      {
        path: "mainbshnl",
        name: "mainbshnl",
        component: Mainbshnl,
        meta: {
          title: "普惠通官网-便民生活服务板块",
          content: {
            keywords: "普惠通,便民生活,第一书记商城,惠农用工,生活缴费",
            description:
              "构建金融便民惠民服务圈，征集企业用工需求，通过网上发布，精准推送到重点就业群体，围绕乡村居民衣食住行内容，提供便民出行、生活缴费，提升乡村居民生活品质-普及金融 惠及民生-普惠通"
          }
        }
      }
    ]
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: "普惠通官网-关于我们",
      content: {
        keywords: "普惠通,金惠普民,普惠金融,兰考模式,乡村振兴",
        description:
          "公司以“普及金融、惠及民生”为理念，以解决传统金融环境下农民、中小微企业，贷款难、信息采集难、风险防控难等痛点为己任，运用大数据、区块链、AI 等技术搭建起数字普惠金融综合服务平台——“普惠通”，通过技术创新、理念创新及模式创新，实现普惠金融助推乡村振兴-普及金融 惠及民生-普惠通"
      }
    }
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
    meta: {
      title: "普惠通官网-新闻动态",
      content: {
        keywords: "新闻资讯,普惠通,金惠普民,普惠金融,乡村振兴",
        description:
          "普惠通资讯频道，为您提供最新最全最专业的乡村征信、征信、贷款资讯内容，呈现最实用的资讯介绍-普及金融 惠及民生-普惠通"
      }
    }
  },
  {
    path: "/article/:id",
    name: "article",
    component: Article,
    meta: {
      title: "新闻详情-普及金融 惠及民生-普惠通",
      content: {
        keywords: "新闻资讯,普惠通,金惠普民,普惠金融,乡村振兴",
        description:
          "普惠通资讯频道，为您提供最新最全最专业的乡村征信、征信、贷款资讯内容，呈现最实用的资讯介绍-普及金融 惠及民生-普惠通"
      }
    }
  },
  {
    path: "/credit",
    name: "credit",
    component: Credit,
    meta: {
      title: "普惠通官网-企业征信",
      content: {
        keywords:
          "普惠通,信易贷,企业征信,数字金融开放服务平台,基金大数据服务平台",
        description:
          "以企业征信系统、大数据决策引擎为基础，开发数字金融开放服务平台，平台立足纵横连接、开放包容，结合政府产业政策，建设企业综合评分体系，向科创型、中小微企业开放，建成省内金融服务超级门户-普及金融 惠及民生-普惠通"
      }
    }
  }
];
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
