import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;

import { post, get } from "./utils/request";
//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;

Vue.use(ElementUI);

import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR"
});

router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
router.afterEach((to, from, next) => {
  if (
    to.name == "mainbscms" ||
    to.name == "mainbsinfo" ||
    to.name == "mainbszd" ||
    "mainbshnl" == to.name ||
    "mainprod/qyzx" == to.name 
  ) {
    return;
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
