<template>
  <div class="sidebar">
    <div class="totop" @click="scrollToTop">
      <div class="board"></div>
    </div>
    <div class="toapp">
      <div class="board"></div>
    </div>
    <div class="tophone">
      <div class="board">
        <div class="show">电话：4000084007</div>
      </div>
    </div>
    <div class="towechat">
      <div class="board"></div>
    </div>
  </div>
</template>

<script>
export default {

  methods:{
    scrollToTop(){
      window.scrollTo({
          top:0,
          left:0,
          behavior: "smooth"
        })
    }
  }
}
</script>

<style scoped>
.sidebar{
  box-sizing: border-box;
  position: fixed;
  top: 83%;
  margin-top: -8.88rem;
  left: 50%;
  margin-left: 38.5rem;
  width: 3.75rem;
  height: 14.06rem;
  background: #FFFFFF;
  box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 1rem;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
    .sidebar {
      margin-left: 30.5rem;
    }
}
.sidebar div{
  width: 3.75rem;
  height: 3.5rem;
  border-bottom: 1px solid #EEEEEE;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.totop{
  background-image: url("../assets/img/top_arrow.png");
  background-size: 3rem 3rem;
}
.sidebar div .board{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.toapp{
  background-image: url("../assets/img/app_icon.png");
  position: relative;
}
.toapp:hover{
  background: #D83023;
}
.toapp:hover .board{
  background-image: url("../assets/img/app_icon_white.png");
}

.tophone{
  background-image: url("../assets/img/phone_icon.png");
}
.tophone:hover{
  background: #D83023;
}
.tophone:hover .board{
  position: relative;
  background-image: url("../assets/img/phone_icon_white.png");
}
.tophone .show{
  display: none;
}
.tophone:hover .show{
  display: block;
  position: absolute;
  top: 0;
  left: 3.75rem;
  width: 10.94rem;
  height: 3.75rem;
  line-height: 3.75rem;
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 2px 2px 4px;
}
/* 
.tohelp{
  background-image: url("../assets/路径 116.png");
}
.tohelp:hover{
  background: #1564DB;
}
.tohelp:hover .board{
  background-image: url("../assets/路径 190.png");
} */

.towechat{
  background-image: url("../assets/img/wechat_icon.png");
  position: relative;
}
.towechat:hover{
  background: #D83023;
}
.towechat:hover .board{
  background-image: url("../assets/img/wechat_icon_white.png");
}

.sidebar div.towechat,
.sidebar div.towechat div{
  border: none;
}
.toapp:hover::after{
  width: 11rem;
  height: 13rem;
  position: absolute;
  left: -10.8125rem;
  top: -0.125rem;
  content: "";
  display: block;
  background-image: url("../assets/img/app.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.towechat:hover::after{
  width: 11rem;
  height: 13rem;
  position: absolute;
  left: -10.8125rem;
  top: -9.125rem;
  content: "";
  display: block;
  background-image: url("../assets/img/wechat1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.tophone:hover .show{
  display: block;
  position: absolute;
  top: 0;
  left: -10.94rem;
  width: 10.94rem;
  height: 3.75rem;
  line-height: 3.75rem;
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 2px 2px 4px;
}
</style>