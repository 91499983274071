<template>
  <div id="container" class="cms">
    <div class="sys-data" >
      <div>
         <p class="sys-head"></p>
        <!-- <p class="sys-name">普惠金融管理系统</p> -->
          <ArtText text="普惠金融管理系统" backText="Management system" ></ArtText>
      </div>
      <div style="display:flex;justify-content: space-between;">
        <div class="sys-left">
       
        <p class="line"></p>
        <p class="sys-desc" id="scms">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;普惠金融管理系统是 “一平台四体系”兰考模式的承载工具，由金 融服务体系、普惠授信体系、信用信息体系、风险防控体系组成。该系 统通过采集汇总农村信用信息数据，搭建风险防控综合模型，为数字普 惠金融服务站提供全线上解决方案。系统涵盖贷款申请、资质审核、银 行审批、订单跟踪、风险警示等功能，紧密连接农户、村协管员、政府 基层管理员、银行等多方参与者，为普惠授信业务全流程的顺利开展提 供科技服务和平台支撑。
        </p>
      </div>
      <div class="sys-right1">
        <img src="../assets/img/source.png" alt="" />
      </div>
      </div>
      
    </div>
    <div class="sys-admin" >
      <div class="sys-left">
        <p class="sys-head1"></p>
        <!-- <p class="sys-name">普惠金融大数据展示系统</p> -->
         <ArtText text="普惠金融大数据展示系统" backText="Big data display system" ></ArtText>
        <p class="line"></p>
        <p class="sys-desc" style="width:40rem" >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;大数据展示系统使金融数据可视化，辖区内数据实时更新汇总，各类数据一目了然，帮助当地政府、人民银行实时了解辖区内发生的金融交易行为及数据。
        </p>
      </div>
      <div class="sys-right2">

        <!-- <div class="img-list">
          <img src="../assets/img/main1.png" alt="">
          <p>普惠授信汇总</p>
        </div>
        <div class="img-list">
          <img src="../assets/img/main2.png" alt="">
          <p>平台数据展示</p>
        </div>
        <div class="img-list">
          <img src="../assets/img/main3.png" alt="">
          <p>风险预警</p>
        </div>
        <div class="img-list">
          <img src="../assets/img/main4.png" alt="">
          <p>站点汇总情况</p>
        </div>
        <div class="img-list">
          <img src="../assets/img/main5.png" alt="">
          <p>电子信用凭证</p>
        </div>
        <div class="img-list">
          <img src="../assets/img/main6.png" alt="">
          <p>系统展示</p> -->

        <!-- </div>  -->

        <img src="../assets/img/main.png" alt="">
      </div>
    </div>
    <div class="sys-app">
      <div>
        <p class="sys-head"></p>
        <!-- <p class="sys-name">普惠通APP</p> -->
         <ArtText text="普惠通APP" backText="PUHUITONG" ></ArtText>
         <p class="line"></p>
        <img src="../assets/img/puhuiApp.png" alt="" style="width:30rem;margin-left: -0.8rem;image-rendering: -webkit-optimize-contrast;">
      </div>
      <div>
        <p class="sys-head" style="margin-left:6.9rem"></p>
        <!-- <p class="sys-name"  style="margin-left:7.5rem">金融服务</p> -->
        <div  style="margin-left:6.9rem"> 
        <ArtText text="金融服务" backText="Financial services"></ArtText>
        </div>
        
         <p class="line" style="margin-left:7.5rem"></p>
          <img  src="../assets/img/finance.png" alt="" style="width:30rem;margin-left: 7rem;image-rendering: -webkit-optimize-contrast;">
      </div>
    </div>
    <div class="sys-loan">
       <p class="sys-head"></p>
       <ArtText text="转贷业务" backText="On-lending business"></ArtText>
         <p class="line"></p>
          <p class="sys-desc" style="width:66rem">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;借助金融科技重塑转贷金融服务形态、流程和模式。采用银行监管模式，建立转贷企业、资金方、银行方、运营平台方共同参与的线上化转贷平台，引 入资金竞价机制，拓宽民营企业转贷资金来源，降低转贷企业资金成本。
        </p>
        <img src="../assets/img/zhuandai.png" alt="">
    </div>

  </div>
</template>

<script>
import {setSpot} from "@/utils/tools.js"
import ArtText from "@/components/ArtText";
export default {
  components: { ArtText },
  created(){
    setSpot('主要业务-普惠金融管理系统')
  }
};
</script>

<style scoped>
p {
  text-align: left;
  margin-bottom: 1.13rem;
}
.cms {
  width: 74.94rem;
  /* height: 45.63rem; */
  box-sizing: border-box;
  padding: 0rem 4.31rem;
  margin-bottom: 3.57rem;
}
.sys-data {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.sys-app{
   display: flex;
  /* justify-content: space-between;
  width: 44rem; */
}
.sys-loan{
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}
.sys-head {
  margin-top: 3.69rem;
  color: #2d2d2d;
  font-weight: bold;
  font-size: 1.13rem;
}
.sys-head1 {
  margin-top: 2.5rem;
  color: #2d2d2d;
  font-weight: bold;
  font-size: 1.13rem;
}
.sys-name {
  font-size: 1.88rem;
  color: #004db3;
}
.sys-desc {
  width: 31.06rem;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #515a67;
}
.sys-right1 {
  box-sizing: border-box;
  /* float: right; */
  width: 40rem;
  height: 18.25rem;
  margin-top: 3rem;

}
div.sys-right2 {
  box-sizing: border-box;
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-bottom: 8rem; */
}
img {
  width: 100%;
  height: auto;
}
p.line {
  width: 28.44rem;
  height: 1px;
  background: #e6e6e6;
  margin-top: 1.5rem;
}
.sys-admin {
  width: 66.5rem;
  margin-top: 3rem;
}
.img-list{
  margin-bottom: 2.29rem;
}
.img-list img{
  width: 33.07rem;
  height: 16.25rem;
}
.img-list p{
  text-align: center;
  font-size: 0.88rem;
  color: #707070;
  margin-top: 1.69rem;
}
</style>