<template>
  <div class="policy">
    <Header />
    <div class="banner" v-if="articleBannerUrl.img" :style="'background-image:url('+articleBannerUrl.img+')'">
    </div>
    <div id="container" class="article">
      <h2>{{articleDetail.title}}</h2>
      <div class="markdown-body" style="text-align:left">
        <VueMarkdown :source="articleDetail.content"></VueMarkdown>
      </div>
    </div>
    <Sidebar />
    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import VueMarkdown from 'vue-markdown'
import { setSpot } from '@/utils/tools.js'

export default {
  name: 'Mainbs',
  data() {
    return {
      articleDetail: '',
      articleBannerUrl: ''
    }
  },
  components: {
    Header,
    Sidebar,
    Footer,
    VueMarkdown
  },
  async created() {
    const articleBannerUrl = await this.$get(
      '/operation-management/api/banner?plate=webarticle'
    )
    this.articleBannerUrl = articleBannerUrl[0]

    const id = this.$route.params.id
    const articleDetail = await this.$get(
      `/operation-management/api/articles/${id}`
    )
    this.articleDetail = articleDetail
    // 设置meta标签
    if (articleDetail.seo_title) {
      document.title = articleDetail.seo_title
    }
    if (articleDetail.seo_description) {
      if(document.querySelector('meta[name="description"]')){
        document.querySelector('meta[name="description"]').setAttribute('content',articleDetail.seo_description)
      }else{

        let meta = document.createElement('meta')
        meta.content = articleDetail.seo_description
        meta.name = 'description'
        document.getElementsByTagName('head')[0].appendChild(meta)
      }
    }
    if (articleDetail.keywords) {
      if(document.querySelector('meta[name="keywords"]')){
        document.querySelector('meta[name="keywords"]').setAttribute('content', articleDetail.keywords)
      }else{

        let meta = document.createElement('meta')
        meta.content = articleDetail.keywords
        meta.name = 'keywords'
        document.getElementsByTagName('head')[0].appendChild(meta)
      }
    }
    setSpot('文章详情', `文章id${this.$route.params.id}`)
  }
}
</script>

<style scoped>
.banner {
  box-sizing: border-box;
  width: 100%;
  height: 13.06rem;
  padding: 4.25rem 0 4.25rem 2.38rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.title-zh {
  text-align: left;
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}
.title-en {
  text-align: left;
  font-size: 1rem;
  color: #f3f3f3;
  opacity: 0.5;
}

#container.article {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1.75rem 4.56rem 3.13rem 2rem;
  display: flex;
  flex-direction: column;
  line-height: 2rem;
}

.art-head {
  margin: 0 auto;
}
</style>