<template>
  <div id="container" class="hnchain">
    <div>
      <p class="market"></p>
      <ArtText
        text="便民生活服务板块"
        backText="Convenient life services"
      ></ArtText>
      <p class="line"></p>
    </div>
    <div class="box">
      <div class="left">
        <div class="intro">
         <p class="sys-head">惠民补贴</p>
         <p class="sys-text">足不出户就能了解补贴政策、查询补贴发放时间和发放金额，减轻群众办 事成本，提高便捷度，提升政府部门办事效能和政务服务满意度。</p>
         <p class="sys-head">商城</p>
         <p class="sys-text">推动特色农副产品和进口优质商品的互联互通，深度融入国际国内双循环。</p>
         <p class="sys-head">惠农用工</p>
         <p class="sys-text">征集企业用工需求，通过网上发布，精准推送到重点就业群体，满足劳动 者求职和企业用工需求。</p>
         <p class="sys-head">高频生活场景</p>
         <p class="sys-text">围绕乡村居民衣食住行内容，提供便民出行、生活缴费、医疗问诊，提升 乡村居民生活品质。</p>
        </div>
      </div>
      <div class="right">
        <img src="../assets/img/hnl_big.png" alt=""   style="image-rendering: -webkit-optimize-contrast;"/>
      </div>
    </div>
  </div>
</template>

<script>
import { setSpot } from "@/utils/tools.js";
import ArtText from "@/components/ArtText";
export default {
  components: { ArtText },
  name: "hnl",
  created() {
    setSpot("主要业务-惠农链");
  },
};
</script>

<style scoped>
.hnchain {
  width: 74.94rem;
  box-sizing: border-box;
  padding: 3.5rem 0 0 4.31rem;
  display: flex;
  flex-direction: column;
}
p {
  text-align: left;
}
.box{
  display:flex ;
  justify-content: space-between;
}
.sys-head {
  margin-top: 3.69rem;
  color: #2d2d2d;
  font-weight: bold;
  font-size: 1.13rem;
}
.sys-text{
   font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #707070;
  opacity: 1;
}
.left {
  width: 27.69rem;
  margin-bottom: 8.94rem;
}
.market {
  font-size: 1.13rem;
  font-weight: bold;
  color: #2d2d2d;
  margin-bottom: 1.13rem;
}
.name {
  font-size: 1.88rem;
  line-height: 1.5rem;
  color: #004db3;
  margin-bottom: 1.81rem;
}
.line {
  width: 28.44rem;
  height: 1px;
  background-color: #e6e6e6;
  margin-top: 1rem;
}
.intro {
  width: 25.63rem;
  opacity: 1;
}
.right img {
  margin-top: 3rem;
  margin-left: 3.34rem;
  width: 39.94rem;
  /* height: 22.25rem; */
}
.img{
  width: 100%;
}
</style>