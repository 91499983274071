<template>
  <div class="mainprod">
    <Header />
    <img
      class="margin-bottom-1"
      src="@/assets/img/mainprod/banner1.png"
      style="width:100%;min-width: 75rem;"
      alt=""
    />
    <div class="w1200 margin-bottom-3">
      <div class="rc">
        <div style="text-align:left;">
          <div class="title margin-bottom-1">
            普惠通APP(数字普惠金融综合服务平台)
          </div>
          <div class="red-line margin-bottom-3"></div>
          <div class="content">
            截止2024年1月底 <br />
            普惠通APP各大应用平台累计下载1200万人次<br />
            累计注册人数突破600万<br />
            实名用户超189.2万人<br />
            上架434家金融机构的信贷、理财、便民支付缴费等<br />普惠类产品2096款<br />
            贷款金额超170亿元
          </div>
        </div>
        <div>
          <img class="img" src="@/assets/img/mainprod/pic2.png" alt="" />
        </div>
      </div>
    </div>

    <div class="bg1 padding-top-5">
      <div class="w1200">
        <div class="title margin-bottom-1">数字金融开放服务平台</div>
        <div class="red-line margin-auto margin-bottom-3"></div>
        <div class="content  margin-bottom-4">
          该平台以数据、特别是信用大数据为基础，以数字智能化风控技术为依托，以风险分担机制为杠杆，打造数字化、开放式金融服务平台<br />通过数据聚合、系统直连、智能风控、风险补偿等创新手段与补贴政策，解决中小微企业融资难、融资贵问题，加快激活当地经济活力
        </div>
        <img class="img" src="@/assets/img/mainprod/pic3.png" alt="" />
      </div>
    </div>
    <div class=" padding-top-5 margin-bottom-5" ref="qyzx">
      <div class="w1200">
        <div class="title margin-bottom-1">企业征信平台</div>
        <div class="red-line margin-auto margin-bottom-3"></div>
        <div class="content  margin-bottom-4">
          企业征信平台依托中国司法、中国电力、朴道征信、百行征信、天眼查、河南省政务数据、郑州市政务数据等海量涉企信用信息<br />打造各类企业征信产品，精准企业信用“画像”，为政府、金融机构、企业提供征信服务。通过该平台可实现线上查询，实时反馈企业信用状况
        </div>
        <img
          class="img margin-bottom-4"
          src="@/assets/img/mainprod/pic4.png"
          alt=""
        />
        <div class="rac margin-bottom-3">
          <div>
            <div class="img-wrap">
              <img src="@/assets/img/creditpicture1.png" alt="" />
            </div>
            <div class="sys-head">数据维度最全面</div>
          </div>
          <div>
            <div class="img-wrap">
              <img src="@/assets/img/creditpicture2.png" alt="" />
            </div>
            <div class="sys-head">产品类型最丰富</div>
          </div>
          <div>
            <div class="img-wrap">
              <img src="@/assets/img/creditpicture3.png" alt="" />
            </div>
            <div class="sys-head">技术手段最先进</div>
          </div>
          <div>
            <div class="img-wrap">
              <img src="@/assets/img/creditpicture4.png" alt="" />
            </div>
            <div class="sys-head">服务方式最多样</div>
          </div>
        </div>

        <div class="btn " @click="goUrl('http://cip.phjrt.com')">
          点击查看企业征信官网
        </div>

        <div class="rsc">
          <div class="bg3  card-wrap">
            <div class="rc">
              <div><img src="@/assets/img/mainprod/pic8.png" alt="" /></div>
              <div class="title">
                智慧岛数字金融开放服务平台
              </div>
            </div>

            <div class="content">
              智慧岛数字金融开放服务平台，依托国家及省“信易贷”平台，建设“智慧岛”数字金融开放服务平台，借鉴国内各地金融服务平台建设经验，以数据、特别是信用大数据为基础，以智能化风控技术为依托，以风险分担机制为杠杆，以“科技贷”、“信用贷”为抓手，打造数字化、开放式金融服务平台，通过数据聚合、系统直连、智能风控、风险补偿等创新手段与补贴政策，解决中小微企业融资难、融资贵问题，支持中原科技城园区企业发展。
            </div>

            <div class="img">
              <img src="@/assets/img/creditPicture8.png" alt="" />
            </div>
            <div class="btn-wrap" @click="goUrl('https://www.ccftis.net')">
              点击查看
            </div>
          </div>

          <div class="bg3  card-wrap">
            <div class="rc">
              <div><img src="@/assets/img/mainprod/pic9.png" alt="" /></div>
              <div class="title">
                智慧岛基金大数据服务平台
              </div>
            </div>
            <div class="second-title">定位</div>
            <div class="content">
              基金大数据平台目标打造全方位、高水平、数字化的金融服务平台，以构建中原科技城内科技企业的股权融资体系。利用人工智能、大数据、知识图谱等高新技术，打通“三网五数三中心”实现供需智能匹配、信息共享、在线图谱、知识库、分析决策等，构建“智慧基金”。
            </div>
            <div class="second-title">目标</div>
            <div class="content ">
             通过基金信息服务、基金招商服务、基金银行投贷联动等功能，实现为基金投资人、基金管理人、被投企业等各方提供“互联网+”服务和业务咨询、项目尽调、人才培养、投融资对接、资产管理等增值服务，构建金融大数据服务体系。
            </div>
            <div class="btn-wrap" @click="goUrl('https://www.ccvcpe.net/')">
              点击查看
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg2 padding-top-5 padding-bottom-5">
      <div class="w1200">
        <div class="title margin-bottom-1">乡村振兴产业链金融AI平台</div>
        <div class="red-line margin-auto margin-bottom-3"></div>
        <div class="content  margin-bottom-4">
          该平台借助大数据AI、物联网及区块链等技术实现土地确权、流转及种植、收储贸易等第三方风控监管<br />为本地化农商行、城商行提供贷前、贷中以及贷后全流程风险闭环、交易闭环管理，为大行提供风控决策依据
        </div>
        <img class="img" src="@/assets/img/mainprod/pic5.png" alt="" />
      </div>
    </div>

    <div class=" padding-top-5 margin-bottom-5">
      <div class="w1200">
        <div class="title margin-bottom-1">普惠金融综合管理系统</div>
        <div class="red-line margin-auto margin-bottom-3"></div>
        <div class="content margin-bottom-4">
          普惠金融综合管理系统是政府部门做好辖区普惠金融管理工作的重要技术支撑<br />“县、乡、村”三级管理人员可实时查看并管理辖区普惠金融工作推进情况，已在兰考县、祥符区、确山县、夏邑县等地部署
        </div>
        <img class="img" src="@/assets/img/mainprod/pic6.png" alt="" />
      </div>
    </div>

    <div class="bg1 padding-top-5 padding-bottom-3">
      <div class="w1200">
        <div class="title margin-bottom-1">大数据展示系统</div>
        <div class="red-line margin-auto margin-bottom-3"></div>
        <div class="content  margin-bottom-4">
          打通数据壁垒，统筹多方资源，实现普惠金融管理全网数据在移动、桌面、大屏三端联动展现，构建互联互通的完整系统
        </div>
        <img class="img" src="@/assets/img/mainprod/pic7.png" alt="" />
      </div>
    </div>
    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Header,
    Footer,
    Sidebar
  },
  data() {
    return {};
  },
  mounted() {
    if (this.$route?.params?.ref) {
      this.$refs[this.$route?.params?.ref].scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    }
  },
  methods: {
    goUrl(url) {
      // window.open.href = url;
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
$count: 5;
@for $i from 0 through $count * 2 {
  .margin-bottom-#{ $i } {
    margin-bottom: $i * 1rem;
  }
  .padding-bottom-#{ $i } {
    padding-bottom: $i * 1rem;
  }
  .padding-top-#{ $i } {
    padding-top: $i * 1rem;
  }
}
.mainprod {
  box-sizing: border-box;
  // .margin-bottom-1 {
  //   margin-bottom: 1rem;
  // }
  // .margin-bottom-3 {
  //   margin-bottom: 3rem;
  // }
  // .margin-bottom-4 {
  //   margin-bottom: 4rem;
  // }
  .img {
    width: 80%;
  }
  .padding-top-5 {
    padding-top: 5rem;
  }
  .title {
    white-space: nowrap;
    font-size: 1.5rem;
    font-family: PingFangSC-Medium;
    line-height: 2.63rem;
    color: #191e4c;
  }
  .content {
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 1.88rem;
    color: #666666;
  }
  .red-line {
    width: 3.63rem;
    height: 0.25rem;
    background: #d32e25;
  }
  .bg1 {
    background: #f2f4f9;
  }
  .indent-2 {
    text-indent: 2em;
  }
  .bg2 {
    background: url("../assets/img/mainprod/bg2.png") 100%;
  }
  .img-wrap {
    width: 11.84rem;
    height: 9.1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sys-head {
    margin-top: 2rem;
    font-size: 1.13rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 1.88rem;
    color: #333333;
  }
  .btn {
    margin: 0 auto 3.75rem;
    cursor: pointer;
    width: 12.94rem;
    height: 2.75rem;
    background: linear-gradient(90deg, #ec5652 0%, #dd2e21 50%, #ee6051 100%);
    opacity: 1;
    border-radius: 0.25rem;
    text-align: center;
    line-height: 2.75rem;
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  .bg3 {
    text-align: left;
    width: 36.25rem;
    height: 37.75rem;
    background: url("../assets/img/mainprod/bg3.png") 100%;
    border-radius: 0.63rem;
    box-sizing: border-box;
    padding: 2.75rem 2.5rem 2.06rem 1.88rem;
  }
  .card-wrap {
    .title {
      margin-left: 2rem;
      font-size: 1.38rem;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 1.88rem;
      color: #333333;
    }
    .second-title {
      margin-top: 2.22rem;
      font-size: 1.25rem;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 1.75rem;
      color: #333333;
    }
    .content {
      margin-top: 1rem;
      margin-bottom: 1.56rem;
      text-align: justify;
    }
    .img {
      width: 100%;
      img {
        width: 100%;
        border-radius: 0.38rem;
      }
    }
    .btn-wrap {
      margin: 2.04rem auto 0;
      width: 7.63rem;
      height: 2.75rem;
      border: 1px solid #707070;
      border-radius: 0.25rem;
      text-align: center;
      font-size: 1rem;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 2.75rem;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
