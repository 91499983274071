import axios from 'axios';
// import qs from 'qs'
// import { Message } from 'element-ui';

axios.defaults.timeout = 50000;
axios.defaults.baseURL ='';
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['appOs'] = 'webpc'
//http request 拦截器
axios.interceptors.request.use(
  config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    config.data = JSON.stringify(config.data);
    // config.headers = {
    //   'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'
    // }
    // if(token){
    //   config.params = {'token':token}
    // }
    return config;
    // config.headers = {
    //   'appOs' : 'webpc'
    // }
  },

  error => {
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    // if(response.data.errCode ==2){
    //   router.push({
    //     path:"/login",
    //     query:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
    //   })
    // }
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url,params={}){
  return new Promise((resolve,reject) => {
    axios.get(url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post(url,params = {}){
   return new Promise((resolve,reject) => {
     axios.post(url, params)
          .then(response => {
            resolve(response.data);
          },err => {
            reject(err)
          })
   })
 }


 /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.put(url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}