<template>
  <div class="contact-wrapper">
    <Header />
    <img
      src="@/assets/img/contact/banner.png"
      style="width: 100%;min-width: 75rem; "
      alt="contact us banner"
    />

    <div class="about-wrap w1200">
      <div class="title-wrap">关于普惠通</div>
      <div class="red-line-h"></div>
      <div class="tabs-wrap rsc">
        <div
          class="tab-item cursor-pointer"
          :class="activeTab == 1 ? 'active' : ''"
          @mouseover="handleTab(1)"
        >
          平台源起
        </div>
        <div
          class="tab-item cursor-pointer"
          :class="activeTab == 2 ? 'active' : ''"
          @mouseover="handleTab(2)"
        >
          发展
        </div>
        <div
          class="tab-item cursor-pointer"
          :class="activeTab == 3 ? 'active' : ''"
          @mouseover="handleTab(3)"
        >
          深化
        </div>
      </div>
      <div class="about-content-wrap rsc" v-show="activeTab == 1">
        <div class="content-img-wrap" style="margin-left: -2rem;">
          <img
            src="@/assets/img/contact8.png"
            style="width: 24.38rem; height: 30.5rem;"
            alt="通知文件"
          />
        </div>
        <div class="text-wrap">
          <p>
            河南省兰考县是我国县域经济的典型代表。习近平总书记前往调研时，要求兰考要准确把握县域治理特点和规律，在县域改革中走出一条好路子。
          </p>
          <p>
            为有效破解传统金融服务不足、服务效率低、融资难、信用缺失等突出问题。2016年8月，人民银行郑州中心支行积极探索，开发上线了微信公众号形式的“普惠金融一网通”移动金融服务平台，涵盖支付服务、便民查询、金融宣传三大类金融服务，在贯彻落实国务院普惠金融发展规划的同时，提升金融服务覆盖率、可得性和满意度。
          </p>
          <p>
            2016年12月，兰考获批成为全国首个国家级普惠金融改革试验区。随着试验区建设的深化和金融服务需求的不断增多，河南省在充分调研论证的前提下，决定引入第三方市场主体建设运营“普惠金融一网通”移动金融服务平台。
          </p>
          <p>
            经过多方精心筹备，2017年6月，普惠通科技（河南）股份有限公司注册成立，被中国人民银行郑州中心支行确定为“普惠金融一网通”建设、运营方。通过几个月的精心打磨，同年10月，兰考普惠金融综合服务平台华丽转身，顺利从“普惠金融一网通”微信公众号升级为“普惠通”APP。
          </p>
        </div>
      </div>
      <div class="about-content-wrap rsc" v-show="activeTab == 2">
        <div class="content-img-wrap" style="margin-left: -2rem;">
          <img
            src="@/assets/img/contact10.png"
            style="width: 24.38rem; height: 30.5rem;"
            alt="通知文件"
          />
        </div>
        <div class="text-wrap">
          <p>
            2017年6月，普惠通科技（河南）股份有限公司（简称普惠通）在中国人民银行郑州中心支行指导下注册成立。2020年7月，省属骨干金融企业中原资产对普惠通进行并购重组，注册资本增加至1亿元。2023年3月，省管重点农业企业河南省农业综合开发有限公司战略入股。
          </p>
          <p>
            公司成立之初，基于移动互联网等数字化信息技术和金融科技手段，为兰考试验区普惠金融推广工作提供重要技术支撑，建立包括数字普惠金融综合服务平台（普惠通APP)、普惠金融综合管理系统及大数据展示系统在内的普惠金融推广技术体系，打造线上金融超市，打通普惠金融在县域农村的“最后一公里”，逐步形成了以数字普惠金融综合服务平台（普惠通APP)为核心，以普惠授信体系、信用信息体系、金融服务体系、风险防控体系为基本内容的“一平台四体系”普惠金融“兰考模式”。普惠金融“兰考模式”先后入选中组部攻坚克难案例、中央党校、清华大学教学案例、省委省政府2019年度十大经济体制改革案例。
          </p>
          <p>
            公司自成立以来，始终把技术和创新作为高质量发展的关键引擎。截至目前，已获得ISO20000
            信息技术服务管理体系认证、ISO9001质量管理体系认证和知识产权管理体系认证，拥有21项软件著作权，“普惠通”系统通过三级等保测评。2021年入选工信部中国信通院“卓信大数据计划”，入库郑州科技型中小企业。2022年4月，入库国家“科技型中小企业”名单，获得知识产权管理体系认证；8月，被省工信厅确定为2022年度第一批DCMM（数据管理能力成熟度评估模型）贯标试点企业。
          </p>
        </div>
      </div>
      <div class="about-content-wrap rsc" v-show="activeTab == 3">
        <div class="text-wrap">
          <p>
            近年来，普惠通在全面复制推广普惠金融“兰考模式”的同时，紧紧围绕“普及金融
            惠及民生”的发展理念，在持续转型升级中，聚焦核心业务发展。逐步构建起以金融科技为基础支撑体，以金融服务和乡村振兴为核心业务板块的“一体两翼”发展格局。
          </p>
          <p>
            <b>——金融科技产品赋能地方经济发展。</b
            >先后开发迭代普惠通APP、数字金融开放服务平台、企业征信平台、乡村振兴产业链金融AI平台、普惠金融综合管理系统、大数据展示系统、大数据决策引擎和信用信息采集系统等，可为各地区金融服务资源与产业振兴的智能联动提供重要技术支撑，通过技术和金融双重赋能，促进当地经济发展。
          </p>
          <p>
            <b>——为政、企及个人用户提供全方位金融服务。</b
            >充分发挥400多家金融机构合作优势，线上依托普惠通APP及惠e众推分销系统，线下依托专业服务团队，为政、企及个人用户提供全方位金融服务。截至2023年3月底，普惠通APP各大应用平台累计下载1200万人次，累计注册人数突破600万，实名用户超189.2万人。上架427家金融机构的信贷、理财、便民支付缴费等普惠类产品1979款.贷款金额超150亿元。
          </p>
          <p>
            <b
              >——顺应国家乡村振兴发展战略大趋势，开展高标准农田托管、涉农电商及惠民用工服务。高标准农田托管服务：</b
            >通过金融赋能，围绕高标准农田流转、运营及综合服务，打造乡村振兴产业链金融AI平台，为高标准农田规模化运营提供从种子到粮食贸易完整的生态链综合服务。<b>涉农电商服务：</b>围绕县域“聚集性、规模性、特色性”进行产业振兴探索，开展涉农电商业务，打造以供应链为核心，孵化选品为基础，电商赋能为支撑，供应链金融为驱动的惠农涉农产业链综合服务模式。<b>惠民用工服务：</b>依托平台县域基层用户资源，汇聚省内外企业用工需求和高校就业需求，通过公众号惠民蓝领普工技能提升助手和微信社群，发布就业信息，提供就业及技能提升指导，同时，可承办用工服务活动，打通用工、就业双向通道，精准服务重点用工、就业群体。
          </p>
          <p>
            2022年2月，《河南省“十四五”数字经济和信息化发展规划》发布，明确提出，“推广使用‘普惠通’平台”。普惠通已逐渐成为金融服务业数字化转型过程中非常重要的一员。
          </p>
        </div>
      </div>
      <div class="about-img ">
        <img
          src="@/assets/img/contact/img4.png"
          style="width: 100%;min-width: 75rem; height: 14.81rem;"
          alt="aboutimg"
        />
      </div>
    </div>

    <div
      class="development-wrap "
      :style="{ height: imgnum > 2 ? '77.5rem' : '57.5rem' }"
    >
      <img
        src="@/assets/img/contact/bg3.png"
        style="width: 100%;min-width: 75rem; position: absolute;top: 0;left: 0;z-index: -1;"
        :style="{ height: imgnum > 2 ? '77.5rem' : '57.5rem' }"
        alt=""
      />
      <!-- <img src="@/assets/img/contact/bg3_reverse.png" v-if="imgnum>2" style="width: 100%;min-width: 75rem; height: 30.6875rem;position: absolute;top: 67.5rem;left: 0;z-index: -1;" alt=""> -->
      <div class="title-wrap">发展历程</div>
      <div class="red-line-h"></div>
      <div class="time-line-wrap w1200 ">
        <div
          class="lineH"
          style="position:absolute;top:5.3rem;left: 0;z-index: -1;"
        ></div>
        <div class="rac">
          <div
            class="year-wrap csc"
            :class="yearTab == '2023' ? 'active' : ''"
            @click="handleYearTab('2023')"
          >
            <div class="text-wrap">2023</div>
            <div class="dot-wrap"></div>
          </div>
          <div
            class="year-wrap csc"
            :class="yearTab == '2022' ? 'active' : ''"
            @click="handleYearTab('2022')"
          >
            <div class="text-wrap">2022</div>
            <div class="dot-wrap"></div>
          </div>
          <div
            class="year-wrap csc"
            :class="yearTab == '2021' ? 'active' : ''"
            @click="handleYearTab('2021')"
          >
            <div class="text-wrap">2021</div>
            <div class="dot-wrap"></div>
          </div>
          <div
            class="year-wrap csc"
            :class="yearTab == '2020' ? 'active' : ''"
            @click="handleYearTab('2020')"
          >
            <div class="text-wrap">2020</div>
            <div class="dot-wrap"></div>
          </div>
          <div
            class="year-wrap csc"
            :class="yearTab == '2019' ? 'active' : ''"
            @click="handleYearTab('2019')"
          >
            <div class="text-wrap">2019</div>
            <div class="dot-wrap"></div>
          </div>
          <div
            class="year-wrap csc"
            :class="yearTab == '2018' ? 'active' : ''"
            @click="handleYearTab('2018')"
          >
            <div class="text-wrap">2018</div>
            <div class="dot-wrap"></div>
          </div>
          <div
            class="year-wrap csc"
            :class="yearTab == '2017' ? 'active' : ''"
            @click="handleYearTab('2017')"
          >
            <div class="text-wrap">2017</div>
            <div class="dot-wrap"></div>
          </div>
        </div>
      </div>

      <div class="content-list-wrap w1200">
        <!-- <div class="content-card">
          <img :src="img" alt="大事件图片" class="img-item">
          <div class="title-item">2022年2月</div>
          <div class="desc-item">省委办公厅莅临调研普惠金融助力乡村振兴实践，形成内参供副省级以上
            领导参考，并将普惠通普惠金融实践入选省委办公厅上报中央办公厅典型
            案例。</div>
        </div> -->
        <div class="content-card" v-for="item in showBigList" :key="item.id">
          <div class="img-item">
            <img
              :src="item.img"
              alt="大事件图片"
              style="width:36.875rem;height:14.375rem"
            />
          </div>

          <div class="title-item">{{ item.year }}</div>
          <div class="desc-item">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <div class="information-wrapper w1200 ra">
      <div class="first-wrap">
        <div
          style="font-size: 2.3rem;
font-weight: bold;
color: #DD2E21;
line-height: 2.63rem;"
        >
          CONTACT US
        </div>
        <div
          style="font-size: 1.88rem;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #191E4C;
line-height: 2.63rem;"
        >
          联系我们
        </div>
        <div
          style="font-size: 1.25rem;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #333333;
line-height: 2.63rem;"
        >
          普及金融 惠及民生
        </div>
      </div>
      <div class="second-wrap">
        <div>
          <img
            src="@/assets/img/contact/dianhua.png"
            style="width:2.25rem;height:1.94rem;"
            alt="logo"
          />
        </div>
        <div
          style="font-size: 1.25rem;
font-weight: bold;
color: #333333;
line-height: 2.63rem;"
        >
          联系方式
        </div>
        <div
          style="font-size: 1.78rem;
font-weight: bold;
color: #333333;
line-height: 2.63rem;"
        >
          0371-55597792
        </div>
        <div
          style="font-size: 0.88rem;
font-weight: 500;
color: #999999;
line-height: 2rem;"
        >
          邮箱：phtkjgf@126.com
        </div>
        <div
          style="font-size: 0.88rem;
font-weight: 500;
color: #999999;
line-height: 2rem;"
        >
          官方客服电话：4000084007
        </div>
      </div>
      <div class="third-wrap">
        <div>
          <img
            src="@/assets/img/contact/loc.png"
            style="width:1.94rem;height:2.38rem;"
            alt="logo"
          />
        </div>
        <div
          style="font-size: 1.25rem;
font-weight: bold;
color: #333333;
line-height: 2.63rem;"
        >
          联系地址
        </div>
        <div
          style="font-size: 0.88rem;
font-weight: 500;
color: #999999;
line-height: 1.75rem;"
        >
          河南省郑州市郑东新区龙子湖联合大厦豫发中心17楼
        </div>
      </div>
      <div class="forth-wrap">
        <div>
          <img
            src="@/assets/img/wechat.jpg"
            style="width:7rem;height:7rem;"
            alt="logo"
          />
        </div>
        <div
          style="font-size: 1rem;
color: #333333;"
        >
          官方公众号
        </div>
      </div>
    </div>

    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import { setSpot } from "@/utils/tools.js";

export default {
  name: "Mainbs",
  components: {
    Header,
    Footer,
    Sidebar
  },
  data() {
    return {
      activeTab: 1,
      imgnum: 3,
      yearTab: "2023",
      // img: require('@/assets/img/contact/2022_2_xipin.png'),
      showBigList: [], //大事件展示内容
      // 大事件列表
      yearsBigList: {
        2023: [
          {
            id: "18",
            year: "2023年4月",
            img: require("@/assets/img/contact/2023.4.png"),
            desc:
              "开封市发改委、金融局、人行开封市中心支行领导一行到普惠通调研，深入了解企业经营、技术研发、未来发展战略规划等方面情况，特别关注了普惠通近年来在普惠金融推广、信用信息体系建设等方面的进展情况。"
          },
          {
            id: "15",
            year: "2023年3月",
            img: require("@/assets/img/contact/2023.3.png"),
            desc:
              "省管重点农业企业河南省农业综合开发有限公司战略入股，助力践行“普惠金融助力乡村振兴”使命。"
          },
          {
            id: "17",
            year: "2023年2月",
            img: require("@/assets/img/contact/2023.2.png"),
            desc:
              "“普惠通”全面升级，力推数智普惠金融发展。由普惠通科技（河南）股份有限公司与河南省投融资协会共同举办的普惠金融创新发展暨成果交流会在郑州龙子湖智慧岛大厦召开。"
          },
          {
            id: "16",
            year: "2023年1月",
            img: require("@/assets/img/contact/2023.1.png"),
            desc:
              "普惠通“信在乡村”微视频荣获“信用河南金融生态”微视频征集活动二等奖。"
          },
        ],

        2022: [
          {
            id: "14",
            year: "2022年4月",
            img: require("@/assets/img/contact/2022.4.png"),
            desc:
              "出资成立河南普惠通实业发展有限公司，普惠金融赋能高标准农田“投融建管运”一体化发展，全面助力乡村振兴。"
          },
          {
            id: "13",

            year: "2022年2月",
            img: require("@/assets/img/contact/2022.2十四五.png"),
            desc:
              "《河南省“十四五”数字经济和信息化发展规划》发布，明确提出“推广使用‘普惠通’平台”。"
          },
          {
            id: "12",
            year: "2022年2月",
            img: require("@/assets/img/contact/2022.2喜拼.png"),
            desc:
              "出资成立河南喜拼云链商业运营管理有限公司，业务向新电商产业延伸。"
          },
          {
            id: "11",
            year: "2022年2月",
            img: require("@/assets/img/contact/2022.2.省委办公厅.png"),
            desc:
              "省委办公厅莅临调研普惠金融助力乡村振兴实践，形成内参供副省级以上领导参考，并将普惠通普惠金融实践入选省委办公厅上报中央办公厅典型案例。"
          }
        ],
        2021: [
          {
            id: "10",
            year: "2021年12月",
            img: require("@/assets/img/contact/2021.12.png"),
            desc:
              "引入河南省中小企业发展基金（有限合伙）与郑州市路通公路建设有限公司战略投资，公司的资本带动力进一步增强。"
          },
          {
            id: "9",
            year: "2021年8月",
            img: require("@/assets/img/contact/2021.8.png"),
            desc: "普惠通“第一书记”电商平台上线。"
          },
          {
            id: "8",
            year: "2021年1月",
            img: require("@/assets/img/contact/2021.1.png"),
            desc:
              "公司确定“一平台四板块”战略发展布局，逐步从单一推广复制普惠金融“兰考模式”向乡村振兴数字综合服务的全面转型。"
          }
        ],
        2020: [
          {
            id: "7",
            year: "2020年9月",
            img: require("@/assets/img/contact/2020.9.png"),
            desc:
              "公司与9家省级金融机构、中国银联河南省分公司及12个省辖市地方政府与平台建立战略合作关系。"
          },
          {
            id: "6",
            year: "2020年7月",
            img: require("@/assets/img/contact/2020.7.png"),
            desc:
              "中原资产管理有限公司对“普惠通”平台进行并购重组，注册资本增加至1亿元。"
          }
        ],
        2019: [
          {
            id: "5",
            year: "2019年12月",
            img: require("@/assets/img/contact/2019.12.png"),
            desc: "助力普惠金融“兰考模式”在省内22个试点县推广复制。"
          },
          {
            id: "4",
            year: "2019年7月",
            img: require("@/assets/img/contact/2019.7.png"),
            desc:
              "兰考县普惠金融”一平台四体系“模式的探索实践入选中央组织部编写的《贯彻落实习近平新时代中国特色社会主义思想在改革发展稳定中攻坚克难案例》和中央党校教材。"
          }
        ],
        2018: [
          {
            id: "3",
            year: "2018年3月",
            img: require("@/assets/img/contact/2018.3.png"),
            desc:
              "“普惠通”APP正式上线，完成“普惠金融一网通”相关金融服务功能迁移，“普惠通”平台由微信公众号顺利升级为独立的应用软件。"
          }
        ],
        2017: [
          {
            id: "2",

            year: "2017年9月",
            img: require("@/assets/img/contact/2017.9.png"),
            desc:
              "与中国人民银行郑州中心支行签署《合作备忘录》，成为“普惠金融一网通”建设、运营方，为普惠金融“兰考模式”推广复制提供技术支撑。"
          },
          {
            id: "1",
            year: "2017年6月",
            img: require("@/assets/img/contact/2017.6.png"),
            desc:
              "公司注册成立，基于移动互联网等数字化信息技术和金融科技手段，探索普惠金融发展新模式，赋能经济发展。"
          }
        ]
      }
    };
  },

  created() {
    setSpot("关于我们");
    this.handleYearTab("2023");
  },

  methods: {
    handleTab(num) {
      this.activeTab = num;
    },
    handleYearTab(year) {
      this.yearTab = year;
      this.showBigList = [...this.yearsBigList[year]];
      this.imgnum = this.showBigList.length; //控制展示盒子高低
    }
  }
};
</script>

<style scoped lang="scss">
.contact-wrapper {
  .title-wrap {
    font-size: 1.88rem;
    font-family: PingFangSC-Medium-, PingFangSC-Medium;
    color: #191e4c;
  }
  .red-line-h {
    width: 3.63rem;
    height: 0.25rem;
    background: #d32e25;
    margin: 1.81rem auto 3rem;
  }
  .about-wrap {
    margin-top: 2rem;
    .tabs-wrap {
      width: 25rem;
      height: 3rem;
      background: #efefef;
      border-radius: 2.19rem 2.19rem 2.19rem 2.19rem;
      margin: 0 auto;
      font-size: 1.25rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;

      color: #666666;
      .tab-item {
        width: 8.63rem;
        height: 3rem;
        line-height: 3rem;
        &.active {
          color: #ffffff;

          background: #d32e25;
          border-radius: 1.75rem 1.75rem 1.75rem 1.75rem;
        }
      }
    }
    .about-content-wrap {
      margin-top: 2.06rem;
      height: 30.5rem;
      .text-wrap {
        text-align: left;
        margin-left: 1.19rem;
        p {
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 1.88rem;
          text-indent: 2em;
        }
      }
    }
    .about-img {
      margin-top: 1.69rem;
    }
  }
  .development-wrap {
    // margin-top: 4.88rem;
    position: relative;
    font-size: 0;
    box-sizing: border-box;
    padding-top: 3.13rem;
    .time-line-wrap {
      font-size: 1.5rem;
      font-family: DIN Alternate-Bold, DIN Alternate;
      font-weight: bold;
      color: #666666;
      line-height: 1.88rem;
      position: relative;
      .year-wrap {
        height: 5.88rem;
        cursor: pointer;
        &.active {
          height: 6.5rem;
          .text-wrap {
            font-size: 2.25rem;
            font-family: DIN Alternate-Bold, DIN Alternate;
            font-weight: bold;

            flex-shrink: 0;
            color: #d32e25;
            line-height: 1.88rem;
          }
          .dot-wrap {
            width: 2.38rem;
            height: 2.38rem;
          }
          .dot-wrap::after {
            content: " ";
            display: inline-block;
            width: 1.63rem;
            height: 1.63rem;
            background: #d32e25;
            border-radius: 50%;
            transform: translateY(25%);
          }
        }
        .dot-wrap {
          width: 1.63rem;
          height: 1.63rem;
          background: #ed9f9b;
          border-radius: 50%;
        }
      }
    }
    .content-list-wrap {
      margin-top: 4.69rem;
      .content-card {
        float: left;
        margin-left: 0.6rem;
        margin-top: 1rem;
        width: 36.88rem;
        height: 24.5rem;
        background: #ffffff;
        border-radius: 0.63rem 0.63rem 0.63rem 0.63rem;
        box-sizing: border-box;
        font-size: 0;
        text-align: left;
        overflow: hidden;
        .img-item {
          width: 100%;
          height: 14.38rem;
        }
        .title-item {
          margin-top: 1rem;
          margin-left: 1.69rem;
          font-size: 1rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #666666;
          line-height: 1.88rem;
        }
        .desc-item {
          margin: 0.75rem 3.19rem 0 1.69rem;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 1.88rem;
        }
      }
    }
  }
  .information-wrapper {
    margin: 2.88rem auto;
    .first-wrap {
      text-align: left;
      width: 16.44rem;
      flex-shrink: 0;
    }
    .second-wrap {
      width: 14.5rem;
      flex-shrink: 0;
    }
    .third-wrap {
      width: 11.38rem;
      flex-shrink: 0;
    }
  }
}
</style>
