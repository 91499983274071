<template>
  <div id="container" class="refinancing">
    <p class="newmode"></p>
    <!-- <p class="title">转贷业务</p> -->
    <ArtTitle title="高标准农田托管服务" num="01"></ArtTitle>

    <!-- <ArtText text="高标准农田托管服务" backText="High standard farmland"></ArtText> -->
    <p class="line"></p>
    <div class="ruralbox1">
      <div class="rural1left">
        <p class="intro">
          通过运用云计算、物联网、大数据等新一代信息技术，紧紧围绕高标准农田流转、运营及综合服务，打造成乡村振兴产业链金融AI平台，涵盖土地流转、金融服务、农资农机、专家咨询、科普视频、大数据分析等模块，为高标准农田规模化运营提供从种子到粮食贸易完整的生态链综合服务，为乡村振兴发展实现全链条赋能。
        </p>
      </div>
      <div class="rural1right1">
        <img src="../assets/img/rural1.png" alt="" />
      </div>
    </div>
    <div style="margin-left:33rem;margin-top: 5rem;">
      <p class="newmode"></p>
      <!-- <p class="title">转贷业务</p> -->
      <ArtTitle title="大宗农产品贸易" num="02" text-align="right"></ArtTitle>

      <!-- <ArtText text="大宗农产品贸易" backText="agricultural "></ArtText> -->
      <p class="line"></p>
    </div>

    <div class="ruralbox1">
      <div class="rural2left">
        <img src="../assets/img/rural2.png" alt="" />
      </div>
      <div class="rural2right">
        <p class="intro" >
         联合建设方、资金方、社会化服务方等多方资源构建“投融建运管”一体化运营模式，依托于乡村振兴产业链AI平台赋能高标准农田规模化运营，与下游大型酒企、面粉企业、地方直属库、食品加工厂等建立订单合作，为下游核心企业打造“种植基地”，逐步建立“订单农业”发展模式，开展大宗农产品贸易。
        </p>
      </div>
    </div>
    <div style="margin-top: 5rem;">
      <p class="newmode"></p>
      <!-- <p class="title">转贷业务</p> -->
      <ArtTitle title="供应链金融服务" num="03"></ArtTitle>

      <!-- <ArtText text="供应链金融服务" backText="Supply Chain Finance"></ArtText> -->
      <p class="line"></p>
    </div>

    <div class="ruralbox3">
      <div class="rural1left">
        <p class="intro">
          通过引入银行、担保公司及保险公司“银保担”模式，强化金融产品创新，
          优化服务模式，聚焦农户、种粮大户、家庭农场等新型农业经营主体和粮食生产的各个环节，开辟专门渠道、提供专属融资服务。
        </p>
      </div>
      <div class="rural3right">
        <img src="../assets/img/rural3.png" alt="" style="width: 90%;"/>
      </div>
    </div>
  </div>
</template>

<script>
import { setSpot } from "@/utils/tools.js";
// import ArtText from "@/components/ArtText";
import ArtTitle from "@/components/ArtTitle";

export default {
  components: {  ArtTitle },
  created() {
    setSpot("主要业务-转贷业务");
  }
};
</script>

<style scoped>
.ruralbox1 {
  display: flex;
  justify-content: space-between;
}
.ruralbox3 {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 8rem; */
}
.rural1left {
  width: 35rem;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #515a67;
}
.rural1right1 {
  box-sizing: border-box;
  /* float: right; */
  width: 35rem;
  margin-left: 2rem;
}
.rural2left {
  box-sizing: border-box;
  /* float: right; */
  width: 35rem;
}
.rural2right {
  width: 35rem;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #515a67;
  margin-left: 2rem;
}
.rural3right {
  width: 35rem;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #515a67;
  margin-left: 2rem;
  margin-top: -9rem;
}
p.line {
  width: 28.44rem;
  height: 0px;
  background: #e6e6e6;
  margin-top: 1.5rem;
}
.refinancing {
  box-sizing: border-box;
  width: 74.94rem;
  padding: 3.5rem 6.63rem 0 4.31rem;
}
p {
  text-align: left;
  margin-bottom: 1.13rem;
}
.newmode {
  font-size: 1.13rem;
  font-weight: bold;
  color: #2d2d2d;
}
.title {
  font-size: 1.88rem;
  color: #004db3;
}
.intro {
  font-size: 1rem;
  color: #515a67;
  line-height: 2.5rem;
  text-align: justify;
}
.steps {
  width: 64.69rem;
  margin-top: 4.43rem;
  margin-bottom: 8.94rem;
  display: flex;
  flex-wrap: wrap;
}
.steps div {
  width: 32rem;
  text-align: left;
}
img {
  width: 100%;
}
.steps p {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2.5rem;
  color: #515a67;
}
</style>
